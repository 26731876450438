// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React from "react";
import { FlatList, Platform, StyleSheet, View } from "react-native";

// Constants
import { colors } from "../helpers/colors";

// Helpers
import { learnFrom, learnFromMapper } from "../helpers/constants";

// Components
import MayaText from "../components/typography/MayaText";
import ListItem from "../components/writeSomething/listItem";
import { useNavigation } from "@react-navigation/native";
import { RouteNames } from "../navigation/routeNames";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import { useSelector } from "react-redux";

const TraningListHOC: React.FC = () => {
  const dimentionState = useSelector(
    (state: RootState) => state.root.dimentionReducer,
  );

  const navigation = useNavigation<NativeStackNavigationProp<any>>();

  const navigateDictionary = {
    "Live learning": RouteNames.LiveLearning,
    URL: {
      name: RouteNames.URL,
      params: {
        key: "",
        name: "URL",
        value: "https://",
        subtitle: `Team Maya, make sure this is a public site and I'm allowed to learn from it : )`,
        buttonText: "Start Training",
      },
    },
    DOC: RouteNames.DOC,
    DOCUMENT: RouteNames.DOCUMENT,
    CSV: RouteNames.CSV,
    Text: RouteNames.Text,
  };

  const handleNavigateTo = (location: string) => {
    navigation.navigate(location, { type: location });
  };

  return (
    <View style={styles.container}>
      <View
        style={
          (Platform.OS === "web") & dimentionState && !dimentionState.mobile
            ? styles.containerOnWeb
            : styles.container
        }
      >
        <MayaText style={{ fontWeight: "700", fontSize: 16, marginBottom: 20 }}>
          Learn From
        </MayaText>

        <FlatList
          data={learnFrom}
          renderItem={({ item, index }) => {
            return (
              <ListItem
                title={item}
                index={index}
                image={learnFromMapper[item]?.icon}
                onPress={() => handleNavigateTo(navigateDictionary[item])}
              />
            );
          }}
          numColumns={2}
          ItemSeparatorComponent={() => <View style={{ height: 10 }} />}
          ListFooterComponent={() => <View style={{ height: 20 }} />}
          keyExtractor={(item) => item.id}
        />
      </View>
    </View>
  );
};

export default TraningListHOC;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.background,
    padding: 5,
    flexDirection: "column",
    justifyContent: "flex-start",
  },
  containerOnWeb: {
    flex: 1,
    flexDirection: "column",
    justifyContent: "center",
    backgroundColor: colors.background,
    maxWidth: 800,
    marginVertical: "auto",
  },
});
