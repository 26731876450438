import React from "react";
import { Pressable, StyleSheet, View } from "react-native";

// Components
import MayaText from "../typography/MayaText";
import MenuIcon from "./menuIcons";

type Props = {
  iconPath: any;
  onPress?: () => void;
  label: string;
};

const MenuItem = ({ iconPath, onPress, label }: Props) => {
  return (
    <Pressable style={styles.menuItemWrapper} onPress={onPress}>
      <MenuIcon path={iconPath} />
      <MayaText style={{ fontSize: 20, color: "white" }}>{label}</MayaText>
    </Pressable>
  );
};

export default MenuItem;

const styles = StyleSheet.create({
  menuItemWrapper: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 20,
  },
});
