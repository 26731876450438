import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Image,
  Platform,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
  Modal,
  Animated,
  PanResponder,
} from "react-native";
import MayaTitle from "../typography/MayaTitle";
import { colors } from "../../helpers/colors";
import { useNavigation } from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import { RouteNames } from "../../navigation/routeNames";
import { UserContext } from "./RegistrationContext";

export const Plans = ({ sendDataplans }) => {
  const [changedIcon, setChangedIcon] = useState(true);
  const navigation = useNavigation<NativeStackNavigationProp<any>>();
  const [selectedSingle, setSelectedSingle] = useState(null);
  const [selectedThreeUsers, setSelectedThreeUsers] = useState(null);
  const [selectedEnterprise, setSelectedEnterprise] = useState(null);
  const { userData, saveUserData } = useContext(UserContext);

  const handleSaveUserData = (planType) => {
    saveUserData({ ...userData, planType: planType });
    setTimeout(() => {
      navigation.navigate(RouteNames.Pay);
    }, 300); 
  };

  const sendData = () => {
    sendDataplans(changedIcon);
  };

  const [isOpen, setOpen] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState("");
  useEffect(() => {
    if (selectedPlan) {
      console.log(selectedPlan);
      sendData();
    }
  }, [selectedPlan]);

  const handlePlan = (plan) => {
    setSelectedPlan(plan);
  };

  const translateY = useRef(new Animated.Value(0)).current;

  const toggleModal = () => {
    setOpen(!isOpen);
    Animated.timing(translateY, {
      toValue: isOpen ? 0 : 1,
      duration: 300,
      useNativeDriver: false,
    }).start();
  };

  const handlePanResponderMove = Animated.event([null, { dy: translateY }], {
    useNativeDriver: false,
  });

  const handlePanResponderRelease = (_, gestureState) => {
    if (gestureState.dy > 50) {
      toggleModal();
      Animated.timing(translateY, {
        toValue: 0,
        duration: 300,
        useNativeDriver: false,
      }).start();
    } else {
      Animated.timing(translateY, {
        toValue: 0,
        duration: 300,
        useNativeDriver: false,
      }).start();
    }
  };

  const panResponder = PanResponder.create({
    onStartShouldSetPanResponder: () => true,
    onMoveShouldSetPanResponder: () => true,
    onPanResponderMove: handlePanResponderMove,
    onPanResponderRelease: handlePanResponderRelease,
  });

  const modalStyle = { transform: [{ translateY: translateY }] };

  return (
    <View style={styles.container}>
      <MayaTitle style={styles.welcome}>Please Choose A Plan</MayaTitle>
      
      {/* View Maya's Features */}
      <TouchableOpacity onPress={() => toggleModal()}>
        <View style={styles.textWrapper}>
          <Image
            source={require("../../assets/mayaChat.png")}
            style={styles.image}
          />
          <Text style={styles.featuresText}>View Maya's Features</Text>
        </View>
      </TouchableOpacity>

      {/* Single Plan */}
      <View style={[styles.singlePlan, selectedSingle && styles.selectedPlan]}>
        <TouchableOpacity
          onPress={() => {
            handlePlan("single");
            handleSaveUserData("single");
            setSelectedSingle(true);
            setSelectedEnterprise(false);
            setSelectedThreeUsers(false);
          }}
        >
          <Text style={styles.singlePlanText}>Single</Text>
          <View style={styles.taskInfo}>
            <Text style={styles.tasks}>500 Tasks</Text>
            <Text style={styles.price}>$90/mo</Text>
          </View>
          <Text style={styles.miniPlanText}>
            Try for Free: 3 Days or 20 tasks
          </Text>
        </TouchableOpacity>
      </View>

      {/* Group: 3 Users Plan */}
      <View style={[styles.singlePlan, selectedThreeUsers && styles.selectedPlan]}>
        <TouchableOpacity
          onPress={() => {
            handlePlan("threeUsers");
            handleSaveUserData("threeUsers");
            setSelectedSingle(false);
            setSelectedEnterprise(false);
            setSelectedThreeUsers(true);
          }}
        >
          <Text style={styles.singlePlanText}>Group: 3 Users</Text>
          <View style={styles.taskInfo}>
            <Text style={styles.tasks}>1000 Tasks</Text>
            <Text style={styles.price}>$111/mo</Text>
          </View>
          <Text style={styles.miniPlanText}>
            Try for Free: 3 Days or 20 tasks
          </Text>
        </TouchableOpacity>
      </View>

      {/* Enterprise Plan */}
      <View style={[styles.singlePlan, selectedEnterprise && styles.selectedPlan]}>
        <TouchableOpacity
          onPress={() => {
            handlePlan("enterprise");
            handleSaveUserData("enterprise");
            setSelectedSingle(false);
            setSelectedEnterprise(true);
            setSelectedThreeUsers(false);
          }}
        >
          <Text style={styles.singlePlanText}>Enterprise</Text>
          <View style={styles.taskInfo}>
            <Text style={styles.tasks}>Custom</Text>
            <Text style={styles.price}>Book A Demo</Text>
          </View>
        </TouchableOpacity>
      </View>

      {/* Modal */}
      <Modal
        animationType="slide"
        transparent={true}
        visible={isOpen}
        onRequestClose={() => toggleModal()}
      >
        <View style={styles.modalContainer}>
          <Animated.View
            {...panResponder.panHandlers}
            style={styles.modalContent}
          >
       {Platform.OS!='web'?(  
                    <View style={{ top: -30 }}>
   
          <Image
                source={require("../../assets/bottomDrawerIcon.png")}
                style={styles.drawerIcon}
              />
              </View>):( 
                                    <View style={{ top: -40 }}>
   
                <TouchableOpacity
                onPress={toggleModal}>
        <Text style={{flex:1,alignSelf:'center',color:colors.anakiwa,fontSize:22,fontWeight:'bold',textAlign:'center',borderWidth:2,borderRadius:50,borderColor:colors.anakiwa,width:'10%'}}>X</Text>
              </TouchableOpacity>
              </View>)
       
      
      }
            

            <Text style={styles.provideInsightsOn}>Provide Insights On:</Text>

            <Text style={styles.answerDeepQuestionsUsingYo}>1. URLs</Text>
            <Text style={styles.answerDeepQuestionsUsingYo}>2. PDFs</Text>
            <Text style={styles.answerDeepQuestionsUsingYo}>
              3. Word Documents
            </Text>
            <Text style={styles.answerDeepQuestionsUsingYo}>
              4. MP3 (Audio) - Beta
            </Text>
            <Text style={styles.answerDeepQuestionsUsingYo}>
              5. MP4 (Video) - Beta
            </Text>
            <Text style={styles.answerDeepQuestionsUsingYo}>
              6. Talk to Maya/ Text
            </Text>

            <Text
              style={{ color: colors.springGreen, fontWeight: "bold", top: 10 }}
            >
              Capabilities
            </Text>
            <View style={[styles.answerDeepQuestionsContainer, { top: 20 }]}>
              <Text style={styles.answerDeepQuestionsUsingYo}>
                1. Answer deep questions using your data.
              </Text>
              <Text style={styles.answerDeepQuestionsUsingYo}>
                2. Talk, see and hear at the same time.
              </Text>
              <Text style={styles.answerDeepQuestionsUsingYo}>
                3. Write you pretty much anything :)
              </Text>
            </View>
            <View style={[styles.answerDeepQuestionsContainer2, { top: 8 }]}>
              <Text style={styles.answerDeepQuestionsUsingYo}>
                . Read and summarize websites or docs
              </Text>
              <Text style={styles.answerDeepQuestionsUsingYo}>
                . Create a plan or report
              </Text>
              <Text style={styles.answerDeepQuestionsUsingYo}>
                . Provide weekly updates
              </Text>
              <Text style={styles.answerDeepQuestionsUsingYo}>
                . Make complex predictions with your data.
              </Text>
              <Text style={styles.answerDeepQuestionsUsingYo}>
                . Make connections - compare & contrast
              </Text>
              <Text>Have a full conversation with you :)</Text>
            </View>
            <Text style={{ color: colors.springGreen, fontWeight: "bold" }}>
              Secured
            </Text>
            <Text style={styles.answerDeepQuestionsUsingYo}>. AWS Secured</Text>
            <Text style={styles.answerDeepQuestionsUsingYo}>
              . Individual Maya systems for each user
            </Text>
            <Text style={[styles.answerDeepQuestionsUsingYo, { left: 30 }]}>
              . Individual database - Data privacy
            </Text>
            <Text style={[styles.answerDeepQuestionsUsingYo, { left: 30 }]}>
              . Individual brain - Personalize AI
            </Text>
            <Text style={[styles.answerDeepQuestionsUsingYo]}>
              . Backup every 60 seconds
            </Text>
          </Animated.View>
        </View>
      </Modal>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.background,
    padding: 16,
  },
  welcome: {
    marginBottom: 20,
  },
  textWrapper: {
    flexDirection: "row",
    backgroundColor: colors.background2,
    padding: Platform.OS === 'web' ? 14 : 8,
    borderRadius: 14,
    borderColor: colors.borderColor,
    borderWidth: 1.8,
    marginBottom: 20,
    width: Platform.OS!='web' ?"80%":"20%",
    height: 50,
    alignSelf: "center",
    alignItems: "center",  // Align items in the center horizontally
    justifyContent: 'center', // Center content vertically
  },
  
  featuresText: {
    color: colors.placeholder,
    marginLeft: 10, // Adjust the margin based on your design
    textAlign: 'center', // Center text within the available space
  },
  singlePlan: {
    backgroundColor: "transparent",
    padding: 20,
    borderRadius: 22,
    borderColor: colors.springGreen,
    borderWidth: 1,
    width:Platform.OS!='web' ?"100%":"30%",
    height: '20%',
    color: "white",
    marginTop: 30,
    alignSelf: 'center',
  },
  selectedPlan: {
    backgroundColor: colors.background2,
  },
  singlePlanText: {
    color: colors.placeholder,
    marginBottom: 10,
    fontSize: 16,
  },
  taskInfo: {
    flexDirection: "row",
    marginBottom: 10,
    justifyContent: "space-between",
  },
  tasks: {
    color: colors.placeholder,
    fontSize: 16,
  },
  price: {
    fontWeight: "bold",
    color: colors.placeholder,
    fontSize: 16,
  },
  miniPlanText: {
    color: colors.placeholder,
    marginBottom: 10,
    fontSize: 12,
  },
  image: {
    width: Platform.OS !== "web" ? 32 : 42,
    height: Platform.OS !== "web" ? 42 : 52,
    marginLeft: 6,
  },
  modalContainer: {
    flex: 1,
    justifyContent: "flex-end",
    alignItems: "center",
    backgroundColor: "rgba(0, 0, 0, 0.637)",
  },
  modalContent: {
    backgroundColor: colors.background,
    padding: 46,
    borderTopLeftRadius: 30,
    borderTopRightRadius: 30,
    width: Platform.OS!='web' ?"100%":"30%",
    height:Platform.OS!='web' ?"80%":"92%",
    paddingBottom: 50,
    paddingTop: 50,
    top:Platform.OS=='web'?'14%':'5%'
  },
  
  
  
  
  provideInsightsOn: {
    fontWeight: "bold",
    marginBottom: 6,
    color: "#FF9900",
  },
  answerDeepQuestionsContainer: {
    marginBottom: 10,
  },
  answerDeepQuestionsContainer2: {
    marginBottom: 2,
    left: 30,
  },
  answerDeepQuestionsUsingYo: {
    marginBottom: 2,
    color: colors.placeholder,
  },
  drawerIcon: {
    height: 4,
    width: 50,
    display: "flex",
    alignSelf: "center",
  },

});