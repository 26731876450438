import React, { useEffect } from "react";
import { StyleSheet, View } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import { setWorks } from "../reducers/worksSlice";

// Constants & Types
import worksJson from "../../mockWorks.json";
import { colors } from "../helpers/colors";
import { RootState } from "../../types";
import WorksList from "../components/works/WorksList";
import { useNavigation } from "@react-navigation/native";
import { RouteNames } from "../navigation/routeNames";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";

const WorkTrainingsHOC: React.FC = () => {
  const navigation = useNavigation<NativeStackNavigationProp<any>>();
  const dispatch = useDispatch();
  const { works } = useSelector((state: RootState) => state.root.worksReducer);

  useEffect(() => {
    if (worksJson) {
      dispatch(setWorks(worksJson));
    }
  }, [worksJson]);
  //@ts-ignore
  const denormalizedWorks = works.allIds.map((id) => works[id]);

  const _onPress = () => {
    navigation.navigate(RouteNames.TrainingList);
  };

  const handleOnItemPress = (id: string) => {};

  return (
    <View style={styles.container}>
      <WorksList
        data={denormalizedWorks}
        onAddPress={_onPress}
        onItemPress={handleOnItemPress}
      />
    </View>
  );
};

export default WorkTrainingsHOC;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.background,
    padding: 15,
  },
});
