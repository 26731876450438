import React, { useContext, useEffect, useRef, useState } from "react";
import {
  ActivityIndicator,
  Linking,
  Platform,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import MayaTitle from "../typography/MayaTitle";
import { colors } from "../../helpers/colors";
import { Image } from "react-native";
import Layout from "../Layout/Layout";
import { useFocusEffect, useNavigation } from "@react-navigation/native"; // Import useNavigation
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import { RouteNames } from "../../navigation/routeNames";
import { UserContext } from "./RegistrationContext";
import LoadingHOC from "../../containers/LoadingHOC";
import { dispatch } from "../../store/store";
import { loginAsyncThunk, submitTokenAsyncThunk } from "../../api/auth/auth";
import AppStack from "../../navigation/AppStack";
import { useSelector } from "react-redux";
import { RootState } from "../../../types";

export const Activate = ({ sendDataActivation }) => {
  console.log("Activate component rendered");
  const navigation = useNavigation<NativeStackNavigationProp<any>>();

  const [timeLayout, setTimeLayout] = useState(false);
  const { userData, saveUserData } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(true);
  const [isLayout, setIsLayout] = useState(false);
  const [authToken, setAuthToken] = useState("");

  const { loading, error, token, userId, isUserPressLogout } = useSelector(
    (state: RootState) => state.persist.authReducer,
  );
  const handleError = (errorType) => {
    saveUserData({ ...userData, email: errorType });
  };
  useEffect(() => {
    if (token) {
      navigation.navigate(RouteNames.Home);
    }
  }, [token]);


  useFocusEffect(() => {
    setIsLayout(true)
    setTimeout(() => {
      submitFormAndClick();
    }, 100);

  });

  //PAYLOAD SEND
  const submitFormAndClick = async () => {


    console.log("submitFormAndClick called");

    try {
      const payload = {
        password: userData.password,
        username: userData.email,
        firstname: userData.firstName,
        lastname: userData.lastName,
        company: userData.company,
        email: userData.email,
        // planType:userData.planType
      };

      const response = await fetch(
        "https://platform.mayadashboard.com/auth/register",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );
      console.log("Response status :", response.status);

      if (!response.ok) {
        sendDataActivation(false);
        if (response.status === 409) {
          handleError("409");
          setTimeout(() => {
            navigation.navigate(RouteNames.Register);
          }, 1500);
          console.log("Response status :", response.status);
          setTimeLayout(false);
          // throw new Error(`HTTP error! Status: ${response.status}`,);
        }
      } else {
        const responseData = await response.json();
        if(responseData){
          console.log("Success:", responseData);     
          dispatch(submitTokenAsyncThunk({ registrationToken: responseData.registration_token }));
          setAuthToken(responseData.registration_token )

          setTimeLayout(false);
          sendDataActivation(true);
          

        }
     
      };

          

    } catch (error) {
      console.error("Error posting data to Platform. Network error.", error);
    }
  };
  

  return isLoading ? (
    // <View style={styles.spinnerContainer}>
    //   <ActivityIndicator size={80} color={colors.springGreen} />
    // </View>
    <LoadingHOC />
  ) : isLayout&&(
    <Layout>
    <View style={styles.container}>
      <MayaTitle style={styles.welcome}>Token Created</MayaTitle>
      <View style={styles.tokenTitleContainer}>
        <MayaTitle style={styles.tokenTitle}>
          Please check your Email for your token
        </MayaTitle>
      </View>
      <View style={styles.imageContainer}>
        <TouchableOpacity
          // handleOpenEmailInbox
          onPress={submitFormAndClick}
        >
          <Image
            source={require("../../assets/tokenCreatedIcon.png")}
            style={styles.image}
          />
        </TouchableOpacity>
      </View>
    </View>
    </Layout>
  );
};
const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.background,
    height: "100%",
    width: "100%",
  },
  welcome: { top: 35, color: colors.springGreen },
  tokenTitle: { fontSize: 18 },
  tokenTitleContainer: {
    top: 35,
  },
  imageContainer: {
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
    top: 60,
  },
  image: {
    width: Platform.OS != "web" ? 266 : 100, // Adjust as needed
    height: Platform.OS != "web" ? 282 : 100, // Adjust as needed
    top: 0,

    marginHorizontal: Platform.OS != "web" ? 14 : 40, // Adjust as needed
  },
  spinnerContainer: {
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: colors.background,
    flex: 1,
  },

});
