import "expo-dev-client";
import React from "react";
import { LogBox, StatusBar, StyleSheet } from "react-native";

import { Provider, ReactReduxContext } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";

import { SafeAreaProvider, SafeAreaView } from "react-native-safe-area-context";

import { colors } from "./src/helpers/colors";
import RootNavigation from "./src/navigation/RootNavigation";
import store, { persistor } from "./src/store/store";
import Toast from "react-native-toast-message";
import { toastConfig } from "./src/hooks/useNotification";
import * as Sentry from "sentry-expo";

LogBox.ignoreLogs(["new NativeEventEmitter"]);
Sentry.init({
  dsn: "https://4860ba24d33db5f44a88cc9049f7566e@o4506326323888128.ingest.sentry.io/4506338754887680",
  // Set tracesSampleRate to 1.0 to capture 100% of transactions for performance monitoring.
  // We recommend adjusting this value in production.
  tracesSampleRate: 1.0,
  debug: false,
});

function App() {
  return (
    <SafeAreaProvider>
      <SafeAreaView edges={["top"]} style={styles.container}>
        <StatusBar
          barStyle={"light-content"}
          backgroundColor={colors.background}
        />

        <Provider store={store} context={ReactReduxContext}>
          <PersistGate loading={null} persistor={persistor}>
            <RootNavigation />
            <Toast
              topOffset={60}
              autoHide={true}
              visibilityTime={5000}
              config={toastConfig}
            />
          </PersistGate>
        </Provider>
      </SafeAreaView>
    </SafeAreaProvider>
  );
}

export default App;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.background,
    paddingTop: StatusBar.currentHeight ?? 5,
  },
});
