import * as React from "react";
import Svg, { Path, Rect } from "react-native-svg";

const SvgComponent = (props) => (
  <Svg
    width="95"
    height="39"
    viewBox="0 0 95 39"
    {...props}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <Rect width="54" height="39" rx="12" fill="#D9D9D9" />
    <Path
      d="M60 14.5258C60 11.8469 61.7759 9.49261 64.3517 8.75667L87.3517 2.18524C91.1846 1.09012 95 3.96811 95 7.95438V31.0456C95 35.0319 91.1846 37.9099 87.3517 36.8148L64.3517 30.2433C61.7759 29.5074 60 27.1531 60 24.4742V14.5258Z"
      fill="#D9D9D9"
    />
  </Svg>
);

export default SvgComponent;
