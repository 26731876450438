import React, { useEffect, useState } from "react";
import {
  Keyboard,
  KeyboardAvoidingView,
  Platform,
  ScrollView,
  StyleSheet,
  Text,
  TextInput,
  TouchableOpacity,
  TouchableWithoutFeedback,
} from "react-native";
import ButtonWide from "../general/ButtonWide";
import MayaTitle from "../typography/MayaTitle";
import { RouteNames } from "../../navigation/routeNames";
import { useFocusEffect, useNavigation } from "@react-navigation/native";

import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import { View } from "react-native";
import { colors } from "../../helpers/colors";

export const Payment = ({ sendDataPayments }) => {
  const [changedIcon, setChangedIcon] = useState(true);
  const sendData = () => {
    sendDataPayments(changedIcon);
  };
  const [isActive, setIsActive] = useState(false);

  const [emptyInput, setEmptyInput] = useState(false);
  const [fullName, setFullName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [fullNameErrors, setFullNameErrors] = useState("");
  const [fullNameError, setFullNameError] = useState(false);

  const [cardNumber, setCardNumber] = useState("");
  const [cardNumberError, setCardNumberError] = useState(false);

  const [expDate, setExpDate] = useState("");
  const [expDateError, setExpDateError] = useState(false);
  const [expDateErrorType, setExpDateErrorType] = useState("");

  const [cvv, setCvv] = useState("");
  const [cvvError, setCvvError] = useState(false);
  const [cvvErrorType, setCvvErrorType] = useState("");

  const [zipCode, setZipCode] = useState("");
  const [zipCodeError, setZipCodeError] = useState(false);
  const [zipCodeErrorMessage, setZipCodeErrorMessage] = useState("");

  const navigation = useNavigation<NativeStackNavigationProp<any>>();
  useEffect(() => {
    const formattedValue = cardNumber
      .replace(/\s/g, "")
      .replace(/(\d{4})/g, "$1 ");
    setCardNumber(formattedValue);
  }, [cardNumber]);

  const validateFullName = () => {
    const trimmedFullName = fullName.trim();
    const splitName = trimmedFullName.split(" ");
    if (fullName == "") {
      setFullNameError(true);

      setFullNameErrors("Full name field cannot be empty");
    } else if (splitName.length >= 2) {
      setFirstName(splitName[0]);
      setLastName(splitName.slice(1).join(" "));
      setFullNameError(false);
    } else {
      setFullNameErrors("Please enter Name and last name");
      setFirstName("");
      setLastName("");
      setFullNameError(true);
    }
    handleValidations()

  };
  const checkCardNumberError = () => {
    if (cardNumber == "") {
      setCardNumberError(false);
      //final check for empty fields
      setEmptyInput(true);
    } else if (cardNumber.length < 19) {
      setCardNumberError(true);
      console.log("Card error ");
    } else {
      console.log("OK");
    }
    handleValidations()

  };
  const handleExpirationDate = () => {
    const month = [];
    const day = [];
    if (expDate) {
      month.push(expDate[0]);
      month.push(expDate[1]);
      //__________________________

      day.push(expDate[3]);
      day.push(expDate[4]);
    }
    const monthNumValue = parseInt(month.join(""), 10);

    const dayNumValue = parseInt(day.join(""), 10);
    //console.log("Day :", dayNumValue);
    if (monthNumValue > 12) {
      setExpDateError(true);
      setExpDateErrorType("Wrong expiration date");
    } else if (dayNumValue > 31) {
      setExpDateError(true);
      setExpDateErrorType("Wrong expiration date");
    } else {
      const formattedExpDate = expDate
        .replace(/\s/g, "")
        .replace(/\//g, "")
        .replace(/(\d{2})/g, "$1/");
      setExpDate(formattedExpDate);
    }
    handleValidations()
  };
  const handleCvv = () => {
    if (cvv == "") {
      setCvvError(false);
    } else if (cvv.length != 3) {
      setCvvError(true);
      setCvvErrorType("Wrong CVV");
    } else {
      setCvvError(false);
    }
    handleValidations()

  };
  const handleZipCode = () => {
    if (zipCode == "") {
      setZipCodeError(true);
      setZipCodeErrorMessage("Zip Code number field cannot be empty");

    } else if (zipCode.length != 5) {
      setZipCodeError(true);
      setZipCodeErrorMessage("Wrong Zip Code number");
    } else {
      setZipCodeError(false);
    }
    handleValidations()
  };
  const handleValidations=()=>{
    if(!fullNameError&&!cardNumberError&&!cvvError&&!expDateError&&!zipCodeError){
      setIsActive(true)
    }
  }
  const handleContinue = () => {
    sendDataPayments(changedIcon);
    navigation.navigate(RouteNames.Activation);
  };
useEffect(()=>{
  setIsActive(false)
},[])
  return (
    Platform.OS!='web'?(<>
        <KeyboardAvoidingView
      style={styles.container}
      behavior={Platform.OS === "ios" ? "padding" : null}
      keyboardVerticalOffset={Platform.OS === "ios" ? 20 : 70}
    >
      <TouchableWithoutFeedback onPress={Keyboard.dismiss}>
        <ScrollView
          contentContainerStyle={{
            flexGrow: 1,
            justifyContent: "center",
            alignItems: "center",
            bottom: 50,
          }}
          keyboardShouldPersistTaps="handled"
          showsVerticalScrollIndicator={false}        >
          <MayaTitle style={styles.welcome}>Add Payment Method</MayaTitle>
          <View style={{ alignSelf: "center", top: 20 }}>
            <View style={styles.flexRowCenter}>
              <TextInput
                placeholder="Full Name"
                placeholderTextColor={colors.placeholder}
                value={fullName}
                onChangeText={(text) => setFullName(text)}
                onBlur={() => {
                  validateFullName();
                }}
                onFocus={() => {
                  setFullNameError(false);
                    sendDataPayments(false);
             
                }}
                style={
                  !fullNameError
                    ? styles.textinputWrapper
                    : styles.textInputError
                }
              />

              {fullNameError && (
                <View style={{ marginTop: -20, paddingVertical: 5 }}>
                  <Text style={{ color: "#f43636", fontSize: 14 }}>
                    {fullNameErrors}
                  </Text>
                </View>
              )}
              <TextInput
                placeholder="16 -Digit Number"
                value={cardNumber}
                maxLength={19}
                keyboardType="numeric"
                onChangeText={setCardNumber}
                onFocus={() => {
                  setCardNumberError(false);
                  sendDataPayments(false);
                }}
                onBlur={() => {
                  checkCardNumberError();
                  sendDataPayments(false);
                }}
                placeholderTextColor={colors.placeholder}
                style={
                  !cardNumberError
                    ? styles.textinputWrapper
                    : styles.textInputError
                }
              />
              {cardNumberError && (
                <View style={{ marginTop: -20, paddingVertical: 5 }}>
                  <Text style={{ color: "#f43636", fontSize: 14 }}>
                    Please provide a 16 digits card number
                  </Text>
                </View>
              )}
              <TextInput
                placeholder="Exp. Date"
                onFocus={() => {
                  setExpDateError(false);
                  sendDataPayments(false);
                }}
                maxLength={5}
                placeholderTextColor={colors.placeholder}
                value={expDate}
                onChangeText={setExpDate}
                onBlur={handleExpirationDate}
                keyboardType="numeric"
                style={
                  !expDateError
                    ? styles.textinputWrapper
                    : styles.textInputError
                }
              />
              {expDateError && (
                <View style={{ marginTop: -20, paddingVertical: 5 }}>
                  <Text style={{ color: "#f43636", fontSize: 14 }}>
                    {expDateErrorType}
                  </Text>
                </View>
              )}
              <TextInput
                placeholder="CVV"
                placeholderTextColor={colors.placeholder}
                value={cvv}
                onChangeText={setCvv}
                keyboardType="numeric"
                maxLength={3}
                onFocus={() => {
                  setCvvError(false);
                  sendDataPayments(false);
                }}
                onBlur={handleCvv}
                style={[
                  styles.textinputWrapper,

                  cvvError && styles.textInputError,
                ]}
              />
              {cvvError && (
                <View style={{ marginTop: -20, paddingVertical: 5 }}>
                  <Text style={{ color: "#f43636", fontSize: 14 }}>
                    {cvvErrorType}
                  </Text>
                </View>
              )}

              <TextInput
                placeholder="ZIP Code"
                placeholderTextColor={colors.placeholder}
                value={zipCode}
                keyboardType="numeric"
                onChangeText={setZipCode}
                onFocus={() => {
                  setZipCodeError(false);                 
                  sendDataPayments(false);
                }}
                maxLength={5}
                onBlur={handleZipCode}
                style={[
                  styles.textinputWrapper,
                  zipCodeError && styles.textInputError,
                ]}
              />
              {zipCodeError && (
                <View style={{ marginTop: -20, paddingVertical: 5 }}>
                  <Text style={{ color: "#f43636", fontSize: 14 }}>
                    {zipCodeErrorMessage}
                  </Text>
                </View>
              )}

              <TouchableOpacity
                onPress={handleContinue}
                style={{
                  backgroundColor: colors.springGreen,
                  opacity: !isActive ? 0.5 : 1,
                  height: 46,
                  width: 320,
                  padding: 10,
                  alignSelf: "center",
                  top: 90,
                  marginBottom: 10,
                  borderRadius: 50,
                }}
              >
                <Text
                  style={{
                    fontWeight: "700",
                    color: "black",
                    textAlign: "center",
                    fontSize: 18,
                  }}
                >
                  Continue
                </Text>
              </TouchableOpacity>
            </View>
          </View>
        </ScrollView>
      </TouchableWithoutFeedback>
    </KeyboardAvoidingView></>):(<>
    
      <ScrollView
      contentContainerStyle={{
        flexGrow: 1,
        justifyContent: "center",
        alignItems: "center",
             backgroundColor:colors.background
      }}
       keyboardShouldPersistTaps="handled"
  showsVerticalScrollIndicator={false}
    >
      <MayaTitle style={{ top: 10 ,fontSize:24}}>
            Add Payment Method</MayaTitle>
          <View style={{ alignSelf: "center", top: 0 }}>
            <View style={styles.flexRowCenter}>
              <TextInput
                placeholder="Full Name"
                placeholderTextColor={colors.placeholder}
                value={fullName}
                onChangeText={(text) => setFullName(text)}
                onBlur={() => {
                  validateFullName();
                }}
                onFocus={() => {
                  setFullNameError(false);
                    sendDataPayments(false);
             
                }}
                style={
                  !fullNameError
                  ? [styles.textinputWrapper,{fontSize:22}] :
                   [styles.textInputError,{fontSize:22}]
                }
              />

              {fullNameError && (
                        <View style={{ marginTop: -20, paddingVertical: 15 }}>
                        <Text style={{ color: "#f43636", fontSize: 22 }}>
                    {fullNameErrors}
                  </Text>
                </View>
              )}
              <TextInput
                placeholder="16 -Digit Number"
                value={cardNumber}
                maxLength={19}
                keyboardType="numeric"
                onChangeText={setCardNumber}
                onFocus={() => {
                  setCardNumberError(false);
                  sendDataPayments(false);
                }}
                onBlur={() => {
                  checkCardNumberError();
                  sendDataPayments(false);
                }}
                placeholderTextColor={colors.placeholder}
                style={
                  !cardNumberError
                  ? [styles.textinputWrapper,{fontSize:22}] 
                  :[styles.textInputError,{fontSize:22}]
                }
              />
              {cardNumberError && (
                  <View style={{ marginTop: -20, paddingVertical: 15 }}>
                  <Text style={{ color: "#f43636", fontSize: 22 }}>
                    Please provide a 16 digits card number
                  </Text>
                </View>
              )}
              <TextInput
                placeholder="Exp. Date"
                onFocus={() => {
                  setExpDateError(false);
                  sendDataPayments(false);
                }}
                maxLength={5}
                placeholderTextColor={colors.placeholder}
                value={expDate}
                onChangeText={setExpDate}
                onBlur={handleExpirationDate}
                keyboardType="numeric"
                style={
                  !expDateError
                  ? [styles.textinputWrapper,{fontSize:22}] 
                  : [styles.textInputError,{fontSize:22}]
                }
              />
              {expDateError && (
                <View style={{ marginTop: -20, paddingVertical: 15 }}>
                <Text style={{ color: "#f43636", fontSize: 22 }}>
                    {expDateErrorType}
                  </Text>
                </View>
              )}
              <TextInput
                placeholder="CVV"
                placeholderTextColor={colors.placeholder}
                value={cvv}
                onChangeText={setCvv}
                keyboardType="numeric"
                maxLength={3}
                onFocus={() => {
                  setCvvError(false);
                  sendDataPayments(false);
                }}
                onBlur={handleCvv}
                style={[

                  !cvvError
                  ? [styles.textinputWrapper,{fontSize:22}] 
                  : [styles.textInputError,{fontSize:22}]
                ]}
              />
              {cvvError && (
                 <View style={{ marginTop: -20, paddingVertical: 15 }}>
                 <Text style={{ color: "#f43636", fontSize: 22 }}>
                    {cvvErrorType}
                  </Text>
                </View>
              )}

              <TextInput
                placeholder="ZIP Code"
                placeholderTextColor={colors.placeholder}
                value={zipCode}
                keyboardType="numeric"
                onChangeText={setZipCode}
                onFocus={() => {
                  setZipCodeError(false);                 
                  sendDataPayments(false);
                }}
                maxLength={5}
                onBlur={handleZipCode}
                style={[
                  
                 ! zipCodeError ? [styles.textinputWrapper,{fontSize:22}] 
                 : [styles.textInputError,{fontSize:22}]
                ]}
              />
              {zipCodeError && (
                    <View style={{ marginTop: -20, paddingVertical: 15 }}>
                    <Text style={{ color: "#f43636", fontSize: 14 }}>
                    {zipCodeErrorMessage}
                  </Text>
                </View>
              )}

      
            </View>
          </View>
          <TouchableOpacity
            onPress={handleContinue}
            style={{
              backgroundColor: colors.springGreen,
              opacity: !isActive ? 0.5 : 1,
              height: 46,
              width:  400,
              padding: 10,
              alignSelf: "center",
              top: 80,
              marginBottom: 140,
              borderRadius: 50,
            }}
            disabled={!isActive}
          >
            <Text
              style={{
                fontWeight: "700",
                color: "black",
                textAlign: "center",
                fontSize: 18,
              }}
            >
              Continue
            </Text>
          </TouchableOpacity>
        </ScrollView> 
    
    </>)

  );
};
const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.background,
    height: "100%",
    width: "100%",
  },

  mainContainer: {
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
  },
  welcome: {
    bottom: 35,
  },
  imageContainer: {
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
    
  },
  image: {
    width: Platform.OS != "web" ? 62 : 100,
    height: Platform.OS != "web" ? 82 : 100,
    top: 0,

    marginHorizontal: Platform.OS != "web" ? 14 : 40,
  },

  textinputWrapper: {
    backgroundColor: colors.background2,
    paddingHorizontal: 20,
    paddingVertical: Platform.OS === "ios" ? 5 : 10,
    borderRadius: 50,
    borderColor: colors.borderColor,
    borderWidth: Platform.OS == "web" ? 2 : 1,
    marginBottom: Platform.OS == "web" ? 38 : 18,
    width: Platform.OS == "web" ? 400 : "100%",
    height: Platform.OS == "web" ? 60 : 50,
    color: colors.placeholder,
  },
  textInputError: {
    backgroundColor: colors.borderColor,
    paddingHorizontal: 15,
    paddingVertical: Platform.OS === "ios" ? 5 : 0,
    borderRadius: 50,
    borderColor: "#f43636",
    borderWidth: Platform.OS == "web" ? 2 : 1,
    marginBottom: 16,
    width: Platform.OS == "web" ? 400 : "100%",
    height: Platform.OS == "web" ? 60 : 50,
    color: "#f43636",
  },
  flexRowCenter: {
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    top: Platform.OS !== "web" ? 20 : 50,
    width: Platform.OS !== "web" ? 320 : 350,
  },
});
