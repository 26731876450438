import React, { useEffect } from "react";
import { Image, ScrollView, StyleSheet, View } from "react-native";

// Constants
import { colors } from "../helpers/colors";

// Components
import MayaText from "../components/typography/MayaText";

// Icons
import ProfileField from "../components/profile/profileField";
import { setProfile } from "../reducers/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { RouteNames } from "../navigation/routeNames";
import { useNavigation } from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import { RootState } from "../../types";
import {
  cacheUser,
  getUserAsyncThunk,
  updateUserProfileAsyncThunk,
} from "../api/user/user";
import { AppDispatch } from "../store/store";
import useScreenDimensionChange from "../hooks/useScreenDimensionChange";
import { UserModel } from "../models/user.model";
import Loading from "../components/Loading/Loading";
import LoadingHOC from "./LoadingHOC";
import { setKeyFromProfile, setUserProfile } from "../reducers/userSlice";

const ProfileHOC = () => {
  const { dimensions, isMobile } = useScreenDimensionChange();
  const { loading, profile } = useSelector(
    (state: RootState) => state.persist.authReducer,
  );

  const navigation = useNavigation<NativeStackNavigationProp<any>>();
  const dispatch = useDispatch<AppDispatch>();

  const onPress = (key: string, value: string) => {
    console.debug("key and value", key, value);
    dispatch(setProfile({ key, value }));
    dispatch(setKeyFromProfile({ key, value }));
    dispatch(updateUserProfileAsyncThunk({ [key]: value } as UserModel));
    navigation.goBack();
  };

  const handleEdit = (key: string, name: string, value: string | undefined) => {
    navigation.navigate(RouteNames.SingleInputScreen, {
      key,
      name,
      value,
      onPress,
    });
  };

  useEffect(() => {
    if (!profile || !profile.email) {
      dispatch(getUserAsyncThunk());
    }
  }, []);

  if (loading) {
    return <LoadingHOC />;
  }
  return (
    <ScrollView>
      <View style={styles.mainContainer}>
        <View
          style={[
            styles.container,
            !isMobile ? { width: "50%", marginHorizontal: "auto" } : {},
          ]}
        >
          <View style={styles.sectionContainer}>
            <Image
              source={require("../assets/user.png")}
              style={{ width: 90, height: 90, alignSelf: "center" }}
              resizeMode="center"
            />

            <MayaText style={styles.title}>PERSONAL INFORMATION</MayaText>

            <ProfileField
              label={"Name"}
              value={profile?.firstname}
              onPress={() =>
                handleEdit("firstname", "Name", profile?.firstname)
              }
            />

            <ProfileField
              label={"Surname"}
              value={profile?.lastname}
              onPress={() =>
                handleEdit("lastname", "Surname", profile?.lastname)
              }
            />

            <ProfileField
              label={"Email"}
              value={profile?.email}
              enableArrow={false}
            />

            <ProfileField
              label={"Birthday"}
              value={profile?.birthday}
              enableArrow={false}
            />
          </View>

          <View style={styles.sectionContainer}>
            <MayaText style={styles.title}>ACCOUNT INFORMATION</MayaText>

            <ProfileField
              label={"Password"}
              value={"***********"}
              onPress={() =>
                handleEdit("password", "Password", profile?.password)
              }
              enableArrow={false}
            />

            <ProfileField
              label={"Language"}
              value={profile?.language}
              onPress={() =>
                handleEdit("language", "Language", profile?.password)
              }
            />
          </View>

          <View style={styles.sectionContainer}>
            <MayaText style={styles.title}>BUSINESS INFORMATION</MayaText>

            <ProfileField
              label={"Company"}
              value={profile?.company}
              onPress={() => handleEdit("company", "Company", profile?.company)}
            />

            <ProfileField
              label={"Address"}
              value={profile?.address}
              onPress={() => handleEdit("address", "Address", profile?.address)}
            />

            <ProfileField
              label={"City"}
              value={profile?.city}
              onPress={() => handleEdit("city", "City", profile?.city)}
            />

            <ProfileField
              label={"State"}
              value={profile?.state}
              onPress={() => handleEdit("state", "State", profile?.state)}
            />

            <ProfileField
              label={"Country"}
              value={profile?.country}
              onPress={() => handleEdit("country", "Country", profile?.country)}
            />

            <ProfileField
              label={"Position"}
              value={profile?.position}
              onPress={() =>
                handleEdit("position", "Position", profile?.position)
              }
            />
          </View>
        </View>
      </View>
    </ScrollView>
  );
};

export default ProfileHOC;

const styles = StyleSheet.create({
  mainContainer: {
    backgroundColor: colors.background,
  },
  container: {
    backgroundColor: colors.background,
    padding: 20,
  },
  editButton: {
    alignSelf: "center",
    fontWeight: "600",
    color: colors.anakiwa,
  },
  sectionContainer: {
    padding: 20,
    backgroundColor: colors.background2,
    borderRadius: 30,
    marginBottom: 30,
    width: "100%",
    alignSelf: "flex-start",
  },
  title: {
    fontWeight: "600",
    fontSize: 10,
    marginBottom: 12,
  },
});
