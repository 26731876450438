import React, { useEffect, useState } from "react";
import {
  Dimensions,
  Image,
  KeyboardAvoidingView,
  Linking,
  Platform,
  StyleSheet,
  TextInput,
  View,
} from "react-native";
import { useDispatch, useSelector } from "react-redux";
import { useNavigation } from "@react-navigation/native";

// Components
import LoginLayout from "../components/login/LoginLayout";
import FooterText from "../components/typography/FooterText";
import MayaTitle from "../components/typography/MayaTitle";
import ButtonWide from "../components/general/ButtonWide";
import MayaText from "../components/typography/MayaText";
import CustomTextInput from "../components/general/CustomTextInput";
// Constants
import { RouteNames } from "../navigation/routeNames";
import { colors } from "../helpers/colors";
import { setError, setLoading, setToken } from "../reducers/authSlice";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import { RootState } from "../../types";
import { loginAsyncThunk, submitTokenAsyncThunk } from "../api/auth/auth";
import { AppDispatch } from "../store/store";
import Layout from "../components/Layout/Layout";
import LoadingHOC from "./LoadingHOC";
import { useKeyboard } from "../hooks/useKeyboard";

type Props = {};

const LoginHOC: React.FC<Props> = ({}: Props) => {
  const isKeyBoardActive = useKeyboard();
  const { loading, error, token, userId, isUserPressLogout } = useSelector(
    (state: RootState) => state.persist.authReducer,
  );
  const navigation = useNavigation<NativeStackNavigationProp<any>>();
  const dispatch = useDispatch<AppDispatch>();

  const [authToken, setAuthToken] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleLogin = () => {
    if (isUserPressLogout) {
      dispatch(loginAsyncThunk({ email, password }));
      return;
    }
    console.debug("authToken", authToken);
    dispatch(submitTokenAsyncThunk({ registrationToken: authToken }));
  };
  const navigateToRegister = () => {   
    navigation.navigate(RouteNames.TopRegisterBar)
  };
  useEffect(() => {
    dispatch(setLoading(false));
    dispatch(setError(undefined));
  }, []);

  useEffect(() => {
    if (token) {
      navigation.navigate(RouteNames.Home);
    }
  }, [token]);

  if (loading) {
    return <LoadingHOC />;
  }
  return (
    <Layout>
      <KeyboardAvoidingView
        keyboardVerticalOffset={10}
        behavior={Platform.OS === "android" ? "height" : "padding"}
        style={styles.container}
      >
        <View style={styles.container}>
          <View style={styles.mainContainer}>
            <View style={styles.imageContainer}>
              <Image
                source={require("../assets/maya-min.png")}
                style={styles.image}
              />
            </View>

            <MayaTitle style={styles.welcome}>Welcome Back!</MayaTitle>
            <View style={{ alignSelf: "center" }}>
              {isUserPressLogout ? (
                <View>
                  <CustomTextInput
                    value={email}
                    setValue={setEmail}
                    placeholder="Email"
                    isPassword={false}
                    secureTextEntry={false}
                    style={{
                      height: 59,
                      padding: 10,
                      width: 350,
                    }}
                  />
                  <CustomTextInput
                    value={password}
                    setValue={setPassword}
                    placeholder="Password"
                    secureTextEntry={true}
                    isPassword={true}
                    style={{
                      height: 59,
                      padding: 10,
                      width: 350,
                    }}
                  />
                </View>
              ) : (
                <CustomTextInput
                  value={authToken}
                  setValue={setAuthToken}
                  placeholder="Token"
                  secureTextEntry={true}
                  isPassword={true}
                  style={{
                    height: 59,
                    padding: 10,
                    width: 350,
                  }}
                />
              )}

              {error && (
                <View style={styles.errorMessageContainer}>
                  <MayaText style={styles.errorMessageText}>{error}</MayaText>
                </View>
              )}

              <View style={{ alignSelf: "center" }}>
                <ButtonWide
                  disabled={
                    isUserPressLogout ? !email || !password : authToken == ""
                  }
                  loading={loading}
                  text="Login"
                  onPress={handleLogin}
                  style={{
                    height: 46,
                    width: 350,
                    padding: 10,
                    alignSelf: "center",
                  }}
                />
              </View>

              <MayaText style={styles.terms}>
                By logging in you agree to Maya’s
              </MayaText>

              <MayaText style={styles.termsLink}>
                Terms of Service, Privacy Policy.
              </MayaText>
            </View>
          </View>
          {isKeyBoardActive === 0 ? (
            <View style={styles.footerContainer}>
              <FooterText
                textWhite="I am new,"
                textBlue="Create a token :)"
                onPress={navigateToRegister}
              />
            </View>
          ) : null}
        </View>
      </KeyboardAvoidingView>
    </Layout>
  );
};

export default LoginHOC;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "transparent",
    height: "100%",
    width: "100%",
  },
  mainContainer: {
    justifyContent: "center",
    alignContent: "center",
    flex: 1,
    alignItems: "center",
  },
  imageContainer: {
    alignSelf: "center",
  },
  image: {
    alignSelf: "center",
    width: 250,
    height: "auto",
    // objectFit: "contain",
    aspectRatio: 1,
  },
  welcome: {
    marginBottom: 40,
    marginTop: 10,
  },
  textinput: {
    backgroundColor: colors.borderColor,
    padding: 15,
    borderRadius: 50,
    borderColor: colors.borderColor,
    borderWidth: 1,
  },
  forgotPassword: {
    fontWeight: "600",
    textAlign: "center",
    marginBottom: 31,
    color: colors.anakiwa,
  },
  terms: {
    fontSize: 12,
    fontWeight: "600",
    textAlign: "center",
    marginBottom: 5,
  },
  termsLink: {
    fontSize: 12,
    fontWeight: "600",
    textAlign: "center",
    color: colors.anakiwa,
  },
  checkbox: {
    width: 16,
    height: 16,
    backgroundColor: "#fff",
    marginRight: 10,
    marginBottom: 20,
  },
  errorMessageContainer: {
    marginBottom: 10,
    width: "100%",
    height: 16,
    flexDirection: "row",
    justifyContent: "center",
  },
  errorMessageText: {
    color: colors.red,
    fontSize: 16,
    fontWeight: "600",
    textAlign: "center",
  },
  footerContainer: {
    flexDirection: "row",
    justifyContent: "center",
    marginVertical: "auto",
    paddingBottom: 20,
  },
});
