import { createSlice } from "@reduxjs/toolkit";
import {
  decoderJWT,
  loginAsyncThunk,
  loginAsyncThunkLogout,
  registerAsyncThunk,
  submitTokenAsyncThunk,
} from "../api/auth/auth";
import { getUserAsyncThunk } from "../api/user/user";

export type Token = {
  access_token: string;
  expires_in: Date;
  refresh_token: string;
  AspNetUserName: string;
  TwoFactorEnabled: string;
  TwoFactorProvider: string;
  APP_WebApiUrl: string;
  issued: Date;
  expires: Date;
  error?: string;
};

export type Profile = {
  id: string | undefined;
  name: string | undefined;
  email: string | undefined;
  picture: string | undefined;
  surname: string | undefined;
  birthday: string | undefined;
  password: string | undefined;
  language: string | undefined;
  company: string | undefined;
  address: string | undefined;
  city: string | undefined;
  state: string | undefined;
  country: string | undefined;
  position: string | undefined;
  lastname: string | undefined;
  firstname: string | undefined;
  username: string | undefined;
};

export type AuthState = {
  baseUrl: string;
  token: Token | null;
  withBiometrics: boolean;
  profile: Partial<Profile> | null;
  loading: boolean;
  error: string | undefined;
  userId: string;
  isUserPressLogout: boolean;
};

const initialState: AuthState = {
  baseUrl: "https://thisIsourApiUrl.com/api/",
  token: null,
  withBiometrics: false,
  userId: "",
  profile: {
    id: "",
    name: "",
    email: "",
    picture: "",
    surname: "",
    birthday: "",
    password: "",
    language: "",
    company: "",
    address: "",
    city: "",
    state: "",
    country: "",
    position: "",
    firstname: "",
    lastname: "",
  },
  loading: false,
  error: undefined,
  isUserPressLogout: false,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setToken: (state, { payload }) => {
      state.token = payload;
    },
    setWithBiometrics: (state, { payload }) => {
      state.withBiometrics = payload;
    },
    setProfile: (state, { payload }) => {
      //@ts-ignore
      state.profile = {
        ...state.profile,
        [payload.key]: payload.value,
      };
    },
    setLoading: (state, { payload }) => {
      state.loading = payload;
    },
    setError: (state, { payload }) => {
      state.error = payload;
    },
    setUserIdAndToken: (state) => {
      const jwt = decoderJWT(state.token?.access_token);
      state.userId = jwt?.sub?.toString();
      state.token = state.token;
    },
    setUserPressLogout: (state, { payload }) => {
      state.isUserPressLogout = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loginAsyncThunk.pending, (state, action) => {
      state.loading = true;
      state.error = undefined;
      state.token = null;
    });
    builder.addCase(loginAsyncThunk.fulfilled, (state, action) => {
      console.debug(state, action.payload);
      const jwt = decoderJWT(action.payload.access_token);
      state.userId = jwt?.sub?.toString();
      state.loading = false;
      state.token = action.payload;
    });
    builder.addCase(loginAsyncThunk.rejected, (state, action) => {
      state.loading = false;
      state.token = null;
      state.error = "Authentication failed" ?? action.error.message;
    });
    builder.addCase(loginAsyncThunkLogout.pending, (state, action) => {
      state.loading = true;
      state.isUserPressLogout = false;
    });
    builder.addCase(loginAsyncThunkLogout.fulfilled, (state, action) => {
      state.loading = false;
      state.error = undefined;
      state.token = null;
      state.isUserPressLogout = true;
    });
    builder.addCase(loginAsyncThunkLogout.rejected, (state, action) => {
      state.loading = false;
      state.error = undefined;
      state.token = null;
      state.isUserPressLogout = false;
    });
    builder.addCase(registerAsyncThunk.pending, (state, action) => {});
    builder.addCase(registerAsyncThunk.fulfilled, (state, action) => {});
    builder.addCase(registerAsyncThunk.rejected, (state, action) => {});
    builder.addCase(getUserAsyncThunk.pending, (state, action) => {
      state.loading = true;
      state.profile = null;
    });
    builder.addCase(getUserAsyncThunk.fulfilled, (state, action) => {
      state.loading = false;
      state.profile = action.payload;
    });
    builder.addCase(getUserAsyncThunk.rejected, (state, action) => {
      state.loading = false;
      state.profile = null;
    });
    builder.addCase(submitTokenAsyncThunk.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(submitTokenAsyncThunk.rejected, (state, action) => {
      state.loading = false;
      state.token = null;
      state.error = "Authentication failed"; //action.error.message;
      state.profile = null;
      state.userId = "";
    });
    builder.addCase(submitTokenAsyncThunk.fulfilled, (state, action) => {
      const jwt = decoderJWT(action.payload?.access_token);
      state.userId = jwt?.sub?.toString();
      state.loading = false;
      state.token = action.payload;
    });
  },
});

export const {
  setToken,
  setWithBiometrics,
  setProfile,
  setLoading,
  setError,
  setUserIdAndToken,
  setUserPressLogout,
} = authSlice.actions;

export default authSlice.reducer;
