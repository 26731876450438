import { useEffect, useState } from "react";
import { Dimensions, Platform } from "react-native";
import { setMobile } from "../reducers/dimentionSlice";
import { dispatch } from "../store/store";

const useScreenDimensionChange = () => {
  const [dimensions, setDimensions] = useState(Dimensions.get("window").width);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (Platform.OS == "web") {
      const handleScreenDimensionsChange = (newDimensions) => {
        const newWidth = Dimensions.get("window").width;
        setDimensions(newWidth);
      };

      handleScreenDimensionsChange(Dimensions.get("window"));

      Dimensions.addEventListener("change", handleScreenDimensionsChange);
      if (dimensions < 966) {
        setIsMobile(true);
        dispatch(setMobile(true));
      } else {
        setIsMobile(false);
        dispatch(setMobile(false));
      }
      return;
    }
    setIsMobile(true);
    dispatch(setMobile(true));
  }, [dimensions]);
  return { isMobile, dimensions };
};

export default useScreenDimensionChange;
