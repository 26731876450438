import React from "react";
import { Dimensions, Platform, StyleSheet, Text, View } from "react-native";
import { useSelector } from "react-redux";
import { RootState } from "../../../types";
import { colors } from "../../helpers/colors";
const ProcessingTraining = ({
  processingFiles,
}: {
  processingFiles: boolean;
}) => {
  if (processingFiles) {
    return (
      <View style={styles.container}>
        <Text style={styles.text}>Processing file/s for training</Text>
      </View>
    );
  }
  return <></>;
};
const styles = StyleSheet.create({
  container: {
    justifyContent: "center",
    alignItems: "center",
    padding: 3,
    backgroundColor: colors.springGreen,
    height: 25,
    opacity: 1,
    width: "100%",
  },
  text: {
    fontSize: 11,
    fontWeight: "bold",
    color: "black",
  },
});
export default ProcessingTraining;
