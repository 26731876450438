import React from "react";
import { StyleSheet, View } from "react-native";

// Components
import { colors } from "../helpers/colors";
import UrlInputScreen from "../components/training/UrlInputString";
import MobileViewHOC from "./MobileViewHOC";

type Props = {};

const UrlHOC: React.FC = ({}: Props) => {
  return <UrlInputScreen />;
};

export default UrlHOC;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.background,
  },
});
