import { Node } from "./node.services";
export class Edge {
  _value: any;
  _fromNode: Node;
  _toNode: Node;

  constructor(fromNode: Node, toNode: Node, value: any) {
    this._value = value;
    this._fromNode = fromNode;
    this._toNode = toNode;
  }

  compare(result: any): boolean {
    if (this._value === "any") return true;
    return result !== null && result === this._value;
  }
}
