import React from "react";
import { StyleSheet, Text, TextStyle } from "react-native";

type Props = {
  style?: TextStyle;
  children?: any;
};

const MayaTitle = ({ style, children }: Props) => {
  return (
    <Text style={[styles.textStyle, style]}>{children ? children : "-"}</Text>
  );
};

export default MayaTitle;

const styles = StyleSheet.create({
  textStyle: {
    fontFamily: "Montserrat",
    color: "#FFF",
    fontSize: 24,
    fontWeight: "700",
    textAlign: "center",
  },
});
