import exp from "constants";
import {
  ETrainingStatus,
  HistoryNotification,
  TrainingEvent,
} from "../models/trainingEvent.model";
import { createSlice } from "@reduxjs/toolkit";
import {
  getNotificationAsyncThunk,
  updateReadStatusAsyncThunk,
} from "../api/notifications/notification";

export type NotificationState = {
  trainingEvent: HistoryNotification[];
  display: boolean;
};

const initialState: NotificationState = {
  trainingEvent: [],
  display: false,
};

export const notificationSlice = createSlice({
  name: "notification_slice",
  initialState,
  reducers: {
    setNotification: (state, action) => {
      state.trainingEvent = [...state.trainingEvent, action.payload];
    },
    closeNotification: (state) => {
      state.display = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getNotificationAsyncThunk.pending, (state, action) => {
      state.display = true;
      state.trainingEvent = action.payload;
    });
    builder.addCase(getNotificationAsyncThunk.fulfilled, (state, action) => {
      state.display = true;
      state.trainingEvent = action.payload.filter((item) => !item.read);
    });
    builder.addCase(getNotificationAsyncThunk.rejected, (state, action) => {
      state.display = true;
      state.trainingEvent = [];
    });
    builder.addCase(updateReadStatusAsyncThunk.fulfilled, (state, action) => {
      state.display = true;
      if (action.payload) {
        state.trainingEvent = state.trainingEvent.filter(
          (item) => item.request_id !== action.payload.requestId
        );
      }
    });
  },
});

export const { setNotification, closeNotification } = notificationSlice.actions;
export default notificationSlice.reducer;
