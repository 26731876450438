import { Platform, StyleSheet, Text, TouchableOpacity, View } from "react-native";
import React from "react";
import { TOptionsWorkFlow } from "../../models/workFlow.model";
import { colors } from "../../helpers/colors";

export const ChipWorkFlow = ({
  option,
  handleChipSelect,
}: {
  option: TOptionsWorkFlow;
  handleChipSelect: (option: TOptionsWorkFlow) => void;
}) => {
  return (
    <TouchableOpacity onPress={() => handleChipSelect(option)}>
      <View style={styles.chipWrapper}>
        <Text style={styles.text}>{option.name}</Text>
      </View>
    </TouchableOpacity>
  );
};
const styles = StyleSheet.create({
  text: {
    color: "white",
    fontSize: Platform.OS=='web'?16: 18,
    padding: 2,
  },
  chipContainer: {
    flexDirection: "row",
    justifyContent: "flex-start",
    flexWrap: "nowrap",
    height: "auto",
  },
  chipWrapper: {
    borderRadius: 38,
    borderColor: "rgba(255, 255, 255, 0.20)",
    borderWidth: 1,
    paddingVertical: 8,
    paddingHorizontal: Platform.OS=='web'?6:10,
    backgroundColor: colors.mineshaft,
    margin: 10,
  },
});

export default ChipWorkFlow;
