import { getDAGExecService } from "./dag.services";
import { Edge } from "./edge.service";
import { EXECUTABLE_TYPE } from "./execution-factory.service";

export class Node {
  private _executable: any;
  private _inEdges: Set<Edge>;
  private _outEdges: Set<Edge>;

  constructor(executable: any) {
    this._executable = executable;
    this._inEdges = new Set();
    this._outEdges = new Set();
  }

  passExternalResponse(obj: any) {
    this._executable.setExternalInput(obj);
  }

  passViewContextObject(obj: any) {
    this._executable.setViewContext(obj);
  }

  passHeaderObject(obj: any) {
    this._executable.setHeader(String(obj));
  }

  initObjects(context: any, chatId: string) {
    this._executable.setContext(context);
    this._executable.setChatId(chatId);
  }

  addEdge(targetNode: Node, value: any): Node {
    const e = new Edge(this, targetNode, value);

    this._outEdges.add(e);
    targetNode._inEdges.add(e);
    return this;
  }

  execute(): any {
    let foundIt = false;
    const result = this._executable.execute();
    if (result !== null) {
      for (const edge of this._outEdges) {
        if (edge.compare(result)) {
          foundIt = true;
          edge._toNode.execute();
        }
      }
    }
    return foundIt;
  }
}

export function createNode(
  showType: string,
  responseMessage: string,
  answers: any,
  answersList: any,
  nodeType: string
): Node | null {
  let executableType: string | null = null;

  // the following code can be deleted if there is no need for validation.
  // TODO write validation code instead of the braindead code below
  if (
    showType === EXECUTABLE_TYPE.GET_INPUT ||
    showType === EXECUTABLE_TYPE.MESSAGE ||
    showType === EXECUTABLE_TYPE.HIDDEN
  ) {
    executableType = showType;
  }

  if (executableType === null) throw new Error("unsupported exec type");

  const executable = getDAGExecService().create_executable(
    executableType,
    responseMessage,
    answers
  );

  return executable !== null ? new Node(executable) : null;
}
