import React from "react";
import { Bubble } from "react-native-gifted-chat";
import DotChat from "../DotChat/DotChat";
import { StyleSheet, Text, useWindowDimensions } from "react-native";
import dayjs from "dayjs";
import RenderHTML from "react-native-render-html";
import { useSelector } from "react-redux";
import { RootState } from "../../../types";

const BubbleRender = ({ props }: { props: any }) => {
  const { userId } = useSelector(
    (state: RootState) => state.persist.authReducer,
  );
  const { width } = useWindowDimensions();

  const BubbleText = () => {
    return (
      <Bubble
        {...props}
        renderMessageText={(bubbleProps) => {
          return (
            <RenderHTML
              {...bubbleProps}
              contentWidth={width}
              source={{
                html: `<div style="color: ${
                  bubbleProps.currentMessage.user._id === userId
                    ? "black"
                    : "white"
                };padding: 10px 10px;text-align: start;font-size: 16px">${
                  bubbleProps.currentMessage.text
                }</div>`,
              }}
            />
          );
        }}
        wrapperStyle={{
          right: {
            backgroundColor: "#D7E2FC",
          },
          left: {
            backgroundColor: "#FFFFFF26",
          },
        }}
        textStyle={{
          right: {
            color: "#161616",
          },
          left: {
            color: "#efefef",
          },
        }}
        renderTime={(props) => {
          return (
            <Text {...props} style={styles.textTime}>
              {dayjs(props?.currentMessage?.createdAt).format("HH:mm")}
            </Text>
          );
        }}
      />
    );
  };

  const { currentMessage } = props;
  if (currentMessage.loading) {
    return (
      <Bubble
        {...props}
        renderCustomView={() => {
          return <DotChat />;
        }}
        wrapperStyle={{
          right: {
            backgroundColor: "#D7E2FC",
          },
          left: {
            backgroundColor: "#FFFFFF26",
          },
        }}
        textStyle={{
          right: {
            color: "#161616",
          },
          left: {
            color: "#efefef",
          },
        }}
        renderTime={(props) => {
          return null;
        }}
      />
    );
  }

  return <BubbleText />;
};

const styles = StyleSheet.create({
  textTime: {
    fontSize: 10,
    color: "gray",
    paddingVertical: 5,
    paddingHorizontal: 10,
  },
});

export default BubbleRender;
