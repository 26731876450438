import { createSlice } from "@reduxjs/toolkit";
import { TOptionsWorkFlow } from "../models/workFlow.model";
import { EdgesConsumable, IDoingWork } from "../models/doingWotrk.model";
import { workFlowSendAsyncThunk } from "../api/doing-work/doing-work";
import { ChatMessage } from "../models/message";
import { GiftedChat } from "react-native-gifted-chat";
import { formatMessageForChat } from "../services/chat.service";

export enum ChatStateEnum {
  TEXT = "TEXT",
  NEUTRAL = "NEUTRAL",
  UPLOADING = "UPLOADING",
}

export type ChatState = {
  promptMessage: string | undefined;
  loadingChat: boolean;
  error: string | undefined;
  state: ChatStateEnum;
  message: string;
  workFlow?: TOptionsWorkFlow | undefined;
  set?: TOptionsWorkFlow | undefined;
  activateWorkFlow: boolean;
  workOptions: IDoingWork | undefined;
  selectedByUserFlow: EdgesConsumable;
  workFlowLoading: boolean;
  messages: ChatMessage[];
};

const initialState: ChatState = {
  promptMessage: undefined,
  loadingChat: false,
  error: undefined,
  state: ChatStateEnum.NEUTRAL,
  message: "",
  workFlow: undefined,
  set: undefined,
  activateWorkFlow: false,
  workOptions: undefined,
  selectedByUserFlow: undefined,
  workFlowLoading: false,
  messages: [],
};

const ChatSlice = createSlice({
  name: "chat_slice",
  initialState,
  reducers: {
    setPromptMessage: (state, action) => {
      state.promptMessage = action.payload;
    },
    setStateInput: (state, action) => {
      state.state = action.payload;
    },
    setMessagesChat: (state, action) => {
      const messageFormated = {
        ...action.payload,
        createdAt: action.payload.createdAt.toString(),
      };
      const removeLoadingAndGetMessage = state.messages.filter(
        (msm) => !msm.loading
      );
      state.messages = GiftedChat.append(
        removeLoadingAndGetMessage,
        messageFormated
      );
    },
    addLoadingMessageBubble: (state) => {
      const loadingMessage = formatMessageForChat({
        message: "",
        loading: true,
      });
      state.messages = GiftedChat.append(state.messages, [loadingMessage]);
    },
    setWorkFlow: (state, action) => {
      state.workFlow = action.payload;
      state.activateWorkFlow = true;
    },
    clearWorkFlow: (state) => {
      state.workFlow = undefined;
    },
    stopWorkFlow: (state) => {
      state.activateWorkFlow = false;
      state.selectedByUserFlow = undefined;
      state.workFlow = undefined;
    },
    setWorkOption: (state, action) => {

      state.workOptions = action.payload;
    },
    setFlowByUser: (state, action) => {
      state.selectedByUserFlow = action.payload;
    },
    setAnswerToQuestion: (state, action) => {
      const { answer, selectedNodeToAnswer } = action.payload;

      state.workOptions = {
        nodes_consumable:
          state.workOptions.nodes_consumable.map((node) => {

            if (node.id === action.payload.selectedNodeToAnswer.id) {

              // node.answers = action.payload.answer;
              node.answers = answer;
            }
            return node;
          }),
        edges_consumable: state.workOptions.edges_consumable,
        id: state.workOptions.id,
      };

    },
  },
  extraReducers: (builder) => {
    builder.addCase(workFlowSendAsyncThunk.pending, (state, action) => {
      state.workFlowLoading = true;
    });
    builder.addCase(workFlowSendAsyncThunk.fulfilled, (state, action) => {
      state.workFlowLoading = false;
    });
    builder.addCase(workFlowSendAsyncThunk.rejected, (state, action) => {
      const failMessage = formatMessageForChat({
        message: "Maya fail to generate this work flow. Please try again.",
        loading: false,
      });
      state.workFlowLoading = false;
      state.messages = GiftedChat.append(state.messages, [failMessage]);
    });
  },
});

export const {
  setPromptMessage,
  setStateInput,
  setWorkFlow,
  clearWorkFlow,
  setWorkOption,
  setFlowByUser,
  stopWorkFlow,
  setAnswerToQuestion,
  setMessagesChat,
  addLoadingMessageBubble,
} = ChatSlice.actions;

export default ChatSlice.reducer;
