import React, { useEffect, useRef, useState } from "react";
import { Animated, Easing, Image, Platform, View } from "react-native";
import { colors } from "../../helpers/colors";
import useHayMayaRecognition from "../../hooks/useHayMayaRecognition";
import Voice from "@react-native-voice/voice";

const ActiveSpeechVoice = ({ volumeChange,recognitionEnd,recognition }) => {
  const volumeScale = useRef(new Animated.Value(0)).current;
  const [recognitionEnded, setRecognitionEnded] = useState(false);


  useEffect(() => {
    // if (Platform.OS === "web") {
    //   startWebWaveAnimation();
    // } else {
    //   startWaveAnimation(volumeChange);
    // }
    // if(recognitionEnd){
    //   console.log("ended recognition")
    //   setRecognitionEnded(true)
    // }
    // else{
    //   console.log("recognition startd")
    // }

  }, []);

  // const startWebWaveAnimation = () => {
  //   Animated.loop(
  //     Animated.sequence([
  //       Animated.timing(volumeScale, {
  //         toValue: 0,
  //         duration: 200,
  //         easing: Easing.bounce,
  //         useNativeDriver: true,
  //       }),
  //       Animated.timing(volumeScale, {
  //         toValue: 1,
  //         duration: 200,
  //         easing: Easing.bounce,
  //         useNativeDriver: true,
  //       }),
  //     ]),
  //   ).start();
  // };

  // const startWaveAnimation = (volumeChangeParam) => {
  //   Animated.timing(volumeScale, {
  //     toValue: volumeChangeParam ? 3 : 0,
  //     duration: 150,
  //     easing: Easing.bounce,
  //     useNativeDriver: true,
  //   }).start();
  // };

  return (
    <>
      {/* {recognitionEnd? null:( */}

        <Image
          source={require("../../assets/transp_wave.gif")}
          style={{ width: Platform.OS=='web'?'30%':'60%', height: 80,bottom:Platform.OS=='web'?20:'2%' }}
        />
      {/* )} */}
    </>
  );
};

export default ActiveSpeechVoice;
