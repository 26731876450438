import React from "react";
import { StyleSheet, View } from "react-native";

// Components
import MayaText from "../components/typography/MayaText";
import { colors } from "../helpers/colors";

// Icons
import CameraIcon from "../icons/video";
import ButtonWide from "../components/general/ButtonWide";

type Props = {};

const LiveTrainingHOC: React.FC = ({}: Props) => {

  const onPress = (key: string, newValue: any) => {
    console.log("onPress");
  }

  return (
    <View style={styles.container}>
      <View style={styles.cameraContainer}>
        <CameraIcon />
      </View>

      <MayaText
        style={{ fontSize: 16, fontWeight: "500", textAlign: "center" }}
      >
        You can practice before training :)
      </MayaText>

      <View style={styles.buttonWrapper}>
        <ButtonWide
          text={"Start Training"}
          onPress={() => onPress("startTraining", true)}
        />
      </View>
    </View>
  );
};

export default LiveTrainingHOC;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.background,
    padding: 20,
  },
  cameraContainer: {
    height: 500,
    backgroundColor: colors.background2,
    marginBottom: 20,
    borderRadius: 30,
    justifyContent: "center",
    alignItems: "center",
  },
  buttonWrapper: {
    padding: 10,
    borderTopColor: colors.borderColor,
    borderTopWidth: 1,
    marginTop: "auto",
  },
});
