import React from "react";
import { Pressable, StyleSheet } from "react-native";
import { colors } from "../../helpers/colors";

// Components
import MayaText from "./MayaText";

type Props = {
  textWhite: string;
  textBlue: string;
  onPress?: () => void;
};

const FooterText = ({ textWhite, textBlue, onPress }: Props) => {
  return (
    <Pressable onPress={onPress}>
      <MayaText style={styles.container}>
        {textWhite}{" "}
        <MayaText style={{ color: colors.anakiwa }}>{textBlue}</MayaText>
      </MayaText>
    </Pressable>
  );
};

export default FooterText;

const styles = StyleSheet.create({
  container: {
    textAlign: "center",
    fontWeight: "600",
    fontSize: 12,
  },
});
