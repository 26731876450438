// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, {  useState } from "react";
import { FlatList, StyleSheet, View } from "react-native";

// Constants
import { colors } from "../helpers/colors";

// Helpers
import useApi from "../api/apis";
import { themeMapper, themes } from "../helpers/constants";

// Components
import MayaText from "../components/typography/MayaText";
import ListItem from "../components/writeSomething/listItem";
import WriteSomethingModal from "../components/writeSomething/writeSomethingModal";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../store/store";
import {
  writeSomethingByThemeAsyncThunk,
  WriteSomethingInput,
} from "../api/insight/insight";

const WriteSomethingListHOC: React.FC = () => {
  const { postWriteSomething } = useApi();
  const dispatch = useDispatch<AppDispatch>();
  const [selectedTheme, setSelectedTheme] = useState("");
  const [selectedValue, setselectedValue] = useState(null);
  const [textValue, setTextValue] = useState("");

  const closeModal = () => {
    setSelectedTheme("");
  };

  const handlePostWriteSomething = () => {
    console.log("handlePostWriteSomething");
    // postWriteSomething(
    //   selectedValue,
    //   textValue,
    //   themeMapper[selectedTheme].title
    // );
    const body: WriteSomethingInput = {
      thinklikeachore: undefined,
      manset: textValue,
    };
    dispatch(
      writeSomethingByThemeAsyncThunk({
        theme: themeMapper[selectedTheme].title,
        body,
      })
    );

    setselectedValue(null);
    setTextValue("");
    setSelectedTheme("");
  };

  return (
    <View style={styles.container}>
      <MayaText style={{ fontWeight: "700", fontSize: 16, marginBottom: 20 }}>
        PICK A THEME
      </MayaText>

      <FlatList
        data={themes}
        renderItem={({ item, index }) => {
          console.log("item", themeMapper[item]);

          return (
            <ListItem
              title={item}
              index={index}
              image={themeMapper[item].icon}
              onPress={() => setSelectedTheme(item)}
            />
          );
        }}
        numColumns={2}
        ItemSeparatorComponent={() => <View style={{ height: 10 }} />}
        ListFooterComponent={() => <View style={{ height: 20 }} />}
      />

      <WriteSomethingModal
        selectedTheme={selectedTheme}
        closeModal={closeModal}
        selectedValue={selectedValue}
        setselectedValue={setselectedValue}
        handlePostWriteSomething={handlePostWriteSomething}
        textValue={textValue}
        setTextValue={setTextValue}
      />
    </View>
  );
};

export default WriteSomethingListHOC;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.background,
    padding: 20,
  },
});
