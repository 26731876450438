import React, { createContext, useState } from "react";

const UserContext = createContext({
  userData: {
    fullName: "",
    firstName: "",
    lastName: "",
    company: "",
    phone: "",
    email: "",
    password: "",
    planType: "",
  },
  saveUserData: (data) => {},
});

const UserProvider = ({ children }) => {
  const [userData, setUserData] = useState({
    fullName: "",
    firstName: "",
    lastName: "",
    company: "",
    phone: "",
    email: "",
    password: "",
    planType: "",
  });

  const saveUserData = (data) => {
    setUserData(data);
  };

  return (
    <UserContext.Provider value={{ userData, saveUserData }}>
      {children}
    </UserContext.Provider>
  );
};

export { UserContext, UserProvider };
