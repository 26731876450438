// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { RouteNames } from "./routeNames";
import React from "react";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import { getHeaderTitle } from "@react-navigation/elements";
import NavigationHeader from "./NavigationHeader";
import HomeHOC from "../containers/HomeHOC";
import WriteSomethingListHOC from "../containers/WriteSomethingListHOC";
import ProfileHOC from "../containers/ProfileHOC";
import LoadingHOC from "../containers/LoadingHOC";
import ChatHOC from "../containers/ChatHOC";
import OptionsNavigationHeader from "./OptionsNavigationHeader";
import EditValueScreen from "../components/profile/SingleInputString";
import WorksNavigator from "./WorksNavigator";
import FolderScreen from "../components/works/folders/folderScreen";
import TraningListHOC from "../containers/TrainingListHOC";
import LiveTrainingHOC from "../containers/LiveTrainingHOC";
import UrlHOC from "../containers/UrlHOC";
import DocumentTrainingHOC from "../containers/DocumentTrainingHOC";
import PromptHOC from "../containers/PromptHOC";
import SettingsHOC from "../containers/SettingsHOC";
import SpeechHOC from "../containers/SpeechHOC";
const Stack = createNativeStackNavigator();

const AppStack = () => {
  return (
    <Stack.Navigator
      initialRouteName={RouteNames.Splash}
      screenOptions={{ headerShown: false }}
    >
      <Stack.Group
        screenOptions={{
          headerShown: false,
          header: ({ navigation, route, options, back }) => {
            const title = getHeaderTitle(options, route.name);

            return (
              <NavigationHeader
                title={title}
                back={!!back}
                navigation={navigation}
                showMenu={true}
              />
            );
          },
        }}
      >
        <Stack.Screen name={RouteNames.Home} component={PromptHOC} />
        <Stack.Screen
          name={RouteNames.WriteSomethingList}
          component={WriteSomethingListHOC}
        />
        <Stack.Screen
          options={{
            headerShown: true,
            header: ({ navigation, route, options, back }) => {
              const title = getHeaderTitle(options, route.name);

              return (
                <OptionsNavigationHeader
                  title={title}
                  back={!!back}
                  navigation={navigation}
                  showMenu={false}
                />
              );
            },
          }}
          name={RouteNames.Profile}
          component={ProfileHOC}
        />
        <Stack.Screen
          name={RouteNames.Loading}
          component={LoadingHOC}
          options={{
            presentation: "modal",
            headerShown: false,
          }}
        />
        <Stack.Screen
          name={RouteNames.Chat}
          component={ChatHOC}
          options={{
            headerShown: true,
            header: ({ navigation, route, options, back }) => {
              const title = getHeaderTitle(options, route.name);
              return (
                <OptionsNavigationHeader
                  title={"Maya"}
                  back={true}
                  navigation={navigation}
                  showMenu={false}
                  headerRight={options}
                />
              );
            },
          }}
        />

        <Stack.Screen
          name={RouteNames.SingleInputScreen}
          component={EditValueScreen}
          options={{
            headerShown: true,
            header: ({ navigation, route, options, back }) => {
              const title = getHeaderTitle(options, route.name);

              return (
                <OptionsNavigationHeader
                  title={title}
                  back={!!back}
                  navigation={navigation}
                  showMenu={false}
                />
              );
            },
          }}
        />

        <Stack.Screen
          name={RouteNames.Works}
          component={WorksNavigator}
          options={{
            headerShown: true,
            header: ({ navigation, route, options, back }) => {
              const title = getHeaderTitle(options, route.name);

              return (
                <OptionsNavigationHeader
                  title={title}
                  back={!!back}
                  navigation={navigation}
                  showMenu={false}
                  headerRight={options}
                />
              );
            },
          }}
        />

        <Stack.Screen
          name={RouteNames.FolderScreen}
          component={FolderScreen}
          options={{
            headerShown: true,
            header: ({ navigation, route, options, back }) => {
              const title = getHeaderTitle(options, route.name);

              console.log("options", options);

              return (
                <OptionsNavigationHeader
                  title={title}
                  back={!!back}
                  navigation={navigation}
                  showMenu={false}
                  headerRight={options}
                />
              );
            },
          }}
        />

        <Stack.Screen
          name={RouteNames.TrainingList}
          component={TraningListHOC}
          options={{
            headerShown: true,
            header: ({ navigation, route, options, back }) => {
              const title = getHeaderTitle(options, route.name);

              return (
                <OptionsNavigationHeader
                  title={title}
                  back={!!back}
                  navigation={navigation}
                  showMenu={false}
                />
              );
            },
          }}
        />
        <Stack.Screen
          name={RouteNames.Settings}
          component={SettingsHOC}
          options={{
            headerShown: true,
            header: ({ navigation, route, options, back }) => {
              const title = getHeaderTitle(options, route.name);

              return (
                <OptionsNavigationHeader
                  title={title}
                  back={!!back}
                  navigation={navigation}
                  showMenu={false}
                />
              );
            },
          }}
        ></Stack.Screen>

        <Stack.Group
          screenOptions={{
            headerShown: true,
            header: ({ navigation, route, options, back }) => {
              const title = getHeaderTitle(options, route.name);

              return (
                <OptionsNavigationHeader
                  title={title}
                  back={!!back}
                  navigation={navigation}
                  showMenu={false}
                />
              );
            },
          }}
        >
          <Stack.Screen
            name={RouteNames.LiveLearning}
            component={LiveTrainingHOC}
          />
          <Stack.Screen name={RouteNames.URL} component={UrlHOC} />
          <Stack.Screen name={RouteNames.DOC} component={DocumentTrainingHOC} />
          <Stack.Screen name={RouteNames.DOCUMENT} component={DocumentTrainingHOC} />
          <Stack.Screen name={RouteNames.CSV} component={DocumentTrainingHOC} />
        </Stack.Group>
      </Stack.Group>
    </Stack.Navigator>
  );
};

export default AppStack;
