import React, { useEffect, useState } from "react";
import {
  Alert,
  Image,
  Platform,
  Pressable,
  StyleSheet,
  Text,
  TextInput,
  View,
} from "react-native";
import { colors } from "../../helpers/colors";
import InputIpFormatter from "../InputIpFormatter/InputIpFormatter";
import { getIpFromDomain } from "../../services/get-ip-from-domain";
import { checkIsTextHasRegex } from "../../helpers/utils";

const MASK_TEXT = [
  /[0-9]/,
  /[0-9]/,
  ".",
  /[0-9]/,
  /[0-9]/,
  ".",
  /[0-9]/,
  /[0-9]/,
  ".",
  /[0-9]/,
  /[0-9]/,
];

export type IpPortInputProps = {
  ipPlaceholder: string;
  portPlaceHolder?: string;
  handleText: (baseUrl: string) => void;
  clearValues: boolean;
};

const IpPortInput = ({
  ipPlaceholder,
  portPlaceHolder,
  handleText,
  clearValues,
}: IpPortInputProps) => {
  const [ip, setIp] = useState("");
  const [port, setPort] = useState("");
  const [ipFromDomain, setIpFromDomain] = useState("");
  const [userEnterDomain, setUserEnterDomain] = useState(false);

  const changePortInput = (ev: string) => {
    const onlyNumberPort = ev.replace(/[^0-9]/g, "");
    setPort(onlyNumberPort);
    const selectedUrl =
      Number(onlyNumberPort) === 80
        ? `http://${ip}:${onlyNumberPort}`
        : `https://${ip}:${onlyNumberPort}`;
    handleText(selectedUrl);
  };

  const handleSaveUrl = (text: string) => {
    setUserEnterDomain(false);
    if (!text) {
      setIpFromDomain("");
      return;
    }

    console.debug("checkIsTextHasRegex", checkIsTextHasRegex(text));

    if (checkIsTextHasRegex(text)) {
      setUserEnterDomain(true);
    }
    const baseUrl = port ? `${ip}:${port}` : `${text}`;
    const onlyNumberIp = text.replace("..", ".");
    setIp(onlyNumberIp);
    handleText(`https://${onlyNumberIp}`);
  };

  useEffect(() => {
    if (clearValues) {
      setIp("");
      setPort("");
      handleText("");
      setIpFromDomain("");
      setUserEnterDomain(false);
    }
  }, [clearValues]);

  const checkDomainName = () => {
    getIpFromDomain(ip)
      .then((res) => {
        if (!res) {
          Alert.alert("Domain does not exist");
        }
        setIpFromDomain(res);
      })
      .catch((err) => {
        setIpFromDomain("");
      });
  };
  return (
    <View style={styles.containerColumn}>
      <View style={styles.container}>
        <InputIpFormatter
          placeholder={ipPlaceholder}
          handleSaveUrl={handleSaveUrl}
          clearValues={clearValues}
        />
        <View style={styles.semicolon}>
          <Text>:</Text>
        </View>
        <TextInput
          style={styles.inputPort}
          value={port}
          placeholder={portPlaceHolder}
          placeholderTextColor={colors.placeholder}
          onChangeText={changePortInput}
          maxLength={4}
        />
        {userEnterDomain && (
          <Pressable onPress={checkDomainName}>
            <View style={styles.customButton}>
              <Image
                source={require("../../assets/check-button.png")}
                style={styles.imageButton}
              />
            </View>
          </Pressable>
        )}
      </View>
      {ipFromDomain && userEnterDomain && (
        <Text style={styles.ipFromDomain}>{ipFromDomain}</Text>
      )}
    </View>
  );
};
const styles = StyleSheet.create({
  containerColumn: {
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    width: "100%",
  },
  container: {
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
    marginVertical: 5,
  },
  input: {
    backgroundColor: colors.background2,
    borderRadius: 30,
    padding: Platform.OS === "web" ? 10 : 5,
    borderColor: colors.borderColor,
    borderWidth: 1,
    color: "#fff",
    fontSize: 18,
    maxWidth: 200,
    width: "100%",
  },
  inputPort: {
    backgroundColor: colors.background2,
    borderRadius: 30,
    padding: Platform.OS === "web" ? 10 : 5,

    borderColor: colors.borderColor,
    borderWidth: 1,
    color: "#fff",
    fontSize: 18,
    width: 90,
  },
  semicolon: {
    color: "white",
    fontSize: 18,
    marginHorizontal: 10,
  },
  customButton: {
    borderRadius: 0,
    // backgroundColor: colors.springGreen,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    height: 20,
    width: 20,
    marginLeft: 10,
  },
  ipFromDomain: {
    fontSize: 12,
    color: "white",
    paddingLeft: 10,
    paddingBottom: 5,
  },
  imageButton: {
    height: 30,
    width: 30,
    objectFit: "contain",
  },
});
export default IpPortInput;
