export const RouteNames = {
  Home: "Home",
  Splash: "Splash",
  Login: "Login",
  Register: "Register",
  Prompt:"PromptHOC",
  Pay:"Payment",
  Plan:"Plans",
  Promt:"PromptHOC",

  TopRegisterBar:"TopRegisterBar",
  TopBar:"TopBar",
  
  Activation:"Activate",
  ForgotPassword: "ForgotPassword",
  ResetPassword: "ResetPassword",
  WriteSomethingList: "Generate Insight",
  TrainingList: "Training Maya",
  Profile: "User Profile",
  Loading: "Loading",
  Chat: "Chat",
  SingleInputScreen: "SingleInputScreen",
  Works: "Works",
  WebView: "WebView",
  Settings: "Settings",
  Speech: 'Speech',
 
  // Works
  WorklistHOC: "WorklistHOC",
  Folders: "Folders",
  Chats: "Chats",
  Training: "Training",
  FolderScreen: "FolderScreen",

  // Training
  LiveLearning: "Live Learning",
  URL: "URL",
  // PDF: "PDF",
  DOCUMENT: "DOCUMENT",

  DOC: "DOC",
  CSV: "CSV",
  Text: "Text",
};