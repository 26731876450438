import React from "react";
import { StyleSheet, ImageBackground, Dimensions, View } from "react-native";
import { useSelector } from "react-redux";
import { colors } from "../../helpers/colors";
import { BlurView } from "expo-blur";

type Props = {
  children: React.ReactNode;
  paddingHorizontal?: number;
  footerComponent?: React.ReactNode;
};

const LoginLayout: React.FC<Props> = ({
  children,
  paddingHorizontal = 15,
  footerComponent,
}: Props) => {
  // const {isLoading} = useSelector(state => state);

  return (
    <ImageBackground
      style={[styles.container, { paddingHorizontal: paddingHorizontal }]}
      source={require("../../assets/loginBackground.gif")}
    >
      {/* {isLoading && (
        <View style={styles.loading}>
          <Loading />
        </View>
      )} */}

      <BlurView
        style={styles.absolute}
        blurType="dark"
        blurAmount={3}
        reducedTransparencyFallbackColor="white"
      />
      <View>{children}</View>

      {footerComponent}
    </ImageBackground>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.background,
    flex: 1,
    justifyContent: "space-between",
  },
  loading: {
    backgroundColor: "#ffffff66",
    height: Dimensions.get("screen").height,
    width: Dimensions.get("screen").width,
    zIndex: 10,
  },
  absolute: {
    position: "absolute",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
  },
});
export default LoginLayout;
