import React, { useCallback } from "react";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import { NavigationContainer } from "@react-navigation/native";
import { useSelector } from "react-redux";

//Screens
import { getDeepLink } from "../components/LinkUrl/LinkUrl";
import { Linking, View } from "react-native";
import AppStack from "./AppStack";
import AuthStack from "./AuthStack";
import { useFonts } from "expo-font";
import { colors } from "../helpers/colors";
// import * as SplashScreen from "expo-splash-screen";

// SplashScreen.preventAutoHideAsync();

const Stack = createNativeStackNavigator();
const linking = {
  prefixes: [getDeepLink()],
  config: {
    screens: {
      Login: "login",
    },
  },
  async getInitialURL() {
    // Check if app was opened from a deep link
    const url = await Linking.getInitialURL();
    if (url != null) {
      return url;
    }
  },
  // Custom function to subscribe to incoming links
  subscribe(listener: any) {
    // Listen to incoming links from deep linking
    const linkingSubscription = Linking.addEventListener("url", ({ url }) => {
      listener(url);
    });

    return () => {
      // Clean up the event listeners
      linkingSubscription.remove();
    };
  },
};

const RootNavigation: React.FC = () => {
  const { token } = useSelector((state: any) => state.persist.authReducer);
  const [fontsLoaded] = useFonts({
    Montserrat: require("../../assets/fonts/Montserrat-Regular.ttf"),
  });

  const onLayoutRootView = useCallback(async () => {
    if (fontsLoaded) {
    }
  }, [fontsLoaded]);

  if (!fontsLoaded) {
    return null;
  }
  return (
    <NavigationContainer>
      <View
        style={{
          flex: 1,
          backgroundColor: colors.background,
        }}
        onLayout={onLayoutRootView}
      >
        {!token ? <AuthStack /> : <AppStack />}
      </View>
    </NavigationContainer>
  );
};

export default RootNavigation;
