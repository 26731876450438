import React, { useEffect, useState } from "react";
import { StyleSheet, TextInput, View } from "react-native";
import { useNavigation, useRoute } from "@react-navigation/native";

// Constants
import { colors } from "../../helpers/colors";

// Components
import ButtonWide from "../general/ButtonWide";
import MayaText from "../typography/MayaText";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../store/store";
import { RootState } from "../../../types";
import { postUrlAsyncThunk } from "../../api/urlInput/urlInput";
import {
  setClearStateOnUnLoad,
  setResponseMessage,
  UrlInputStatus,
} from "../../reducers/urlInputSlice";
import MobileViewHOC from "../../containers/MobileViewHOC";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import { RouteNames } from "../../navigation/routeNames";

type Params = {
  key?: string;
  name?: string;
  value?: string;
  subtitle?: string;
  buttonText?: string;
};

const UrlInputScreen: React.FC = () => {
  const navigation = useNavigation<NativeStackNavigationProp<any>>();
  const { responseMessage, loadingUrl, status } = useSelector(
    (state: RootState) => state.root.urlInputReducer,
  );
  const { userId, token } = useSelector(
    (state: RootState) => state.persist.authReducer,
  );
  const dispatch = useDispatch<AppDispatch>();
  const route = useRoute();
  const params: Params | null = route && route.params ? route?.params : null;
  const key = params?.key || "";
  const name = params?.name || "URL";
  const subtitle =
    params?.subtitle ||
    "Team Maya, make sure this is a public site and I'm allowed to learn from it : )";
  const buttonText = params?.buttonText || "Start Training";

  const [newValue, setNewValue] = useState("");

  const handlePost = async (value: string) => {
    dispatch(postUrlAsyncThunk({ url: value, userid: userId }));
    setNewValue("");
    dispatch(setResponseMessage("-"));
  };

  useEffect(() => {
    navigation.setOptions({
      title: name,
    });
    return () => {
      dispatch(setResponseMessage("-"));
    };
  }, [route, navigation]);

  useEffect(() => {
    if (responseMessage && status === UrlInputStatus.SUCCESS) {
      navigation.navigate(RouteNames.Chat);
      dispatch(setResponseMessage("-"));
    }
    return () => {
      dispatch(setClearStateOnUnLoad());
    };
  }, [responseMessage]);

  return (
    <View style={styles.container}>
      <MobileViewHOC>
        <View>
          <TextInput
            ref={(ref) => ref?.focus()}
            style={styles.input}
            value={newValue}
            placeholder={`Url for Training`}
            placeholderTextColor={colors.placeholder}
            onChangeText={setNewValue}
            secureTextEntry={key === "password"}
          />

          {subtitle && (
            <MayaText style={{ paddingHorizontal: 30, fontSize: 16 }}>
              {subtitle}
            </MayaText>
          )}

          {responseMessage && (
            <MayaText
              style={{
                fontSize: 18,
                fontWeight: "700",
                textAlign: "center",
                marginTop: 30,
              }}
            >
              {responseMessage}
            </MayaText>
          )}
        </View>

        <View style={styles.buttonWrapper}>
          <ButtonWide
            text={buttonText || "Save"}
            onPress={() => handlePost(newValue)}
            loading={loadingUrl}
            disabled={loadingUrl || !newValue}
            style={{ padding: 10 }}
          />
        </View>
      </MobileViewHOC>
    </View>
  );
};

export default UrlInputScreen;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.background,
    justifyContent: "space-between",
    padding: 10,
  },
  input: {
    backgroundColor: colors.background2,
    borderRadius: 30,
    padding: 10,
    paddingHorizontal: 20,
    margin: 20,
    borderColor: colors.borderColor,
    borderWidth: 1,
    color: "#fff",
    fontSize: 18,
  },
  buttonWrapper: {
    padding: 10,
    borderTopColor: colors.borderColor,
    borderTopWidth: 1,
  },
});
