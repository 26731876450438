import { createSlice } from "@reduxjs/toolkit";
import { postUrlAsyncThunk } from "../api/urlInput/urlInput";

export enum UrlInputStatus {
  SUCCESS = "SUCCESS",
  ERROR = "ERROR",
}

export type UrlInputState = {
  urlValue: string | undefined;
  loadingUrl: boolean;
  error: string | undefined;
  responseMessage: string | undefined;
  status: UrlInputStatus | undefined;
};

const initialState: UrlInputState = {
  urlValue: undefined,
  loadingUrl: false,
  error: undefined,
  responseMessage: "-",
  status: undefined,
};

const urlInputSlice = createSlice({
  name: "urlInput",
  initialState,
  reducers: {
    setResponseMessage: (state, action) => {
      state.responseMessage = action.payload;
    },
    setClearStateOnUnLoad: (state) => {
      state.responseMessage = "-";
      state.status = undefined;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(postUrlAsyncThunk.pending, (state, action) => {
      console.log("action", action);
      state.loadingUrl = true;
      state.error = undefined;
      state.responseMessage = "-";
      state.status = undefined;
    });
    builder.addCase(postUrlAsyncThunk.fulfilled, (state, action) => {
      console.log("action", action);
      state.loadingUrl = false;
      state.urlValue = action.payload;
      state.responseMessage = "Success!!!";
      state.status = UrlInputStatus.SUCCESS;
    });
    builder.addCase(postUrlAsyncThunk.rejected, (state, action) => {
      state.status = UrlInputStatus.ERROR;
      state.loadingUrl = false;
      state.responseMessage = "Something went wrong";
    });
  },
});
export const { setResponseMessage, setClearStateOnUnLoad } =
  urlInputSlice.actions;
export default urlInputSlice.reducer;
