// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import React, { Dispatch, useState } from "react";
import { Image, Pressable, StyleSheet, TextInput, View } from "react-native";

// Components
import MayaText from "../typography/MayaText";
import Modal from "react-native-modal";
import { Picker } from "@react-native-picker/picker";
import { types } from "../../helpers/constants";
import ButtonWide from "../general/ButtonWide";
import { colors } from "../../helpers/colors";

type Props = {
  selectedTheme: string;
  closeModal: () => void;
  handlePostWriteSomething: () => void;
  setselectedValue: Dispatch<React.SetStateAction<null>>;
  selectedValue: string | null;
  textValue: string;
  setTextValue: Dispatch<React.SetStateAction<string>>;
};

const WriteSomethingModal = ({
  selectedTheme,
  selectedValue,
  setselectedValue,
  closeModal,
  handlePostWriteSomething,
  textValue,
  setTextValue,
}: Props) => {
  const [pickerFocused, setPickerFocused] = useState(false);

  return (
    <Modal
      isVisible={selectedTheme}
      onBackButtonPress={closeModal}
      onBackdropPress={closeModal}
      useNativeDriverForBackdrop
      animationIn={"slideInUp"}
      animationOut={"slideOutDown"}
      backdropOpacity={0.6}
      backdropColor={"#000"}
      style={{}}
    >
      <View style={styles.modalContainer}>
        <View style={styles.modalHeader}>
          <View />

          <View style={styles.imageContainer}>
            <Image
              source={require("../../assets/insight.png")}
              resizeMode="center"
              style={{ width: 41, height: 41, marginRight: 10 }}
            />

            <MayaText style={{ fontSize: 20 }}>{selectedTheme}</MayaText>
          </View>

          <Pressable onPress={closeModal}>
            <MayaText style={{ fontWeight: "700", fontSize: 20 }}>X</MayaText>
          </Pressable>
        </View>

        <View style={{ marginBottom: 30 }}>
          <View style={{ marginBottom: 40 }}>
            <Picker
              mode="dropdown"
              onFocus={() => setPickerFocused(true)}
              onBlur={() => setPickerFocused(false)}
              selectedValue={selectedValue}
              onValueChange={(itemValue) => {
                setselectedValue(itemValue);
              }}
              dropdownIconColor={"#FFFFFF"}
              style={{
                backgroundColor: colors.background2,
                borderRadius: 10,
              }}
            >
              <Picker.Item
                label={"Think like a..."}
                value={"Think like a..."}
                color={"#FFFFFF"}
                style={{ backgroundColor: colors.background }}
              />
              {types.map((item, index) => {
                return (
                  <Picker.Item
                    key={index}
                    value={item}
                    label={item}
                    color={"#FFFFFF"}
                    style={{ backgroundColor: colors.background }}
                  />
                );
              })}
            </Picker>

            <MayaText style={{ marginTop: 10, fontSize: 12 }}>
              My state of mind as I work : )
            </MayaText>
          </View>

          <View style={{}}>
            <TextInput
              style={{
                backgroundColor: colors.background2,
                borderRadius: 10,
                padding: 10,
                height: 100,
                textAlignVertical: "top",
                color: "#FFFFFF",
                fontSize: 18,
              }}
              placeholder="A report about..."
              placeholderTextColor="#FFFFFF"
              multiline
              value={textValue}
              onChangeText={setTextValue}
            />

            <MayaText style={{ marginTop: 10, fontSize: 12 }}>
              The more direction, the better!
            </MayaText>
          </View>
        </View>

        <ButtonWide text={"Start writing"} onPress={handlePostWriteSomething} />
      </View>
    </Modal>
  );
};

export default WriteSomethingModal;

const styles = StyleSheet.create({
  modalContainer: {
    backgroundColor: "#000000",
    flex: 0.7,
    paddingHorizontal: 40,
    paddingVertical: 10,
    borderRadius: 30,
    shadowColor: "#FFFFFF40",
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 0.25, // 40 in hexadecimal translates roughly to 0.25 in decimal (opacity is 0 to 1 in React Native)
    shadowRadius: 4,
  },
  modalHeader: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 20,
  },
  imageContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
});
