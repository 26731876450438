import React from "react";
import { StyleSheet, View } from "react-native";

// Constants

// Components
import MenuItem from "../general/menuItem";

type Props = {
  closeModal: () => void;
};

const WorksMenuContent = ({ closeModal }: Props) => {
  return (
    <View style={styles.container}>
      <MenuItem
        label="Move to"
        iconPath={require("../../assets/moveTo.png")}
        // onPress={() => handleNavigation(RouteNames.Chat)}
      />
      <MenuItem
        label="Delete work"
        iconPath={require("../../assets/delete.png")}
        // onPress={() => handleNavigation(RouteNames.Chat)}
      />
    </View>
  );
};

export default WorksMenuContent;

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
});
