import React from "react";
import { StyleSheet, Text, TextStyle, View } from "react-native";

type Props = {
  style?: TextStyle | TextStyle[];
  children?: any;
  numberOfLines?: number;
};

const MayaText = ({ style, children, numberOfLines }: Props) => {
  return (
    <Text style={[styles.textStyle, style]} numberOfLines={numberOfLines}>
      {children ? children : "-"}
    </Text>
  );
};

export default MayaText;

const styles = StyleSheet.create({
  textStyle: {
    fontFamily: "Montserrat",
    color: "white",
  },
});
