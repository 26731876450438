import { createSlice ,PayloadAction} from "@reduxjs/toolkit";

export type RecognitionState = {
  loading: boolean;
  transcript: string;
  activeTranscript: boolean;
  recognitionEnd: boolean;
  microphoneIsOpen: boolean;
};

const initialState: RecognitionState = {
  loading: false,
  transcript: "",
  activeTranscript: false,
  recognitionEnd: false,
  microphoneIsOpen: false,
};

const recognitionSlice = createSlice({
  name: "recognition_slice",
  initialState,
  reducers: {
    startRecognition: (state) => {
      state.loading = true;
      state.transcript = "";
      state.activeTranscript = true;
      // state.recognitionEnd = false;
    },
    stopRecognition: (state) => {
      state.loading = false;
      state.activeTranscript = false;
    },
    setTranscript: (state, action) => {
      state.transcript = action.payload;
    },
    setRecognitionEnd: (state,action: PayloadAction<boolean>) => {
       state.recognitionEnd = true;
       state.activeTranscript = !action.payload;     },
    setMicrophoneIsOpen: (state, action) => {
      state.microphoneIsOpen = action.payload;
    },
  },
});

export const {
  startRecognition,
  stopRecognition,
  setTranscript,
  setRecognitionEnd,
  setMicrophoneIsOpen,
} = recognitionSlice.actions;

export default recognitionSlice.reducer;
