import React, { useEffect, useState } from "react";
import {
  Animated,
  Dimensions,
  ScrollView,
  StyleSheet,
  TouchableOpacity,
  View,
} from "react-native";
import { createMaterialTopTabNavigator } from "@react-navigation/material-top-tabs";
import { setMenuModalVisible } from "../reducers/generalSlice";
import { useDispatch } from "react-redux";
import { useNavigation } from "@react-navigation/native";

// Constants
import { colors } from "../helpers/colors";
import { RouteNames } from "./routeNames";

// Components
import WorklistHOC from "../containers/WorklistHOC";
import WorksFolderHOC from "../containers/WorksFoldersHOC";
import FolderMenuContent from "../components/works/folders/folderMenuContent";
import WorksMenuContent from "../components/works/worksMenuContent";
import CustomTabBar from "../components/works/CustomTabBar";
import TraningListHOC from "../containers/TrainingListHOC";
import WorkTrainingsHOC from "../containers/WorkTrainingsHOC";

type Props = {};

const Tab = createMaterialTopTabNavigator();

const tabStyle: any = {
  tabBarStyle: {
    backgroundColor: colors.background,
    borderBottomColor: colors.borderColor,
    borderBottomWidth: 1,
  },
  tabBarIndicatorStyle: {
    backgroundColor: colors.anakiwa,
    height: 3,
  },
  tabBarLabelStyle: {
    color: "#fff",
    fontWeight: "700",
  },
};

const WorksNavigator: React.FC = ({}: Props) => {
  const [selectedLabel, setSelectedLabel] = useState("Works");
  const navigation = useNavigation();
  const dispatch = useDispatch();

  const headerContentDictionary = {
    Folders: (
      <FolderMenuContent
        closeModal={() => dispatch(setMenuModalVisible(false))}
      />
    ),
    Works: (
      <WorksMenuContent
        closeModal={() => dispatch(setMenuModalVisible(false))}
      />
    ),
  };

  useEffect(() => {
    if (navigation) {
      navigation.setOptions({
        headerRight: () => {
          //@ts-ignore
          return <>{headerContentDictionary[selectedLabel]}</>;
        },
      });
    }
  }, [selectedLabel, navigation]);

  return (
    <Tab.Navigator
      tabBar={(props) => (
        <CustomTabBar {...props} _onPress={setSelectedLabel} />
      )}
      style={styles.container}
      initialRouteName={RouteNames.WorklistHOC}
      initialLayout={{ width: Dimensions.get("window").width }}
    >
      <Tab.Screen
        name={RouteNames.WorklistHOC}
        options={{ tabBarLabel: "Works", ...tabStyle }}
        component={WorklistHOC}
      />

      <Tab.Screen
        name={RouteNames.Folders}
        options={tabStyle}
        component={WorksFolderHOC}
      />

      <Tab.Screen
        name={RouteNames.Chats}
        options={tabStyle}
        component={WorklistHOC}
      />

      <Tab.Screen
        name={RouteNames.Training}
        options={tabStyle}
        component={WorkTrainingsHOC}
      />
    </Tab.Navigator>
  );
};

export default WorksNavigator;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.background,
  },
});
