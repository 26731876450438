import React from "react";
import { StyleSheet, View } from "react-native";

// Components
import Modal from "react-native-modal";

// Constants
import { colors } from "../../helpers/colors";

type Props = {
  isVisible: boolean;
  closeModal: () => void;
  children?: React.ReactNode;
};

const MenuModal = ({ isVisible, closeModal, children }: Props) => {
  return (
    <Modal
      isVisible={isVisible}
      onBackButtonPress={closeModal}
      onBackdropPress={closeModal}
      useNativeDriverForBackdrop
      animationIn={"slideInUp"}
      animationOut={"slideOutDown"}
      backdropOpacity={0.5}
      backdropColor={"#000"}
      style={{
        margin: 0,
        justifyContent: "flex-end",
      }}
    >
      <View style={styles.modalContainer}>
        <View style={styles.menuWrapper} />
        {children}
      </View>
    </Modal>
  );
};

export default MenuModal;

const styles = StyleSheet.create({
  modalContainer: {
    backgroundColor: "#000",
    paddingVertical: 10,
    borderTopRightRadius: 30,
    borderTopLeftRadius: 30,
    flexDirection: "column",
    justifyContent: "flex-end",
  },
  menuWrapper: {
    width: 70,
    height: 4,
    backgroundColor: colors.anakiwa,
    alignSelf: "center",
    borderRadius: 6,
    marginBottom: 20,
  },
  menuItemWrapper: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 20,
  },
});
