export default class PubSub<
  T extends Record<string, (...args: any[]) => void>,
> {
  private eventMap: Record<keyof T, Set<(...args: any[]) => void>> =
    {} as Record<keyof T, Set<(...args: any[]) => void>>;

  emit<K extends keyof T>(event: K, ...args: Parameters<T[K]>) {
    (this.eventMap[event] ?? []).forEach((cb) => cb(...args));
  }

  on<K extends keyof T>(event: K, callback: T[K]) {
    if (!this.eventMap[event]) {
      this.eventMap[event] = new Set();
    }

    this.eventMap[event].add(callback);
  }

  off<K extends keyof T>(event: K, callback: T[K]) {
    if (!this.eventMap[event]) {
      return;
    }

    this.eventMap[event].delete(callback);
  }
}

export enum PubSubEvents {
  USER_SELECT_WORKFLOW = "USER_SELECT_WORKFLOW",
  USER_GET_PROMPT = "USER_GET_PROMPT",
}

// Define the type for the events
interface ChatEventPubSub {
  [PubSubEvents.USER_SELECT_WORKFLOW]: (workflowId: string) => void;
  [PubSubEvents.USER_GET_PROMPT]: (prompt: string) => void;
}
// Create a new PubSub instance
export const pubSubChat = new PubSub<any>();
