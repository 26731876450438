import React, {useState} from 'react';
import {StyleSheet, View, Image} from 'react-native';
import {useNavigation} from '@react-navigation/native';

// Constants
import {colors} from '../helpers/colors';
import {RouteNames} from '../navigation/routeNames';

// Components
import MayaText from '../components/typography/MayaText';
import LoginLayout from '../components/login/LoginLayout';
import MayaTitle from '../components/typography/MayaTitle';
import CustomTextInput from '../components/general/CustomTextInput';
import ButtonWide from '../components/general/ButtonWide';
import FooterText from '../components/typography/FooterText';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';

type Props = {};

const ForgotPasswordHOC: React.FC = ({}: Props) => {
  const navigation = useNavigation<NativeStackNavigationProp<any>>();
  const [email, setEmail] = useState('');

  const navigateToLogin = () => {
    navigation.navigate(RouteNames.Login);
  };

  const handleAPICall = () => {
    // Perform login logic here using the email and password state
    console.log('Logging in:', email);
  };

  return (
    <View style={styles.container}>
      <LoginLayout
        paddingHorizontal={20}
        footerComponent={
          <FooterText
            textWhite="Already have a Maya?"
            textBlue="Power Up"
            onPress={navigateToLogin}
          />
        }>
        <Image
          source={require('../assets/maya.png')}
          style={styles.image}
          resizeMode="center"
        />

        <MayaTitle style={styles.welcome}>Forgot Password</MayaTitle>

        <MayaText style={styles.subtitle}>
          This is a small explanatory text that contains some information about
          the app.
        </MayaText>

<View style={{alignSelf:'center',width:'20%'}}>
<CustomTextInput
          value={email}
          setValue={setEmail}
          placeholder="Email"
        />
</View>
        <ButtonWide
          text="Send"
          
          onPress={() => navigation.navigate(RouteNames.ResetPassword)}
       style={styles.sendBtn}
       
       />

      </LoginLayout>
    </View>
  );
};

export default ForgotPasswordHOC;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.background,
  },
  image: {
    alignSelf: 'center',
    width: 124,
    height: 137,
    marginTop:80
  },
  welcome: {
    marginBottom: 40,
    marginTop: 10,
  },
  subtitle: {
    textAlign: 'center',
    marginBottom: 40,
  },
  sendBtn:{
    alignSelf:'center',
    width:'14%',
    marginTop:50,

  }
});
