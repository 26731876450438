import { useNavigation } from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import React from "react";
import { Image, Platform, Pressable, StyleSheet, Text, View } from "react-native";
import { RouteNames } from "../../navigation/routeNames";
import { colors } from "../../helpers/colors";

export type TextSpeechActionsProps = {
  speechModal: (sendMessage: boolean) => void;
  stylesIconsHeight?: any;
  stylesIconsWidth?: any;
};

const TextSpeechActions = ({ speechModal }: TextSpeechActionsProps) => {
  const navigation = useNavigation<NativeStackNavigationProp<any>>();
  const handlerTranscriptEnd = () => {
    speechModal(false);
  };
  const handleUploadFile = () => {
    speechModal(false);
    navigation.navigate(RouteNames.TrainingList);
  };

  return (
    <View style={styles.textAndIconContainer}>
      <Pressable onPress={handlerTranscriptEnd}>
        <View style={styles.textIconContainer}>
          <Text style={styles.textIcon}>T</Text>
        </View>
      </Pressable>
      <Pressable onPress={handleUploadFile}>
        <View style={styles.textIconContainer}>
          <Image
            style={{ width: 30, height: 30 }}
            source={require("../../assets/paperclip.png")}
          ></Image>
        </View>
      </Pressable>
    </View>
  );
};

const styles = StyleSheet.create({
  textAndIconContainer: {
    flexDirection: "row",
    justifyContent: "center",
    alignContent: "center",
    backgroundColor: colors.background,
    bottom:Platform.OS=='web'?30: 10
  },
  textIconContainer: {
    borderRadius: 50,
    borderColor: "#A0BEFF",
    borderWidth: 1,
    width: 40,
    height: 40,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    margin: 5,
  },
  textIcon: {
    fontSize: 25,
    fontWeight: "700",
    color: "#A0BEFF",
  },
});

export default TextSpeechActions;
