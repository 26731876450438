import { IExecutable } from "./executable.interface";

export class APICallExe extends IExecutable {
  response_message: string;

  constructor(response_message: string) {
    super();
    this.response_message = response_message;
    // console.log('api-exec service: response_message', this.response_message);

  }

  execute() {
    console.log(`Calling API ${this.response_message}`);
    return "EXAMPLE API RESULT: 15";
  }
}
