import React, { memo } from "react";
import { useEffect, useState } from "react";
import { Text } from "react-native";

const TypeText = ({ children, delay = 23 }) => {
  const [revealedLetters, setRevealedLetters] = useState(0);
  const interval = setInterval(() => {
    setRevealedLetters((prev) => prev + 1);
  }, delay);
  useEffect(() => {
    if (revealedLetters === children.length) {
      clearInterval(interval);
    }
  }, [children, interval, revealedLetters]);

  useEffect(() => {
    return () => {
      clearInterval(interval);
    };
  }, [interval]);
  if (!children) {
    return <></>;
  }
  return <>{children?.substring(0, revealedLetters)}</>;
};

export default memo(TypeText);
