import React from "react";
import { Pressable, StyleSheet, Text, View } from "react-native";
import ActiveSpeechVoice from "../Animations/SpeechSvg";
import TextSpeechActions from "../TextSpeechActions/TextSpeechActions";

import { colors } from "../../helpers/colors";
import TypeText from "../TypeText/TypeText";

declare const window: any;

export type SpeechSectionOnVoiceProps = {
  sentMessage: () => void;
  message: string;
  volumeChange: boolean;
  pressForClose?: boolean;
  recognitionEnd:boolean
};
const SpeechSectionOnVoice = ({
  volumeChange,
  recognitionEnd,
  sentMessage,
  message,
  pressForClose,
}: SpeechSectionOnVoiceProps) => {
  const actionToSendMessage = () => {
    sentMessage();
  };

  return (
    <View style={styles.container}>
      <Pressable
        style={styles.backdrop}
        onPress={actionToSendMessage}
      ></Pressable>
      <Pressable onPress={actionToSendMessage}>
        <View style={styles.transcriptContainer}>
          <Text style={styles.speechText}>
            <TypeText>{message ? message : ""}</TypeText>
          </Text>
        </View>
      </Pressable>

      <TextSpeechActions speechModal={actionToSendMessage} />
      <View style={styles.animationContainer}>
        <ActiveSpeechVoice volumeChange={volumeChange}
                       recognitionEnd={recognitionEnd}
                       recognition={recognitionEnd}
/>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    justifyContent: "flex-end",
    flexDirection: "column",
    width: "100%",
    height: "100%",
    position: "absolute",
    bottom: 0,
    padding: 10,
  },
  backdrop: {
    backgroundColor: "transparent",
    height: "100%",
  },
  transcriptContainer: {
    backgroundColor: colors.background,
    padding: 10,
  },
  speechText: {
    color: "white",
  },
  animationContainer: {
    width: "100%",
    alignItems: "center",
    height: 60,
    flexDirection: "row",
    justifyContent: "center",
  },
});

export default SpeechSectionOnVoice;
