import AsyncStorage from "@react-native-async-storage/async-storage";

const uploadFileRegex =
  /(?:upload|attach|send|submit)(?:ing|ed)?\s+(?:a|the)?\s+file/i;
const regexPatternFile = /\b(I want to|read|analyze|upload|file)\b/i;
const regexPatternUlr = /\b(I want to|read|analyze|upload|file|URL|link)\b/i;

const ipv4Regex =
  /^(25[0-5]|2[0-4][0-9]|[0-1]?[0-9]{1,2})(\.(25[0-5]|2[0-4][0-9]|[0-1]?[0-9]{1,2})){3}$/;

export const isMessageContainsUrl = (message) => {
  if (!message) return false;
  return regexPatternUlr.test(message);
};

export const isMessageContainsUpload = (message: string) => {
  if (!message) return false;
  return regexPatternFile.test(message);
};

export const getStorageData = async (key: string) => {
  try {
    const value = await AsyncStorage.getItem(key);
    if (value !== null) {
      return value;
    }
    return "";
  } catch (e) {
    // error reading value
    return "";
  }
};

export function validateIPaddress(ipaddress: string) {
  if (ipv4Regex.test(ipaddress)) {
    return true;
  }
  alert("You have entered an invalid IP address!");
  return false;
}

export const checkIsNumberRegex = (value: string) => {
  const regex = /^[0-9]*$/;
  return regex.test(value);
};

export const checkIsTextHasRegex = (value: string) => {
  const regex = /^[a-zA-Z.]*$/;
  return regex.test(value);
};

export const checkIsHasDotRegex = (value: string) => {
  const regex = /\./g;
  return regex.test(value);
};

export const checkIfUserAddTowNumberAndDot = (value: string) => {
  const regex = /.{2}\.$/;
  return regex.test(value);
};

export const userSaidHeyMaya = (message: string) => {
  const regex = /hey maya/g;
  if (!message) {
    return false;
  }
  return regex.test(message.toLowerCase());
};
