import { io } from "socket.io-client";

// "undefined" means the URL will be computed from the `window.location` object
const URL = "https://platform.mayadashboard.com/chat";

export type SocketProps = {
  query: {
    user_id: string | undefined;
    token: string | undefined;
  };
  baseChatUrl: string;
};

export const socket = ({ query, baseChatUrl = URL }: SocketProps) => {
  console.info("socket", query, baseChatUrl);
  return io(baseChatUrl, {
    query: { user_id: query.user_id, token: query.token },
    reconnection: true,
    reconnectionAttempts: 2,
    reconnectionDelay: 3000,
    reconnectionDelayMax: 5000,
  });
};
