import * as React from "react";
import Svg, { Path } from "react-native-svg";

const SvgComponent = (props) => (
  <Svg
    width="16"
    height="23"
    viewBox="0 0 16 23"
    {...props}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <Path
      d="M14 2L3 11.5L14 21"
      stroke="#A0BEFF"
      strokeWidth="3"
      strokeLinecap="round"
    />
  </Svg>
);

export default SvgComponent;
