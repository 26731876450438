import React from "react";
import { StyleSheet, View } from "react-native";

// Constants

// Components
import MenuItem from "../../general/menuItem";

type Props = {
  closeModal: () => void;
};

const FolderMenuContent = ({ closeModal }: Props) => {
  return (
    <View style={styles.container}>
      <MenuItem
        label="Create folder"
        iconPath={require("../../../assets/addFolder.png")}
        // onPress={() => handleNavigation(RouteNames.Chat)}
      />
      <MenuItem
        label="Rename folder"
        iconPath={require("../../../assets/writeSomething.png")}
        // onPress={() => handleNavigation(RouteNames.Chat)}
      />
      <MenuItem
        label="Move to"
        iconPath={require("../../../assets/moveTo.png")}
        // onPress={() => handleNavigation(RouteNames.Chat)}
      />
      <MenuItem
        label="Font size"
        iconPath={require("../../../assets/download.png")}
        // onPress={() => handleNavigation(RouteNames.Chat)}
      />
      <MenuItem
        label="Delete"
        iconPath={require("../../../assets/delete.png")}
        // onPress={() => handleNavigation(RouteNames.Chat)}
      />
    </View>
  );
};

export default FolderMenuContent;

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
});
