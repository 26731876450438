import React from "react";
import { Image, Pressable, StyleSheet } from "react-native";

// Components
import MayaText from "../typography/MayaText";

// Constants

type Props = {
  title: string;
  index: number;
  onPress: () => void;
  image: any;
};

const ListItem = ({ title, index, onPress, image }: Props) => {
  return (
    <Pressable style={[styles.container]} onPress={onPress}>
      <Image
        source={image}
        resizeMode="contain"
        style={{ width: 41, height: 41 }}
      />

      <MayaText style={{ color: "white", fontSize: 16 }}>{title}</MayaText>
    </Pressable>
  );
};

export default ListItem;

const styles = StyleSheet.create({
  container: {
    backgroundColor: "#FFFFFF26",
    flex: 1,
    padding: 20,
    borderRadius: 30,
    height: 142,
    justifyContent: "space-between",
    marginHorizontal: 10,
  },
});
