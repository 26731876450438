import { createSlice } from "@reduxjs/toolkit";
import Toast from "react-native-toast-message";
import { axiosBase } from "../api/agent";
import { URLS } from "../helpers/constants";

export type GeneralState = {
  menuModalVisible: boolean;
  selectedForUpload: string[];
  multiSelectInspections: boolean;
  baseUrl: string;
  loadingModal: boolean;
  baseChatUrl: string;
  defaultBaseUrl: string;
  defaultBaseChatUrl: string;
};

const initialState: GeneralState = {
  menuModalVisible: false,
  selectedForUpload: [],
  multiSelectInspections: false,
  defaultBaseUrl: "https://platform.mayadashboard.com",
  defaultBaseChatUrl: "https://platform.mayadashboard.com/chat",
  baseUrl: "https://platform.mayadashboard.com",
  baseChatUrl: "https://platform.mayadashboard.com/chat",
  loadingModal: false,
};

const worksSlice = createSlice({
  name: "general",
  initialState,
  reducers: {
    setMenuModalVisible: (state, { payload }) => {
      state.menuModalVisible = payload;
    },
    setMultiSelectInspections(state, action) {
      state.multiSelectInspections = action.payload;
    },
    setSelectedForUpload(state, action) {
      state.selectedForUpload = action.payload;
    },
    setLoadingModal(state, action) {
      state.loadingModal = action.payload;
    },
    setDocumentUpload(state, action) {
      state.baseUrl = action.payload;
      axiosBase.defaults.baseURL = action.payload;
      Toast.show({
        type: "success",
        text1: `Update Base Url for Document Upload with: ${action.payload}`,
      });
    },
    setChatUpload(state, action) {
      state.baseChatUrl = action.payload;
      Toast.show({
        type: "success",
        text1: `Update Base Url for Chat with: ${action.payload}`,
      });
    },
    resetDefaultValues(state) {
      state.baseUrl = state.defaultBaseUrl;
      state.baseChatUrl = state.defaultBaseChatUrl;
      axiosBase.defaults.baseURL = URLS.MAIN_TRAINING;
      Toast.show({
        type: "success",
        text1: `Clear values to default`,
      });
    },
  },
});

export const {
  setMenuModalVisible,
  setMultiSelectInspections,
  setSelectedForUpload,
  setLoadingModal,
  setChatUpload,
  setDocumentUpload,
  resetDefaultValues,
} = worksSlice.actions;

export default worksSlice.reducer;
