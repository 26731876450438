import React, { useEffect, useRef } from "react";
import { Animated, Easing, StyleSheet, View } from "react-native";
import { colors } from "../../helpers/colors";

const DotChat = () => {
  const scaleAnimateDotOne = useRef(new Animated.Value(0)).current;
  const scaleInterpolate = scaleAnimateDotOne.interpolate({
    inputRange: [1, 2],
    outputRange: [2, 1],
  });
  const scaleAnimateDotTwo = useRef(new Animated.Value(0)).current;
  const scaleInterpolateTwo = scaleAnimateDotTwo.interpolate({
    inputRange: [1, 2],
    outputRange: [2, 1],
  });
  const scaleAnimateDotThree = useRef(new Animated.Value(0)).current;
  const scaleInterpolateThree = scaleAnimateDotThree.interpolate({
    inputRange: [1, 2],
    outputRange: [2, 1],
  });
  const animateElement = () => {
    Animated.loop(
      Animated.sequence([
        Animated.timing(scaleAnimateDotOne, {
          toValue: 1,
          duration: 400,
          useNativeDriver: true,
          easing: Easing.ease,
        }),
        Animated.timing(scaleAnimateDotOne, {
          toValue: 0,
          duration: 400,
          useNativeDriver: true,
          easing: Easing.ease,
        }),
        Animated.delay(50),
        Animated.timing(scaleAnimateDotTwo, {
          toValue: 1,
          duration: 400,
          useNativeDriver: true,
          easing: Easing.ease,
        }),
        Animated.timing(scaleAnimateDotTwo, {
          toValue: 0,
          duration: 400,
          useNativeDriver: true,
          easing: Easing.ease,
        }),
        Animated.delay(50),
        Animated.timing(scaleAnimateDotThree, {
          toValue: 1,
          duration: 400,
          useNativeDriver: true,
          easing: Easing.ease,
        }),
        Animated.timing(scaleAnimateDotThree, {
          toValue: 0,
          duration: 400,
          useNativeDriver: true,
          easing: Easing.ease,
        }),
      ]),
    ).start();
  };
  const animationStyleOne = {
    transform: [
      {
        scale: scaleInterpolate,
      },
    ],
  };
  const animationStyleTwo = {
    transform: [
      {
        scale: scaleInterpolateTwo,
      },
    ],
  };
  const animationStyleThree = {
    transform: [
      {
        scale: scaleInterpolateThree,
      },
    ],
  };

  useEffect(() => {
    animateElement();
  }, []);
  return (
    <View style={styles.container}>
      <Animated.View style={[styles.dot, animationStyleOne]} />
      <Animated.View style={[styles.dot, animationStyleTwo]} />
      <Animated.View style={[styles.dot, animationStyleThree]} />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
    paddingVertical: 16,
    paddingHorizontal: 22,
    height: 50,
  },
  dot: {
    borderStyle: "solid",
    borderWidth: 0,
    borderRadius: 50,
    borderColor: colors.bubble,
    backgroundColor: colors.bubble,
    height: 6,
    width: 6,
    marginHorizontal: 9,
  },
});

export default DotChat;
