import React, { useEffect, useState, useMemo } from "react";
import {
  Image,
  Keyboard,
  Platform,
  Pressable,
  StyleSheet,
  Text,
  TextInput,
  View,
} from "react-native";
import { colors } from "../../helpers/colors";
import { useKeyboard } from "../../hooks/useKeyboard";
import AutoTemplateActionSheet from "../AutoTemplateActionSheet/AutoTemplateActionSheet";
import { useSelector } from "react-redux";
import { RootState } from "../../../types";
import { dispatch } from "../../store/store";
import { setPromptMessage } from "../../reducers/chatSlice";
import StopStepBackWorkFlow from "../StopStepBackActions/StopStepBackWorkFlow";
import { stopProcessWorkFlow } from "../../../src/services/chat.service";
import { useFocusEffect } from "@react-navigation/native";
import TextSpeechActions, { TextSpeechActionsProps } from "../TextSpeechActions/TextSpeechActions";
type CustomChatInputProps = {
  props: any;
  sendFn: (text: any) => void;
  attachFn: () => void;
  startRecord?: () => void;
  trainingOptions?: boolean;
  pressForClose?: boolean;
};

const CustomChatInput = ({
  attachFn,
  props,
  sendFn,
  startRecord,
  trainingOptions = false,
  pressForClose = false,
}: CustomChatInputProps) => {
  const [textInput, setTextInput] = useState("");
  const [activateSuggestion, setActivateSuggestion] = useState(false);
  const [workFlowView, setWorkFlowView] = useState(null);
  const [closeDag,setCloseDag]=useState(false);
  
  const { selectedByUserFlow, activateWorkFlow, workFlow } = useSelector(
    (state: RootState) => state.persist.chatReducer
  );

  const keyboardHeight = useKeyboard();

  useEffect(() => {
    if (selectedByUserFlow!=undefined) {
      setWorkFlowView(true)
      console.log("selected by user flow"+ activateWorkFlow)
      
    }else{
      console.log("selected by user flow"+ selectedByUserFlow)

      setWorkFlowView(false);
    }
    console.log("from chat input " + workFlow)
  }, [selectedByUserFlow]);

  const handleSend = () => {
    if (textInput) {
      sendFn(textInput);
      setTextInput("");
    }
  };


  const handleStop = () => {

    setCloseDag(false)
      stopProcessWorkFlow(dispatch, "stop");
      // setWorkFlowView(false);
      setTextInput(""); // Clear text input when stopping workflow

  };

  const containsHashtag = (text: any) => {
    // return hashtagRegex.test(text);
  };
  
  const handleTypeTextChange = (text: string) => {
     const hashtagRegex = "\#";

    if(text==hashtagRegex){
    setCloseDag(false)
    setActivateSuggestion(true);
    }else{
      setActivateSuggestion(false);

    }
    setTextInput(text);
  };

  const handlerTemplateOnText = (template: string) => {
    setActivateSuggestion(false);
    setTextInput("");
    dispatch(setPromptMessage(null));
  };

  useEffect(() => {
    if (trainingOptions) {
      setActivateSuggestion(true);
    } else {
      setActivateSuggestion(false);
    }
  }, [trainingOptions]);

  const closeButton = useMemo(() => (
    workFlowView && (
      <View style={[styles.btnContainer, { right: 4 }]}>
        <Pressable onPress={handleStop}>
          <Image
            style={{
              width: Platform.OS === "web" ? 34 : 24,
              height: Platform.OS === "web" ? 34 : 24,
            }}
            source={require("../../assets/x_close.png")}
          />
        </Pressable>
      </View>
    )
  ), [workFlowView]);

  return (
    <>
      {activateSuggestion && (

         <AutoTemplateActionSheet
          handleTemplate={handlerTemplateOnText}
          stylesProps={{ bottom: Platform.OS=='ios'?80:60,position: 'absolute',height:'auto',flex:1 }}
        />

      )}  


   {closeDag && !activateSuggestion && (
  <View style={{backgroundColor:colors.background, justifyContent: 'center', alignItems: 'center',bottom: Platform.OS=='ios'?25:53,top:Platform.OS=='web'&&100}}>
    <View style={{ paddingHorizontal:10,bottom:Platform.OS=='ios'?'30%':null}}>
      <Text style={{ color: colors.placeholder, textAlign: 'center', fontSize: 18,top:4 }}>
        Ok, shall I cancel your request to  {"\n"} "{workFlow.name}"?
      </Text>
    </View>
    <View style={{ flexDirection: 'row', alignItems: 'center',bottom:Platform.OS=='ios'?'5%':null }}>
      <Pressable
        onPress={() => {
          console.log("Yes pressed");
          handleStop();
        }}
        style={({ pressed }) => [styles.chipWrapper,
          {
            backgroundColor: pressed ? colors.borderColor : colors.mineshaft,
     
          },
        ]}
      >
        <Text style={{ color: 'white', textAlign: 'center' }}>Yes</Text>
      </Pressable>
      <Pressable
        onPress={() => {
          console.log("No pressed");
          setCloseDag(false);
        }}
        style={({ pressed }) => [styles.chipWrapper,
          {
            backgroundColor: pressed ? colors.borderColor : colors.mineshaft,
     
          },
        ]}
      >
        <Text style={{ color: 'white', textAlign: 'center' }}>No</Text>
      </Pressable>
    </View>
  </View>
)}


      <View
        {...props}
        style={[
          styles.container,
          { bottom: Platform.OS === "android" ? 0 : keyboardHeight },
        ]}
      >


        <View style={styles.actionContainer}>

          <Pressable onPress={attachFn}>
            <View style={styles.btnContainer}>
              <Image
                style={{
                  width: Platform.OS == "web" ? 34 : 24,
                  height: Platform.OS == "web" ? 34 : 24,
                }}
                source={require("../../assets/paperclip.png")}
              />
            </View>
          </Pressable>

          <Pressable onPress={startRecord}>
            <View style={styles.btnContainer}>
              <Image
                style={{
                  width: Platform.OS == "web" ? 34 : 24,
                  height: Platform.OS == "web" ? 34 : 24,
                }}
                source={require("../../assets/microphone.png")}
              />
            </View>
          </Pressable>

          <View style={styles.textInputWrapper}>
            <TextInput
              placeholder=" Tap here to start typing..."
              multiline={true}
              style={[
                styles.textInput,
                Platform.OS === "web" && { flex: 1 },
              ]}
              placeholderTextColor="#FFFFFF33"
              value={textInput}
              onChangeText={handleTypeTextChange}
              onSubmitEditing={handleSend}
              autoFocus={Platform.OS=='web'?false:true}
              onKeyPress={Keyboard.isVisible}
              onFocus={Keyboard.isVisible}
              
             
            />
            {workFlowView&&(
              <>
                <View style={[styles.btnContainer, { right: 4 }]}>
                  <Pressable onPress={()=>{
                    setCloseDag(true)}}>
                    <Image
                      style={{
                        width: Platform.OS === "web" ? 34 : 24,
                        height: Platform.OS === "web" ? 34 : 24,
                      }}
                      source={require("../../assets/x_close.png")}
                    />
                  </Pressable>
                </View>
              </>
            )}


          </View>

          <Pressable onPress={handleSend}>
            <View
              style={[
                styles.btnContainer,
                { opacity: textInput ? 1 : 0.5 },
              ]}
            >
              <Image
                style={{
                  width: Platform.OS == "web" ? 34 : 24,
                  height: Platform.OS == "web" ? 34 : 24,
                }}
                source={require("../../assets/paper-airplane.png")}
              />
            </View>
          </Pressable>
        </View>

      </View>
    </>);
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "column",
    justifyContent: "center",
    width: "100%",
    height: 30,
    backgroundColor: colors.background,
    position: "absolute",
    bottom: 20
  },
  actionContainer: {
    flexDirection: "row",
    alignItems: "center",
    alignContent: "center",
    paddingHorizontal: 5,
    justifyContent: Platform.OS === "web" ? "center" : "space-evenly",
    width: "100%",
    bottom: Platform.OS=='ios'?45:20,
  },
  btnContainer: {
    width: Platform.OS == "web" ? 44 : 34,
    height: Platform.OS == "web" ? 44 : 34,
    borderWidth: 0.7,
    borderColor: colors.anakiwa,
    borderRadius: 50,
    alignSelf: "center",
    justifyContent: "center",
    alignItems: "center",
    margin:Platform.OS!='web'? 2:8,
  },
  textInputWrapper: {
    flexDirection: "row",
    alignItems: "center",
    borderRadius: Platform.OS == "android" ? 6 : 20,
    borderWidth: Platform.OS === "android" ? 0.1 : 0.2,
    borderColor: "white",
    width: "65%",
    height: 60,
    paddingVertical: 0,
  },
  textInput: {
    padding: Platform.OS=='web'?18:10,
    marginTop:Platform.OS=='web'?'2%':null,
    left: 1,
    color: "white",
    fontSize: Platform.OS == "web" ? 16 : 14,
    flex: 1,
    //ACTIVATE THIS WHEN BUILDING FOR WEB
    
    outlineStyle:Platform.OS=='web'&& 'none',
  },
  chipWrapper: {
    
    borderRadius: 38,
    //borderColor: "rgba(255, 255, 255, 0.20)",
    //borderColor: 'white',
    borderWidth: 1,
    paddingVertical: 4,
    paddingHorizontal: 12,
    marginHorizontal: 30,
    //backgroundColor: colors.mineshaft,
    minWidth: 70,
    margin: 10,
    alignItems:'center'
    
  },
});

export default CustomChatInput;
