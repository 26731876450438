import { createSlice } from "@reduxjs/toolkit";
import {
  trainingMultiPdfAsyncThunk,
  trainingWithPdfAsyncThunk,
} from "../api/training/training";
import { TrainingEvent } from "../models/trainingEvent.model";

export type TrainingMessageResponse = {
  message: string;
  status: string;
};

export type TrainingState = {
  loading: boolean;
  result: TrainingMessageResponse;
  error: string;
  processingFiles: boolean;
};

const initialState: TrainingState = {
  loading: false,
  result: {
    message: "",
    status: "",
  },
  error: "",
  processingFiles: false,
};

const trainingSlice = createSlice({
  name: "training_slice",
  initialState,
  reducers: {
    clearState: (state) => {
      state.result = {
        message: "",
        status: "",
      };
    },
    setProcessingFilesEnd: (state) => {
      state.processingFiles = false;
    },
    setLoading: (state, { payload }) => {
      state.loading = payload;
    },
    setTrainingProcessing: (state, { payload }) => {
      state.processingFiles = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(trainingWithPdfAsyncThunk.pending, (state, action) => {
      state.loading = true;
      state.result = {
        message: "",
        status: "",
      };
      state.error = "";
    });
    builder.addCase(trainingWithPdfAsyncThunk.fulfilled, (state, action) => {
      state.loading = false;
      state.result = action.payload;
      state.error = "";
    });
    builder.addCase(trainingWithPdfAsyncThunk.rejected, (state, action) => {
      state.loading = false;
      state.result = {
        message: "",
        status: "",
      };
      state.error = action.error.message || "";
    });
    builder.addCase(trainingMultiPdfAsyncThunk.pending, (state, action) => {
      state.loading = true;
      state.result = {
        message: "Training the system",
        status: "",
      };
      state.error = "";
      state.processingFiles = true;
    });
    builder.addCase(trainingMultiPdfAsyncThunk.fulfilled, (state, action) => {
      state.loading = false;
      state.result = action.payload as any;
      state.error = "";
      state.processingFiles = true;
    });
    builder.addCase(trainingMultiPdfAsyncThunk.rejected, (state, action) => {
      state.loading = false;
      state.result = {
        message: action.error.message ?? "Error on training",
        status: "Reject",
      };
      state.processingFiles = false;
      state.error = action.error.message || "";
    });
  },
});

export const {
  clearState,
  setProcessingFilesEnd,
  setLoading,
  setTrainingProcessing,
} = trainingSlice.actions;
export default trainingSlice.reducer;
