import React from "react";
import { Platform, StyleSheet, View } from "react-native";
import { RouteNames } from "../../navigation/routeNames";
import { useNavigation } from "@react-navigation/native";
import { useDispatch } from "react-redux";

// Components

// Icons
import MenuItem from "./menuItem";

import { loginAsyncThunkLogout } from "../../api/auth/auth";
import { AppDispatch } from "../../store/store";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import VersionComponent from "../Version/Version";
import { setUserPressLogout } from "../../reducers/authSlice";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { STORAGE_KEYS } from "../../helpers/constants";

type Props = {
  closeModal?: () => void;
};

const MenuContent = ({ closeModal }: Props) => {
  const navigation = useNavigation<NativeStackNavigationProp<any>>();
  const dispatch = useDispatch<AppDispatch>();

  const handleNavigation = (routeName: string) => {
    navigation?.navigate(routeName);
    closeModal();
  };

  const handleQuit = async () => {
    try {
      // Remove token from AsyncStorage
      await AsyncStorage.removeItem(STORAGE_KEYS.TOKEN);
      
      // Log AsyncStorage values after removing the token
      const tokenAfterLogout = await AsyncStorage.getItem(STORAGE_KEYS.TOKEN);
      //console.log("Token after logout:", tokenAfterLogout);
  
      // Dispatch actions
      dispatch(loginAsyncThunkLogout());
      dispatch(setUserPressLogout(true));
      
      closeModal();
      navigation.navigate(RouteNames.Home)
    } catch (error) {
      console.error("Error logging out:", error);
      // Handle error if needed
    }
  };
  
  return (
    <View style={styles.container}>
      <View>
        <MenuItem
          label="User profile"
          iconPath={require("../../assets/profile.png")}
          onPress={() => handleNavigation(RouteNames.Profile)}
        />
      </View>
      <View style={{ marginTop: 10 }}>
        <MenuItem
          label="Settings"
          iconPath={require("../../assets/settings-menu.png")}
          onPress={() => handleNavigation(RouteNames.Settings)}
        />
      </View>
      <View>
        <MenuItem
          label="Logout"
          iconPath={require("../../assets/close.png")}
          onPress={handleQuit}
        />
      </View>
      <View style={{flex:1,top:Platform.OS!=='web'?'25%':100}}>
      <VersionComponent />

      </View>
    </View>
  );
};

export default MenuContent;

const styles = StyleSheet.create({
  container: {
    justifyContent: "center",
    flexDirection: "column",
    paddingVertical: 40,
  },
});
