export const themes = [
  "General Report",
  "Problem Solving",
  "Research Paper",
  "Detailed Plan",
  "Information Guides",
  "Creative Writing",
  "Contract Draft",
  "Predict Things",
  "Compare & Constrast",
  "Decision Making",
  "Match Things",
  "Invent Things",
];

// export const learnFrom = ["Live learning", "URL", "PDF", "DOC", "CSV", "Text"];
export const learnFrom = ["URL", "DOCUMENT", "CSV"];

export const themeType = {
  custom_training: {
    title: "Custom Training",
    icon: require("../assets/customTraning.png"),
  },
  research_paper: {
    title: "Research Paper",
    icon: require("../assets/notepad.png"),
  },
  solving_problems: {
    title: "Problem Solving",
    icon: require("../assets/insight.png"),
  },
  invent_things: {
    title: "Invent Things",
    icon: require("../assets/invent.png"),
  },
  general_report: {
    title: "General Report",
    icon: require("../assets/writeSomething.png"),
  },
  creative_writing: {
    title: "Creative Writing",
    icon: require("../assets/creativeWriting.png"),
  },
  contract_drafts: {
    title: "Contract Draft",
    icon: require("../assets/contractDraft.png"),
  },
  detailed_planning: {
    title: "Detailed Plan",
    icon: require("../assets/burger.png"),
  },
  information_guide: {
    title: "Information Guides",
    icon: require("../assets/crossroad.png"),
  },
  predicting_things: {
    title: "Predict Things",
    icon: require("../assets/predictThings.png"),
  },
  match_things: {
    title: "Match Things",
    icon: require("../assets/match.png"),
  },
  decision_making: {
    title: "Decision Making",
    icon: require("../assets/decision.png"),
  },
  compare_things: {
    title: "Compare & Constrast",
    icon: require("../assets/compare.png"),
  },
};

export const themeMapper = {
  "Research Paper": {
    title: "research_paper",
    icon: require("../assets/notepad.png"),
  },
  "Problem Solving": {
    title: "solving_problems",
    icon: require("../assets/insight.png"),
  },
  "Invent Things": {
    title: "invent_things",
    icon: require("../assets/invent.png"),
  },
  "General Report": {
    title: "general_report",
    icon: require("../assets/writeSomething.png"),
  },
  "Creative Writing": {
    title: "creative_writing",
    icon: require("../assets/creativeWriting.png"),
  },
  "Contract Draft": {
    title: "contract_drafts",
    icon: require("../assets/contractDraft.png"),
  },
  "Detailed Plan": {
    title: "detailed_planning",
    icon: require("../assets/burger.png"),
  },
  "Information Guides": {
    title: "information_guide",
    icon: require("../assets/crossroad.png"),
  },
  "Predict Things": {
    title: "predicting_things",
    icon: require("../assets/predictThings.png"),
  },
  "Match Things": {
    title: "match_things",
    icon: require("../assets/match.png"),
  },
  "Decision Making": {
    title: "decision_making",
    icon: require("../assets/decision.png"),
  },
  "Compare & Constrast": {
    title: "compare_things",
    icon: require("../assets/compare.png"),
  },
};

export const learnFromMapper = {
  "Live Learning": {
    title: "live_learning",
    icon: require("../assets/liveLearning.png"),
  },
  URL: {
    title: "url",
    icon: require("../assets/url.png"),
  },
  DOCUMENT: {
    title: "document",
    icon: require("../assets/doc.png"),
  },
  DOC: {
    title: "doc",
    icon: require("../assets/doc.png"),
  },
  CSV: {
    title: "csv",
    icon: require("../assets/csv.png"),
  },
  Text: {
    title: "text",
    icon: require("../assets/text.png"),
  },
};

export const types = [
  "Phamacist",
  "Scientist",
  "Brain Surgeon",
  "Neurosurgeon",
  "Physicist",
  "Oncologist",
  "Phycologist",
  "Physician",
  "Drug Researcher",
  "Neuroscientist",
  "Chemist",
  "Neurochemist",
  "Biologist",
  "Sick Patient",
  "Astrophysicist",
  "Astrobiologist",
  "An Executive",
  "Data Analyst",
  "Product Designer",
  "Compiance Officer",
];

export const URLS = {
  MAYA_DASHBOARD: "https://mayadashboard.com",
  TEST: "http://localhost:3000/",
  DUMMY: "https://www.dotsyntax.gr/maya/maya.html",
  MAIN_TRAINING: "https://platform.mayadashboard.com",
};

export const STORAGE_KEYS = {
  TOKEN: "token",
};

export const USER_ID_DEFAULT = "1950";

export enum PlatformOS {
  ANDROID = "android",
  IOS = "ios",
  WEB = "web",
}

export const UUID = "f8d4b6ff-9656-4fb9-b6fd-368500391bd5";

export const DATA_FOR_TRAINING = [
  { id: 1, name: "Maya's Opinion", flow: "opinion_conversation_flow", set: "opinion_submit_answers" },
  { id: 2, name: "Summarize our time together", flow: "summarize_time_conversation_flow", set: "summarize_time_submit_answers" },
  { id: 3, name: "Write me something custom", flow: "report_conversation_flow", set: "report_submit_answers" },
  { id: 4, name: "Analyze a Web URL", flow: "analyze_url_conversation_flow", set: "analyze_url_submit_answers" },
  { id: 5, name: "Analyze a YouTube Video", flow: "analyze_youtube_video_conversation_flow", set: "analyze_youtube_video_submit_answers" },
  { id: 6, name: "Write me a detailed report", flow: "detailed_report_conversation_flow", set: "detailed_report_submit_answers" },
  { id: 7, name: "Compare two things", flow: "compare_things_conversation_flow", set: "compare_things_submit_answers" },
];

export const DATA_ON_PROCESS_WORK_FLOW = [
  // { id: 1, name: "Step Back" },
  { id: 2, name: "Stop Process" },
];

export enum EProcessWorkFlow {
  STOP = 2,
  STEP_BACK = 1,
}
