import React, { useCallback, useEffect, useRef, useState } from "react";
import { Dimensions, Platform, StyleSheet, View } from "react-native";
import { GiftedChat } from "react-native-gifted-chat";
import { colors } from "../helpers/colors";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../types";
import CustomChatInput from "../components/customChatInput/CustomChatInput";
import { AppDispatch } from "../store/store";
import MenuContent from "../components/general/MenuContent";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import { useFocusEffect, useNavigation } from "@react-navigation/native";
import { RouteNames } from "../navigation/routeNames";
import { socket as webSocket } from "../services/socket";
import { ChatMessage } from "../models/message";
import { ETrainingStatus, TrainingEvent } from "../models/trainingEvent.model";
import { setNotification } from "../reducers/notificationSlice";
import ProcessingTraining from "../components/notificationChat/ProssesingTraining";
import {
  setProcessingFilesEnd,
  setTrainingProcessing,
} from "../reducers/trainingSlice";
import useNotification from "../hooks/useNotification";
import BubbleRender from "../components/Bubble/BubbleRender";
import AvatarChat from "../components/AvatarChat/AvatorChat";
import { sentryErrorService } from "../services/sentry-error.service";
import SpeechSectionOnVoice from "../components/SpeechSectionOnVoice/SpeechSectionOnVoice";
import useHayMayaRecognition from "../hooks/useHayMayaRecognition";
import { useKeyboard } from "../hooks/useKeyboard";
import { getTheDoingWorkWithOption } from "../services/doing-work";
import { Eedges_consumable, IDoingWork } from "../models/doingWotrk.model";
import {
  addLoadingMessageBubble,
  setAnswerToQuestion,
  setFlowByUser,
  setMessagesChat,
  setWorkOption,
  stopWorkFlow,
} from "../reducers/chatSlice";
import { workFlowSendAsyncThunk } from "../api/doing-work/doing-work";
import {
  appendMessage,
  formatMessageForChat,
  getSelectedNodeBasedOnStep,
  stopProcessWorkFlow,
} from "../services/chat.service";
import dayjs from "dayjs";
import { EChatHeightOnOpenActionBanner } from "../helpers/chat";
import { PubSubEvents, pubSubChat } from "../services/pub-sub.service";
import recognitionSlice from "../reducers/recognitionSlice";
import { platform } from "os";

type ChatHOCProps = {
  navigation: NativeStackNavigationProp<any>;
  route: any;
};

const ChatHOC: React.FC<ChatHOCProps> = ({ route }) => {
  const { wakeUp } = route?.params ?? false;
  const trainingEvent = useNotification();
  const {
    message,
    volumeChange,
    recognitionEnd,
    onResetVoiceMessage,
    speechPause,
    startRecognitionMobile,
    stopRecognitionMobile,
    startRecognitionWeb,
    stopRecognizingWeb,
    
  } = useHayMayaRecognition();
  const keyboardHeight = useKeyboard();
  const { processingFiles } = useSelector(
    (state: RootState) => state.root.trainingReducer
  );
  const [speechModal, setSpeechModal] = useState(false);
    const { selectedByUserFlow, activateWorkFlow, workFlow } = useSelector(
    (state: RootState) => state.persist.chatReducer
  );
  const navigation = useNavigation<NativeStackNavigationProp<any>>();
  const chatState = useSelector(
    (state: RootState) => state.persist.chatReducer
  );
  
  const { baseChatUrl } = useSelector(
    (state: RootState) => state.root.generalReducer
  );
  const dimentionState = useSelector(
    (state: RootState) => state.root.dimentionReducer
  );
  const { token, profile, userId } = useSelector(
    (state: RootState) => state.persist.authReducer
  );
  const dispatch = useDispatch<AppDispatch>();
  const [messages, setMessages] = useState([]);
  const textInputRef = useRef(null);
  const [reconnectionCounter, setReconnectionCounter] = useState(0);
  const [socket, setSocket] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const [joinTheRoom, setJoinTheRoom] = useState(false);
  const [minInputToolbarHeight, setMinInputToolbarHeight] = useState(100);
  const pickDocument = async () => {
    navigation.navigate(RouteNames.TrainingList);
  };

useEffect(()=>{
console.log("gueicoo"+wakeUp)
},[])
  const removeLoadingAppendMessage = (message: ChatMessage, userResponse?: string) => {
    dispatch(addLoadingMessageBubble());
    dispatch(setMessagesChat(message));

  };


  const mayaLoading = () => {
    dispatch(addLoadingMessageBubble());
  };

  const handlerUserSendWorkFlow = async (text: string) => {
    const selectedByTheUser = chatState.selectedByUserFlow;

    if (!selectedByTheUser) {
      return;
    }


    const selectOption = chatState.workOptions.edges_consumable.find(
      (edge) => edge.from_node === selectedByTheUser.to_node
    );

    const answerSelected = getSelectedNodeBasedOnStep(
      chatState.workOptions,
      selectedByTheUser.to_node
    );



    if (!answerSelected) {

      stopProcessWorkFlow(dispatch);
      return;
    }
    dispatch(
      setAnswerToQuestion({
        answer: text,
        selectedNodeToAnswer: answerSelected,
      })
    );

    const nextQuestion = chatState.workOptions.nodes_consumable.find(
      (node) => node.id === selectOption.to_node
    );

    // ** STOP PROCESS
    if (!nextQuestion) {
      stopProcessWorkFlow(dispatch);
      return;
    }

    // Dynamically update the last user answer in the response_message
    const updatedNodesConsumable = [...chatState.workOptions.nodes_consumable];
    const updatedEdgesConsumable = [...chatState.workOptions.edges_consumable];

    const updatedNodeIndex = updatedNodesConsumable.findIndex(
      (noded) => noded.id === answerSelected.id
    );

    updatedNodesConsumable[updatedNodeIndex] = {
      ...updatedNodesConsumable[updatedNodeIndex],
      answers: text,
    };


    dispatch(
      setWorkOption({
        ...chatState.workOptions,
        nodes_consumable: updatedNodesConsumable,
      })
    );

    let updatedAnswer = { 'edges_consumable': updatedEdgesConsumable, 'nodes_consumable': updatedNodesConsumable, id: chatState.workFlow.set }
    // console.log('updatedAnswer', updatedAnswer);

    const nextEdge = chatState.workOptions.edges_consumable.find(
      (edge) => nextQuestion.id === edge.to_node
    );

    dispatch(setFlowByUser(nextEdge));

    const formatMessage = formatMessageForChat({
      message: nextQuestion.response_message,
    });

    removeLoadingAppendMessage(formatMessage);

    if (nextQuestion.id === Eedges_consumable.END) {

      dispatch(stopWorkFlow());

      dispatch(workFlowSendAsyncThunk({ dagFile: updatedAnswer }));

      return;
    }

  };

  const setMessageForWorkFlow = (option: IDoingWork, text: string) => {
    const messages = formatMessageForChat({
      message: text,
      userId,
      profileName: profile?.name,
    });
    console.log(text)
    dispatch(setMessagesChat(messages));
    mayaLoading();


    const startOption = option.edges_consumable.find(
      (edges) => edges.from_node === Eedges_consumable.START
    );

    const firstOption = option.nodes_consumable.find(
      (node) => node.id === startOption.to_node
    );

    dispatch(setFlowByUser(startOption));
    const messageFromMaya = formatMessageForChat({
      message: firstOption.response_message,
    });

    removeLoadingAppendMessage(messageFromMaya);
  };

  const setMessageForNotWorkFlow = () => {
    const messageSendFromMaya = formatMessageForChat({
      message: "At present, Maya does not possess this workflow.",
    });
    removeLoadingAppendMessage(messageSendFromMaya);
  };

  const handleNewMessage = useCallback((message: any) => {
    const isJoinRoom = message ? message.includes("joined") : false;
    setJoinTheRoom(isJoinRoom);
  }, []);

  const handlerErrorConnection = useCallback(
    (e: any) => {
      console.error("Connection error:", e);
      sentryErrorService(e);
      setMessages((prevMessages) =>
        appendMessage(prevMessages, [
          formatMessageForChat({
            message: "Unable to connect to the server. Trying to reconnect.",
          }),
        ])
      );
      setReconnectionCounter(reconnectionCounter + 1);
    },
    [reconnectionCounter]
  );

  const handleReconnect = (e: any) => {
    console.error(
      "Reconnection failed after several attempts. Server might be down."
    );
  };

  const handleNewAnswer = useCallback((answer: any) => {
    setLoading(false);
    const formatAnswer = formatMessageForChat({
      message: answer.message,
      timestamp: dayjs.unix(answer?.timestamp).toDate(),
    });
    removeLoadingAppendMessage(formatAnswer);
  }, []);

  const handleEventTraining = (event: TrainingEvent) => {
    if (event.status === ETrainingStatus.PROCESSING) {
      dispatch(setTrainingProcessing(true));
      return;
    }
    if (event.status === ETrainingStatus.SUCCESS) {
      dispatch(setProcessingFilesEnd());
      dispatch(setNotification(event));
      return;
    }
    if (event.status === ETrainingStatus.FAILED) {
      dispatch(setProcessingFilesEnd());
      dispatch(setNotification(event));
      return;
    }
  };
  const onSend = useCallback(
    (message: string) => {
      if (!message) {
        return;
      }
      onResetVoiceMessage();


      const messages = formatMessageForChat({
        message,
        userId,
        profileName: profile?.name,
      });

      const payload = {
        user_id: userId,
        query: messages.text,
        request_id: null,
        lang_key: "en",
        lang_key_voice: "Geraint",
        memclassset: "other",
        real_voice: "ai4-en-US-Ariana",
        shadowbox: "",
        token: token,
      };


      //FIXME: this is a hack to fix the bug of the chat
      // pubSubChat.emit(PubSubEvents.USER_SELECT_WORKFLOW, message);
      dispatch(setMessagesChat(messages));
      if (chatState.activateWorkFlow) {

        pubSubChat.emit(PubSubEvents.USER_SELECT_WORKFLOW, message);
        setTimeout(() => {
          
          handlerUserSendWorkFlow(message);
        }, 1000);

        return;
      }
      socket.emit("send_message", payload);
      mayaLoading();
    },
    [socket, chatState, chatState.activateWorkFlow]
  );

  const renderBubble = (props: any) => {
    return <BubbleRender props={props} />;
  };

  const handleStartRecord = () => {
    setSpeechModal(true);
    if (Platform.OS !== "web") {
      startRecognitionMobile(true).catch(console.error);
    } else {
      startRecognitionWeb();
    }
  };

  const sendMessagePressTextAction = (text: string) => {
    if(text=='#'){
      console.log("hashtag pressed")
    }
    onSend(text ?? message);
    if (Platform.OS === "web") {
      setSpeechModal(false);
      stopRecognizingWeb();
    }
    if (Platform.OS !== "web") {
      stopRecognitionMobile().then(() => {
        setSpeechModal(false);
      });
    }
  };

  useEffect(() => {
    const newSocket = webSocket({
      query: {
        user_id: userId,
        token: token?.access_token,
      },
      baseChatUrl,
    });
    setSocket(newSocket);
    newSocket.on("new_message", handleNewMessage);

    newSocket.on("connect_error", handlerErrorConnection);

    newSocket.on("reconnect_failed", handleReconnect);

    newSocket.on("new_answer", handleNewAnswer);

    newSocket.on("training_status", handleEventTraining);

    return () => {
      newSocket.close();
    };
  }, [baseChatUrl]);

  useEffect(() => {
    if (speechPause) {
      onSend(message);
      if (speechModal) {
        Platform.OS !== "web"
          ? startRecognitionMobile().catch(console.error)
          : startRecognitionWeb();
      }
    }
  }, [speechPause]);

  useEffect(() => {
    if (wakeUp && !speechModal) {
      handleStartRecord();
    }
  }, [wakeUp]);

  useEffect(() => {
    let heightOnSpeech =
      Platform.OS === "ios"
        ? EChatHeightOnOpenActionBanner.IOS_OPEN_SPEECH
        : EChatHeightOnOpenActionBanner.ANDROID_OPEN_SPEECH;
    let heightOffSpeech =
      Platform.OS === "ios"
        ? EChatHeightOnOpenActionBanner.IOS_CLOSE_SPEECH
        : EChatHeightOnOpenActionBanner.ANDROID_CLOSE_SPEECH;
    if (Platform.OS === "web") {
      heightOffSpeech = EChatHeightOnOpenActionBanner.WEB_OPEN_SPEECH;
      heightOnSpeech = EChatHeightOnOpenActionBanner.WEB_CLOSE_SPEECH;
    }

    if (speechModal) {
      setMinInputToolbarHeight(heightOnSpeech);
    } else if (chatState.selectedByUserFlow) {
      setMinInputToolbarHeight(
        Platform.OS !== "web"
          ? EChatHeightOnOpenActionBanner.OPEN_WORK_FLOW
          : EChatHeightOnOpenActionBanner.WEB_OPEN_SPEECH
      );
    } else {
      setMinInputToolbarHeight(heightOffSpeech);
    }
  }, [speechModal, keyboardHeight, chatState.selectedByUserFlow]);

  useFocusEffect(
    useCallback(() => {
      return () => {
        if (Platform.OS !== "web") {
          stopRecognitionMobile().then(() => {
            setSpeechModal(false);
          });
        }
      };
    }, [])
  );

  useEffect(() => {
    if (chatState.workFlow) {
      getTheDoingWorkWithOption(chatState.workFlow.flow)
        .then((workFlow) => {
          dispatch(setWorkOption(workFlow));
          setMessageForWorkFlow(workFlow, chatState.workFlow.name);
        })
        .catch((err) => {
          dispatch(stopWorkFlow());
          setMessageForNotWorkFlow();
        });
    }
  }, [chatState.workFlow]);

  return (
      <View
        style={
          dimentionState && !dimentionState.mobile
            ? styles.containerOnWeb
            : styles.container
        }
      >
        <View style={styles.containerChat}>
          <View style={{ flexDirection: "row", justifyContent: "center" }}>
            <ProcessingTraining processingFiles={processingFiles} />
          </View>
          <GiftedChat
            messages={chatState?.messages}
            user={{
              _id: userId,
            }}
            alwaysShowSend={true}
            showUserAvatar
            scrollToBottom={true}
            renderBubble={renderBubble}
            showAvatarForEveryMessage={true}
            keyboardShouldPersistTaps={"never"}
            messagesContainerStyle={{
              height:
                Platform.OS === "web"
                  ? Dimensions.get("screen").height -
                  minInputToolbarHeight -
                  (processingFiles ? 30 : 0)
                  : Dimensions.get("screen").height -
                  keyboardHeight -
                  minInputToolbarHeight -
                  (processingFiles ? 30 : 0),
                  bottom:activateWorkFlow&&Platform.OS=='android'||
                  Platform.OS=='web'?'7.5%':'3%',
                  width:Platform.OS=='web'?'70%':"100%",
display:'flex' ,
alignSelf:'center'                 
            }}
            renderAvatar={(props) => {
              return <AvatarChat props={props} userId={userId} />;
            }}
            renderInputToolbar={(props) => {
              return speechModal ? (
                <SpeechSectionOnVoice
                  volumeChange={volumeChange}
                  message={message}
                  sentMessage={() => sendMessagePressTextAction(null)}
                  recognitionEnd={recognitionEnd}
                />
              ) : (
                <View style={{flex:1}}>
                  
                <CustomChatInput
                  props={props}
                  sendFn={(text) => sendMessagePressTextAction(text)}
                  attachFn={pickDocument}
                  startRecord={handleStartRecord}
                />
                </View>
              );
            }}
            textInputRef={textInputRef}
            renderAvatarOnTop={false}
          />
        </View>

        {dimentionState && !dimentionState.mobile && (
          <View
            style={{
              width: 320,
              height: "auto",
              backgroundColor: colors.background,
              alignSelf: "flex-end",
              borderWidth: 0.1,
              borderLeftColor: colors.borderColor,
              borderBottomColor: colors.borderColor,
              
            }}
          >
            {Platform.OS=='web'?<View style={{bottom:500}}>
            <MenuContent />

            </View>:<MenuContent />}
          </View>
        )}
      </View>
  );
};

export default ChatHOC;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.background,
  },
  containerOnWeb: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "flex-end",
    backgroundColor: colors.background,
 
  },
  containerChat: {
    flex: 1,
    width: "100%",
    borderRightColor:colors.borderColor,
    borderWidth:1
  },
  itemContainer: {
    flexDirection: "row",
    alignItems: "center",
    paddingVertical: 5,
    backgroundColor: "#273236",
    paddingHorizontal: 10,
    flex: 1,
    color: "#fff",
  },
  Sendbutton: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    height: 40,
    width: 40,
    position: "absolute",
    left: -50,
    bottom: 10,
  },
  image: {
    flex: 1,
    justifyContent: "center",
    flexDirection: "column",
  },
  paperClip: {
    marginTop: 8,
    marginHorizontal: 5,
    transform: [{ rotateY: "180deg" }],
  },
  sendButton: { marginBottom: 10, marginRight: 10 },
  sendContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
  },

  fileContainer: {
    flex: 1,
    maxWidth: 300,
    marginVertical: 2,
    borderRadius: 15,
  },
  fileText: {
    marginVertical: 5,
    fontSize: 16,
    lineHeight: 20,
    marginLeft: 10,
    marginRight: 5,
  },

  buttonFooterChatImg: {
    width: 35,
    height: 35,
    borderRadius: 50,
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    borderColor: "black",
    left: 66,
    top: -4,
    backgroundColor: "rgba(255, 255, 255, 0.6)",
  },
});
