import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../types";
import React, { useEffect } from "react";
import Toast, { BaseToast, ErrorToast } from "react-native-toast-message";
import { AppDispatch } from "../store/store";
import { updateReadStatusAsyncThunk } from "../api/notifications/notification";
import { colors } from "../helpers/colors";
import { ETrainingStatus } from "../models/trainingEvent.model";

export const toastConfig = {
  /*
      Overwrite 'success' type,
      by modifying the existing `BaseToast` component
    */
  success: (props) => (
    <BaseToast
      text1NumberOfLines={4}
      {...props}
      style={{
        borderLeftColor: colors.springGreen,
        width: "100%",
        height: "auto",
        backgroundColor: colors.springGreen,
        borderRadius: 40,
      }}
      contentContainerStyle={{ padding: 15 }}
      text1Style={{
        fontSize: 15,
        fontWeight: "400",
        color: "black",
        padding: 5,
      }}
    // renderTrailingIcon={() => (
    //   <Image
    //     style={{
    //       height: 30,
    //       width: 30,
    //         marginTop: 10
    //     }}
    //     source={require("../assets/close.png")}
    //   />
    // )}
    />
  ),
  /*
      Overwrite 'error' type,
      by modifying the existing `ErrorToast` component
    */
  error: (props) => (
    <ErrorToast
      text1NumberOfLines={4}
      style={{
        borderLeftColor: colors.red,
        width: "100%",
        height: "auto",
        backgroundColor: colors.springGreen,
        borderRadius: 40,
      }}
      contentContainerStyle={{ padding: 15 }}
      {...props}
      text1Style={{
        fontSize: 15,
        fontWeight: "400",
        color: "black",
        padding: 5,
      }}
    // renderTrailingIcon={() => (
    //   <Image
    //     style={{
    //       height: 30,
    //       width: 30,
    //       top: Platform.OS === "web" ? 25 : 32,
    //       right: 10,
    //     }}
    //     source={require("../assets/close.png")}
    //   />
    // )}
    />
  ),
};

const useNotification = () => {
  const successMessage = "Maya successfully processed";
  const errorMessage =
    "The document you just uploaded does not have any text. We are unable to process it. This will be available in the next release.";
  const dispatch = useDispatch<AppDispatch>();
  const { userId } = useSelector(
    (state: RootState) => state.persist.authReducer,
  );
  const { display, trainingEvent } = useSelector(
    (state: RootState) => state.root.notificationReducer,
  );

  const trainingEventItem = (trainingEvent: any[]) => {
    return trainingEvent.map((item) => {
      const message =
        item.status === ETrainingStatus.SUCCESS ? successMessage : errorMessage;
      console.debug("trainingEventItem", item.status);
      Toast.show({
        type: item.status === ETrainingStatus.SUCCESS ? "success" : "error",
        text1: `${message}  ${item.status === ETrainingStatus.SUCCESS
            ? item.filenames && item.filenames.length > 0
              ? item.filenames.join(", ")
              : "-"
            : ""
          } ${item.status === ETrainingStatus.SUCCESS
            ? "and ready to answer your questions about it"
            : ""
          } `,
        onPress: () => {
          Toast.hide();
          dispatch(
            updateReadStatusAsyncThunk({
              requestId: item.request_id,
              userId,
            }),
          );
        },
        onHide: () => {
          dispatch(
            updateReadStatusAsyncThunk({
              requestId: item.request_id,
              userId,
            }),
          );
        },
      });
    });
  };

  useEffect(() => {
    // console.log("useNotification", trainingEvent);
    if (trainingEvent && trainingEvent.length > 0) {
      trainingEventItem(trainingEvent);
    }
  }, [trainingEvent]);

  return trainingEvent;
};

export default useNotification;
