import {
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import React from "react";
import { colors } from "../../helpers/colors";
import { DATA_FOR_TRAINING } from "../../helpers/constants";
import { TOptionsWorkFlow } from "../../models/workFlow.model";
import { dispatch } from "../../store/store";
import { setWorkFlow } from "../../reducers/chatSlice";
import ChipWorkFlow from "../Chip/ChipWorkFlow";

export type AutoTemplateActionSheetProps = {
  handleTemplate: (template: string) => void;
  stylesProps?: any;
};

const AutoTemplateActionSheet = ({
  handleTemplate,
  stylesProps,
}: AutoTemplateActionSheetProps) => {
  const handlerChip = (option: TOptionsWorkFlow) => {
    dispatch(setWorkFlow(option));
    handleTemplate(option.name);
  };

  return (
    <View style={[styles.container, stylesProps]}>
      <ScrollView horizontal={true}>
      <View style={styles.chipContainer}>
          {DATA_FOR_TRAINING.map((data) => (
            <ChipWorkFlow
              option={data}
              handleChipSelect={(option) => handlerChip(option)}
              key={data.id}
            />
          ))}
      </View>
        </ScrollView>
    </View>
  );
};
const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.background,
    borderTopColor: colors.mineshaft,
    borderTopWidth: 1,
    width: "100%",
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
    padding: 10,
  },
  text: {
    color: "white",
    fontSize: 18,
    padding: 2,
  },
  chipContainer: {
    flexDirection: "row",
    justifyContent: "flex-start",
    flexWrap: "nowrap",
    height: "auto",

  },
  chipWrapper: {
    borderRadius: 38,
    borderColor: "rgba(255, 255, 255, 0.20)",
    borderWidth: 1,
    paddingVertical: 8,
    paddingHorizontal: 10,
    backgroundColor: colors.mineshaft,
    margin: 10,
  },
});
export default AutoTemplateActionSheet;
