import React from "react";
import { Animated, StyleSheet, TouchableOpacity, View } from "react-native";

// Components
import MayaText from "../typography/MayaText";
import { colors } from "../../helpers/colors";

type Props = {};

const CustomTabBar = ({ navigation, descriptors, state, _onPress }: any) => {
  return (
    <View style={{ maxHeight: 70 }}>
      <View
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
          borderBottomColor: colors.borderColor,
          borderBottomWidth: 1,
        }}
      >
        {state.routes.map((route: any, index: number) => {
          const { options } = descriptors[route.key];
          const label =
            options.tabBarLabel !== undefined
              ? options.tabBarLabel
              : options.title !== undefined
              ? options.title
              : route.name;

          const isFocused = state.index === index;

          const onPress = () => {
            const event = navigation.emit({
              type: "tabPress",
              target: route.key,
              canPreventDefault: true,
            });
            _onPress(label);
            if (!isFocused && !event.defaultPrevented) {
              // The `merge: true` option makes sure that the params inside the tab screen are preserved
              navigation.navigate({ name: route.name, merge: true });
            }
          };

          const onLongPress = () => {
            navigation.emit({
              type: "tabLongPress",
              target: route.key,
            });
          };

          return (
            <TouchableOpacity
              accessibilityRole="button"
              accessibilityState={isFocused ? { selected: true } : {}}
              accessibilityLabel={options.tabBarAccessibilityLabel}
              testID={options.tabBarTestID}
              onPress={onPress}
              onLongPress={onLongPress}
              style={{
                flex: 1,
                justifyContent: "center",
                padding: 10,
                paddingTop: 30,
                borderBottomColor: isFocused ? colors.anakiwa : "transparent",
                borderBottomWidth: 3,
              }}
            >
              <Animated.Text
                style={{
                  color: isFocused ? "#FFF" : "#ffffff80",
                  fontWeight: "700",
                  fontSize: 13,
                  alignSelf: "center",
                  padding: 6,
                }}
              >
                {label.toUpperCase()}
              </Animated.Text>
            </TouchableOpacity>
          );
        })}
      </View>
    </View>
  );
};

export default CustomTabBar;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.background,
  },
});
