import React from "react";
import { StyleSheet, Text, View } from "react-native";
import { colors } from "../../helpers/colors";

const version = require("../../../app.json")?.expo?.version;

export type VersionComponentProps = {
  props?: any;
};

const VersionComponent = ({ props }: VersionComponentProps) => {
  return (
    <View {...props} style={styles.versionContainer}>
      <Text style={styles.versionText}>{version}</Text>
    </View>
  );
};
const styles = StyleSheet.create({
  versionContainer: {
    position: "absolute",
    bottom: 20,
    width: "100%",
    flexDirection: "row",
    justifyContent: "center",
  },
  versionText: {
    fontSize: 11,
    color: colors.springGreen,
  },
});
export default VersionComponent;
