import React, { FC } from "react";
import { StyleSheet, View } from "react-native";
import { useSelector } from "react-redux";
import { RootState } from "../../types";
import { colors } from "../helpers/colors";
type MobileViewHOCProps = {
  children: React.ReactNode;
};
const MobileViewHOC: FC<MobileViewHOCProps> = ({ children }) => {
  const dimentionState = useSelector(
    (state: RootState) => state.root.dimentionReducer
  );

  return (
    <View
      style={
        dimentionState && !dimentionState.mobile
          ? styles.containerOnWeb
          : styles.container
      }
    >
      {children}
    </View>
  );
};

const styles = StyleSheet.create({
  containerOnWeb: {
    flex: 1,
    flexDirection: "column",
    justifyContent: "space-between",
    maxWidth: 800,
    marginHorizontal: "auto",
    backgroundColor: colors.background,
  },
  container: {
    flex: 1,
    backgroundColor: colors.background,
    flexDirection: "column",
    justifyContent: "space-between",
  },
});
export default MobileViewHOC;
