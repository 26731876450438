import React, { useEffect, useState } from "react";
import { StyleSheet, View } from "react-native";
import { useNavigation, useRoute } from "@react-navigation/native";
import { useDispatch, useSelector } from "react-redux";
import { setNewWorkFolders } from "../reducers/worksSlice";
import { setMenuModalVisible } from "../reducers/generalSlice";
import uuid from "react-native-uuid";

// Constants & Types
import { colors } from "../helpers/colors";
import { RootState } from "../../types";
import { RouteNames } from "../navigation/routeNames";

// Components
import FolderMenuContent from "../components/works/folders/folderMenuContent";
import WorksList from "../components/works/WorksList";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";

const WorksFolderHOC: React.FC = () => {
  const navigation = useNavigation<NativeStackNavigationProp<any>>();

  const dispatch = useDispatch();
  const { workFolders } = useSelector(
    (state: RootState) => state.root.worksReducer
  );

  //@ts-ignore
  const denormalizedFolders = workFolders.allIds?.map((id) => workFolders[id]);

  const _onPress = (key: string, value: string) => {
    const id = uuid.v4();
    dispatch(setNewWorkFolders({ id, [key]: value }));

    navigation.navigate(RouteNames.Folders);
  };

  const handleNewFolder = () => {
    navigation.navigate(RouteNames.SingleInputScreen, {
      key: "title",
      name: "New Folder",
      value: "",
      onPress: _onPress,
    });
  };

  const handleOnItemPress = (id: string) => {
    navigation.navigate(RouteNames.FolderScreen, { id });
  };

  return (
    <View style={styles.container}>
      <WorksList
        data={denormalizedFolders}
        onAddPress={handleNewFolder}
        onItemPress={handleOnItemPress}
      />
    </View>
  );
};

export default WorksFolderHOC;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.background,
    padding: 15,
  },
});
