import { BlurView } from "expo-blur";
import React from "react";
import { FC } from "react";
import {
  Dimensions,
  ImageBackground,
  ScrollView,
  StyleSheet,
  View,
} from "react-native";
import { colors } from "../../helpers/colors";
import Loading from "../Loading/Loading";

type Props = {
  children: React.ReactNode;
  paddingHorizontal?: number;
  footerComponent?: React.ReactNode;
};
const Layout: FC<Props> = ({
  children,
  paddingHorizontal = 15,
  footerComponent,
}: Props) => {
  return (
    <View style={[styles.container]}>
      {/* <ScrollView> */}
      <ImageBackground
        blurRadius={1}
        style={[
          styles.backgrounImage,
          { paddingHorizontal: paddingHorizontal },
        ]}
        source={require("../../assets/loginBackground.gif")}
      >
        <BlurView style={styles.blurContainer} intensity={80} tint="dark" />
        <View>{children}</View>
        {footerComponent && (
          <View style={styles.footerContainer}>{footerComponent}</View>
        )}
      </ImageBackground>
      {/* </ScrollView> */}
      <Loading />
    </View>
  );
};
const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.background,
    flex: 1,
    height: Dimensions.get("screen").height,
  },
  backgrounImage: {
    flexDirection: "row",
    height: "100%",
    justifyContent: "center",
  },
  loading: {
    backgroundColor: "#ffffff66",
    height: Dimensions.get("screen").height,
    width: Dimensions.get("screen").width,
    zIndex: 10,
  },
  blurContainer: {
    position: "absolute",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
  },
  footerContainer: {
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
  },
});
export default Layout;
