import React, { useEffect } from "react";
import Layout from "../components/Layout/Layout";
import {
  Image,
  Pressable,
  ScrollView,
  StyleSheet,
  Text,
  View,
} from "react-native";
import ButtonWide from "../components/general/ButtonWide";
import { useIsFocused, useNavigation } from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import { RouteNames } from "../navigation/routeNames";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../store/store";
import { setPromptMessage } from "../reducers/chatSlice";
import { getNotificationAsyncThunk } from "../api/notifications/notification";
import { RootState } from "../../types";
import { loginAsyncThunkLogout } from "../api/auth/auth";
import VersionComponent from "../components/Version/Version";

import { colors } from "../helpers/colors";
import AutoTemplateActionSheet from "../components/AutoTemplateActionSheet/AutoTemplateActionSheet";

const PromptHOC = () => {
  const isFocused = useIsFocused();
  const { userId } = useSelector(
    (state: RootState) => state?.persist?.authReducer,
  );
  const navigation = useNavigation<NativeStackNavigationProp<any>>();
  const dispatch = useDispatch<AppDispatch>();

  const wakeUp = () => {
    dispatch(setPromptMessage(""));
    navigation.push(RouteNames.Chat, {
      wakeUp: true,
    });
  };
  const goChat = (text: string) => {
    navigation.push(RouteNames.Chat, { wakeUp: false });
  };

  useEffect(() => {
    if (userId) {
      dispatch(getNotificationAsyncThunk({ userId: userId }));
    }
    if (!userId) {
      dispatch(loginAsyncThunkLogout());
    }
  }, [userId]);

  return (
    <Layout>
      <View style={styles.container}>
        <View style={styles.imageContainer}>
          <Image
            source={require("../assets/maya.png")}
            style={styles.image}
            resizeMode="contain"
          />
        </View>
        <View style={styles.textContainer}>
          <Text style={styles.title}>How can I help you?</Text>
          <View style={styles.optionsContainer}>
            <AutoTemplateActionSheet
              handleTemplate={(template) => goChat(template)}
              stylesProps={{
                backgroundColor: "transparent",
                borderTopWidth: 0,

              }}
            />
          </View>
        </View>

        <View style={styles.buttonWrapper}>
          <ButtonWide
            style={{ alignSelf: "center" }}
            text="Tap to chat"
            onPress={wakeUp}
          />
        </View>
        <VersionComponent props={{ bottom: 30 }} />
      </View>
    </Layout>
  );
};
const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  imageContainer: {
    alignSelf: "center",
  },
  image: {
    alignSelf: "center",
    width: 297,
    height: 297,
  },
  textContainer: {
    justifyContent: "center",
    alignItems: "center",
  },
  title: {
    fontSize: 24,
    color: "white",
    fontFamily: "Montserrat",
    fontWeight: "bold",
  },
  optionsContainer: {
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    paddingTop: 38,
    
  },
  option: {
    fontFamily: "Montserrat",
    fontSize: 18,
    color: "white",
    fontWeight: "500",
    paddingBottom: 20,
  },
  buttonWrapper: {
    width: 233,
    alignSelf: "center",
    marginTop: 30,
  },
  chipContainer: {
    flexDirection: "row",
    justifyContent: "flex-start",
    flexWrap: "nowrap",
    height: "auto",
  },
  chipWrapper: {
    borderRadius: 38,
    borderColor: "rgba(255, 255, 255, 0.20)",
    borderWidth: 1,
    paddingVertical: 8,
    paddingHorizontal: 10,
    backgroundColor: colors.mineshaft,
    margin: 10,
  },
});

export default PromptHOC;
