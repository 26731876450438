import React, { useEffect, useState } from "react";
import {
  Dimensions,
  Image,
  Platform,
  Pressable,
  StyleSheet,
  View,
} from "react-native";
import { NavigationContainer } from "@react-navigation/native";

// Types
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import { ParamListBase } from "@react-navigation/routers";

// Constants
import { colors } from "../helpers/colors";

// Components
import MayaText from "../components/typography/MayaText";
import MenuModal from "../components/general/menuModal";

// Icons
import BackIcon from "../icons/arrowLeft";
import { useDispatch, useSelector } from "react-redux";
import { setMenuModalVisible } from "../reducers/generalSlice";
import { RootState } from "../../types";
import MenuContent from "../components/general/MenuContent";
import { AppDispatch } from "../store/store";
import { setMobile } from "../reducers/dimentionSlice";

type Props = {
  title?: string;
  back?: boolean;
  navigation?: NativeStackNavigationProp<ParamListBase>;
  showMenu?: boolean;
  headerRight?: React.ReactNode;
  width?: number;
};
type CustomScaledSize = {
  window: {
    width: number;
    height: number;
    scale: number;
    fontScale: number;
  };
};
const OptionsNavigationHeader: React.FC = ({
  title,
  back,
  navigation,
  headerRight,
}: Props) => {
  const menu = //@ts-ignore
    headerRight && headerRight.headerRight ? (
      //@ts-ignore
      headerRight.headerRight()
    ) : (
      <View />
    );

  const dispatch = useDispatch<AppDispatch>();
  const { menuModalVisible } = useSelector(
    (state: RootState) => state.root.generalReducer
  );

  const closeModal = () => {
    dispatch(setMenuModalVisible(false));
  };
  const [dimensions, setDimensions] = useState(Dimensions.get("window").width);
  const [isMobile, setIsMobile] = useState(true);

  useEffect(() => {
    if (Platform.OS == "web") {
      const handleScreenDimensionsChange = (newDimensions) => {
        const newWidth = Dimensions.get("window").width;
        setDimensions(newWidth);
      };

      handleScreenDimensionsChange(Dimensions.get("window"));

      Dimensions.addEventListener("change", handleScreenDimensionsChange);
      if (dimensions < 966) {
        setIsMobile(true);
        dispatch(setMobile(true));
      } else {
        setIsMobile(false);
        dispatch(setMobile(false));
      }
      return;
    }
    setMobile(true);
    dispatch(setMobile(true));
  }, [dimensions]);
  return (
    <View style={styles.container}>
      <Pressable
        style={{ paddingHorizontal: 15 }}
        onPress={() => navigation?.goBack()}
      >
        {back && <BackIcon />}
      </Pressable>

      <MayaText
        style={{
          fontSize: 20,
          fontWeight: "800",
          color: "white",
          paddingVertical: 10,
        }}
      >
        {title}
      </MayaText>

      {headerRight ? (
        <Pressable onPress={() => dispatch(setMenuModalVisible(true))}>
          {isMobile && (
            <Image
              source={require("../assets/menuBurger.png")}
              style={{ width: 40, height: 40 }}
            />
          )}
        </Pressable>
      ) : (
        <View style={{ width: 30 }} />
      )}

      <MenuModal isVisible={menuModalVisible} closeModal={closeModal}>
        <MenuContent closeModal={closeModal} />
      </MenuModal>
    </View>
  );
};

export default OptionsNavigationHeader;

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.background,
    paddingVertical: 5,
    paddingHorizontal: 20,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    borderBottomColor: "#FFFFFF33",
    borderBottomWidth: 1,
  },
  modalContainer: {
    backgroundColor: "#000000",
    flex: 0.7,
    paddingHorizontal: 40,
    paddingVertical: 10,
  },
  menuWrapper: {
    width: 70,
    height: 3,
    backgroundColor: "#fff",
    alignSelf: "center",
    borderRadius: 50,
    marginBottom: 20,
  },
  menuItemWrapper: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 20,
  },
});
