import React, { useEffect, useState } from "react";
import { Platform, StyleSheet, TextInput, View } from "react-native";
import { useNavigation, useRoute } from "@react-navigation/native";

// Constants
import { colors } from "../../helpers/colors";

// Components
import ButtonWide from "../general/ButtonWide";
import MayaText from "../typography/MayaText";

type Params = {
  key?: string;
  name?: string;
  value?: string;
  onPress?: (key: string, value: string) => void;
  subtitle?: string;
  buttonText?: string;
};

const SingleInputScreen: React.FC = () => {
  const route = useRoute();
  const navigation = useNavigation();
  const params: Params = route?.params;
  const key = params?.key;
  const name = params?.name;
  const value = params?.value;
  const onPress = params?.onPress;
  const subtitle = params?.subtitle;
  const buttonText = params?.buttonText;

  const [newValue, setNewValue] = useState(value);

  useEffect(() => {
    navigation.setOptions({
      title: name,
    });
  }, [route, navigation]);

  return (
    <View style={styles.mainContainer}>
      <View style={styles.container}>
        <View>
          <TextInput
            ref={(ref) => ref?.focus()}
            style={styles.input}
            value={newValue}
            placeholder={`Put your ${name} here...`}
            placeholderTextColor={colors.placeholder}
            onChangeText={setNewValue}
            secureTextEntry={key === "password"}
          />

          {subtitle && (
            <MayaText style={{ paddingHorizontal: 30, fontSize: 16 }}>
              {subtitle}
            </MayaText>
          )}
        </View>

        <View style={styles.buttonWrapper}>
          <ButtonWide
            text={buttonText || "Save"}
            onPress={() => onPress(key, newValue)}
          />
        </View>
      </View>
    </View>
  );
};

export default SingleInputScreen;

const styles = StyleSheet.create({
  mainContainer: {
    width: "100%",
    height: "100%",
    backgroundColor: colors.background,
  },
  container: {
    flex: 1,
    justifyContent: "space-between",
    padding: 10,
    width: Platform.OS !== "web" ? "auto" : 500,
    marginHorizontal: "auto",
  },
  input: {
    backgroundColor: colors.background2,
    borderRadius: 30,
    padding: 10,
    paddingHorizontal: 20,
    margin: 20,
    borderColor: colors.borderColor,
    borderWidth: 1,
    color: "#fff",
    fontSize: 18,
  },
  buttonWrapper: {
    padding: 10,
    borderTopColor: colors.borderColor,
    borderTopWidth: 1,
  },
});
