import React from "react";
import { RouteNames } from "./routeNames";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import TopTabNavigator from "../components/Registration/TopRegisterBar";
import ChatHOC from "../containers/ChatHOC";
import LoginHOC from "../containers/LoginHOC";
import WebViewHOC from "../containers/WebViewHOC";
import ForgotPasswordHOC from "../containers/ForgotPasswordHOC";
import ResetPasswordHOC from "../containers/ResetPasswordHOC";
import SplashHOC from "../containers/SplashHOC";
import PromptHOC from "../containers/PromptHOC";

const Stack = createNativeStackNavigator();

const AuthStack = () => {
  return (
    <Stack.Navigator
      // initialRouteName={RouteNames.Splash} // Change to the desired initial screen
      screenOptions={{ headerShown: false, animation: 'none' }}
    >
      <Stack.Screen name={RouteNames.Login} component={LoginHOC} />
     
      <Stack.Screen name={RouteNames.ForgotPassword} component={ForgotPasswordHOC} />
      <Stack.Screen name={RouteNames.ResetPassword} component={ResetPasswordHOC} />
      {/* <Stack.Screen name={RouteNames.Prompt} component={PromptHOC} /> */}

      <Stack.Screen name={RouteNames.TopRegisterBar} component={TopTabNavigator} />
      <Stack.Screen name={RouteNames.Chat} component={ChatHOC} />
    </Stack.Navigator>
  );
};

export default AuthStack;