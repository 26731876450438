import { requests } from "../agent";
import { createAsyncThunk } from "@reduxjs/toolkit";

export interface WriteSomethingInput {
  thinklikeachore: string | undefined;
  manset: string;
}

const insightsAgent = {
  writeSomethingByTheme: (theme: string, body: WriteSomethingInput) =>
    requests.post<any>(`/doing_work/${theme}`, {
      thinklikeachore: body.thinklikeachore,
      manset: body.manset,
    }),
};

// INFO here we add the  createAsyncThunk for Redux to use the API

export const writeSomethingByThemeAsyncThunk = createAsyncThunk(
  "insights/writeSomethingByTheme",
  async (data: { theme: string; body: WriteSomethingInput }) => {
    const response = await insightsAgent.writeSomethingByTheme(
      data.theme,
      data.body
    );
    console.log(response);
    return response;
  }
);
