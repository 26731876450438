import React, { useEffect, useState } from "react";
import Layout from "../Layout/Layout";
import MayaTitle from "../typography/MayaTitle";
import MayaText from "../typography/MayaText";
import {
  View,
  Image,
  Modal,
  StyleSheet,
  Alert,
  ImageBackground,
  Pressable,
} from "react-native";
import * as Progress from "react-native-progress";
import { colors } from "../../helpers/colors";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../types";
import { BlurView } from "expo-blur";
import { AppDispatch } from "../../store/store";
import { setLoadingModal } from "../../reducers/generalSlice";

type LoadingModalProps = {
  modalVisible: boolean;
};

const Loading = () => {
  const [progress, setProgress] = useState(0);
  const { loadingModal } = useSelector(
    (state: RootState) => state.root.generalReducer
  );
  const dispatch = useDispatch<AppDispatch>();

  const incrementWithDelay = () => {
    let number = 0; // Initialize the number to 0

    function incrementAndLog() {
      if (number < 10) {
        // Check if the number is less than 10
        number++; // Increment the number
        setProgress(number);
        setTimeout(incrementAndLog, 1000); // Schedule the next increment after 1000 milliseconds (1 second)
      }
    }

    // Start the incrementing process
    incrementAndLog();
  };

  useEffect(() => {
    incrementWithDelay();
  }, []);

  return (
    <Modal animationType="slide" transparent={true} visible={loadingModal}>
      <ImageBackground
        blurRadius={1}
        style={[styles.container, { paddingHorizontal: 10 }]}
        source={require("../../assets/loginBackground.gif")}
      >
        <BlurView style={styles.blurContainer} intensity={80} tint="dark" />
        <View style={styles.closeContainerImage}>
          <Pressable onPress={() => dispatch(setLoadingModal(false))}>
            <Image
              source={require("../../assets/x_close.png")}
              style={styles.closeImage}
              resizeMode="contain"
            />
          </Pressable>
        </View>
        <Image
          source={require("../../assets/maya.png")}
          style={styles.image}
          resizeMode="center"
        />

        <MayaTitle style={{ marginBottom: 10, fontSize: 24 }}>
          Maya is working...
        </MayaTitle>
        <MayaText style={{ textAlign: "center", fontSize: 16 }}>
          Be Patient
        </MayaText>

        <View
          style={{
            alignSelf: "center",
            paddingVertical: 10,
            flex: 1,
            justifyContent: "space-between",
            marginTop: 30,
          }}
        >
          <Progress.Circle
            progress={progress / 10}
            showsText={true}
            textStyle={{ fontSize: 16, fontWeight: "600" }}
            size={70}
            borderWidth={12}
            color={"#fff"}
            unfilledColor={"red"}
            animated={true}
            indeterminate={true}
          />
        </View>
      </ImageBackground>
    </Modal>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.background,
    paddingTop: 20,
  },
  blurContainer: {
    position: "absolute",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
  },
  image: {
    alignSelf: "center",
    width: 405,
    height: 405,
  },
  closeImage: {
    width: 40,
    height: 40,
  },
  closeContainerImage: {
    alignSelf: "flex-end",
    flexDirection: "row",
    justifyContent: "flex-end",
  },
});

export default Loading;
