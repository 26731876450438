import { createAsyncThunk } from "@reduxjs/toolkit";
import { UserModel } from "../../models/user.model";
import { requests } from "../agent";
import Toast from "react-native-toast-message";
import * as Sentry from "sentry-expo";
import { sentryErrorService } from "../../services/sentry-error.service";

export const cacheData: Map<string, UserModel> = new Map<string, UserModel>();
export const userAgent = {
  getUser: () => requests.get<UserModel>("/auth/profile"),
  updateUser: (user: UserModel) =>
    requests.patch<Partial<UserModel>>("/auth/update_profile", {
      ...user,
    }),
};

export const getUserAsyncThunk = createAsyncThunk("user/getUser", async () => {
  // if (cacheData.has("user")) {
  //   return cacheData.get("user") as UserModel;
  // }
  const response = await userAgent.getUser();
  cacheData.set("user", response);
  return response;
});

export const updateUserProfileAsyncThunk = createAsyncThunk(
  "user/updateUserProfile",
  async (user: UserModel) => {
    try {
      const response = await userAgent.updateUser(user);
      return response;
    } catch (error) {
      sentryErrorService(error);
      console.error("updateUserProfileAsyncThunk", error);
      Toast.show({
        type: "error",
        text1: `Something went wrong`,
      });
      return error;
    }
  },
);

export const cacheUser = () => {
  return cacheData.get("user") as UserModel;
};
