import React from "react";
import { ActivityIndicator, Pressable, StyleSheet, View } from "react-native";
import LinearGradient from "react-native-linear-gradient";
import { colors } from "../../helpers/colors";
import MayaText from "../typography/MayaText";

type Props = {
  text: string;
  onPress: () => void;
  disabled?: boolean;
  loading?: boolean;
  style?: any;
  styleText?: any;
};

const ButtonWide = ({
  text,
  onPress,
  disabled,
  loading,
  style,
  styleText,
}: Props) => {
  const onPressButton = () => {
    if (disabled) {
      return;
    }
    onPress ? onPress() : null;
  };
  return (
    <Pressable
      style={[styles.container, { opacity: disabled ? 0.5 : 1, ...style }]}
      onPress={onPressButton}
    >
      <MayaText style={[styles.buttonText, { ...styleText }]}>
        {text}
        {loading && (
          <View style={styles.activityIndicator}>
            <ActivityIndicator size="small" color="#0000ff" />
          </View>
        )}
      </MayaText>
    </Pressable>
  );
};

export default ButtonWide;

const styles = StyleSheet.create({
  container: {
    width: "100%",
    marginBottom: 10,
    backgroundColor: colors.springGreen,
    padding: 20,
    borderRadius: 50,
  },
  buttonText: {
    fontWeight: "700",
    fontSize: 18,
    textAlign: "center",
    color: "black",
  },
  activityIndicator: {
    marginLeft: 10,
  },
});
