export interface IDoingWork {
  edges_consumable: EdgesConsumable[];
  id: string;
  nodes_consumable: NodesConsumable[];
}

export interface EdgesConsumable {
  from_node: string;
  return_type: string;
  return_value: any;
  to_node: string;
  user_message: any;
}

export interface NodesConsumable {
  answers?: any;
  id: string;
  response_message?: string;
  show_type: string;
}

export enum Eedges_consumable {
  START = "start",
  END = "end",
}
