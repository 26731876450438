import { useEffect, useState } from "react";
import Voice from "@react-native-voice/voice";
import { Platform } from "react-native";
import { userSaidHeyMaya } from "../helpers/utils";
import { setRecognitionEnd,stopRecognition } from "../reducers/recognitionSlice";

declare const window: any;
const options = {
  // EXTRA_SPEECH_INPUT_COMPLETE_SILENCE_LENGTH_MILLIS: 8000, //INFO if this activate then the on end is not working on android
  REQUEST_PERMISSIONS_AUTO: true,
  EXTRA_MAX_RESULTS: 2,
  EXTRA_PARTIAL_RESULTS: true,
  EXTRA_LANGUAGE_MODEL: "LANGUAGE_MODEL_FREE_FORM",
  EXTRA_SPEECH_INPUT_POSSIBLY_COMPLETE_SILENCE_LENGTH_MILLIS: 4000, // how long it should wait after silence
};
let timerAuto: any;
const useHayMayaRecognition = () => {
  const [volumeChange, setVolumeChange] = useState(false);
  const [activateMaya, setActivateMaya] = useState(false);
  const [message, setMessage] = useState("");
  const [speechPause, setSpeechPause] = useState(false);
  const [recognition, setRecognition] = useState<any>();
  const [recognitionEnd, setRecognitionEnd] = useState<any>();

  const [activateAutoSend, setActivateAutoSend] = useState(true);

  const handleResultRecognitionWeb = (event: any) => {
    if (timerAuto) {
      clearTimeout(timerAuto);
    }
    setVolumeChange(true);
    let speech_result = event.results[event.results.length - 1][0].transcript;
    const recordingResult = Array.from(event.results)
      .map((result) => result[0])
      .map((result) => result.transcript)
      .join("");
    console.log("Speech result: ", recordingResult);

    setSpeechPause(false);
    setMessage(recordingResult);
  };
  const startRecognitionWeb = (activateAutoSend: boolean = true) => {
    setActivateAutoSend(activateAutoSend);
    if ("SpeechRecognition" in window || "webkitSpeechRecognition" in window) {
      //@ts-ignore
      const recognition = new (window?.SpeechRecognition ||
        //@ts-ignore
        window?.webkitSpeechRecognition)();
      setRecognition(recognition);
      recognition.lang = "en-US";
      recognition.continuous = false;
      recognition.interimResults = true;
      recognition.start();
      recognition.onresult = handleResultRecognitionWeb;
      recognition.onend = () => {
        setVolumeChange(false);
        if (timerAuto) {
          clearTimeout(timerAuto);
        }
        timerAuto = setTimeout(() => {
          if (activateAutoSend) {
            setSpeechPause(true);
            stopRecognizingWeb();
          }
        }, 3000);
      };
    }
  };

  const onSpeechEnd = (event: any) => {
    console.debug("use Maya onSpeechEnd: ", event);
    if (Platform.OS === "android") {
      return;
    }
    clearTimeout(timerAuto);
    setSpeechPause(true);
  };

  const onSpeechError = (event: any) => {
    console.debug("use Maya onSpeechError: ", event);
    setRecognitionEnd(true)
  };

  const onSpeechResults = (event: any) => {
    console.debug("use Maya onSpeechResults: ", event);
    if (event && event.value && event.value.length > 0) {
      const message = event && event.value.length ? event.value[0] : "";
      setMessage(message);
      if (timerAuto) {
        clearTimeout(timerAuto);
      }
      timerAuto = setTimeout(() => {
        setSpeechPause(true);
        if (activateAutoSend) {
          stopRecognitionMobile().catch(() =>
            console.error("stop recognition onSpeechResults"),
          );
        }
      }, 4000);
    }
  };

  const onSpeechPartialResults = (event: any) => {
    const message = event && event.value.length ? event.value[0] : "";
    console.log("onSpeechPartialResults", message);
    checkMessageForHayMaya(message);
  };

  const onSpeechVolumeChanged = (event: any) => {
    if (event) {
      const amplitude = event.value;
      const threshold = 3;
      const isAboveThreshold = amplitude > threshold;
      setVolumeChange(isAboveThreshold);
    }
  };

  const startRecognitionMobile = async (activateAutoSend: boolean = true) => {
    try {
      initValuesVoice();
      setActivateAutoSend(activateAutoSend);
      const isVoiceAvailable = await Voice.isAvailable();
      const isVoiceRecognizing = await Voice.isRecognizing();
      if (isVoiceAvailable && !isVoiceRecognizing) {
        await Voice.start("en-US", options);
      }
      if (isVoiceAvailable && isVoiceRecognizing) {
        await restartRecognitionMobile();
      }
      return true;
    } catch (e) {
      console.error("startRecognizing error", e);
      return false;
    }
  };

  const restartRecognitionMobile = async () => {
    try {
      const voiceIsAvailable = await Voice.isAvailable();
      const voiceIsRecognizing = await Voice.isRecognizing();
      if (voiceIsAvailable && !voiceIsRecognizing) {
        await Voice.destroy();
        await startRecognitionMobile();
      }
      return true;
    } catch (e) {
      console.error("restartRecognitionMobile error", e);
    }
  };

  const stopRecognitionMobile = async (isActiveMaya: boolean = false) => {
    try {
      await Voice.destroy();
      Voice.removeAllListeners();
      return true;
    } catch (e) {
      console.error("stopRecognitionMobile error", e);
      return false;
    }
  };

  const stopRecognizingWeb = () => {
    if (recognition) {
      recognition.abort();
      recognition.stop();
    }
    clearTimeout(timerAuto);
    setRecognition(null);
  };
  const onResetVoiceMessage = () => {
    setMessage("");
  };

  const initValuesVoice = () => {
    setMessage("");
    setSpeechPause(false);
  };

  const checkMessageForHayMaya = (message: string) => {
    const saidHeyMaya = userSaidHeyMaya(message);
    setActivateMaya(saidHeyMaya);
  };

  useEffect(() => {
    if (Platform.OS !== "web") {
      Voice.onSpeechEnd = onSpeechEnd;
      Voice.onSpeechError = onSpeechError;
      Voice.onSpeechResults = onSpeechResults;
      Voice.onSpeechPartialResults = onSpeechPartialResults;
      Voice.onSpeechVolumeChanged = onSpeechVolumeChanged;
    }
    return () => {
      if (Platform.OS !== "web") {
        Voice.destroy().then(() => {
          Voice.removeAllListeners();
        });
      }
    };
  }, [Voice]);
  return {
    message,
    volumeChange,
    activateMaya,
    speechPause,
    recognition,
    recognitionEnd,
    stopRecognitionMobile,
    onResetVoiceMessage,
    startRecognitionMobile,
    restartRecognitionMobile,
    startRecognitionWeb,
    stopRecognizingWeb,
    setActivateMaya,
  };
};

export default useHayMayaRecognition;
