import React, { useEffect, useState } from "react";
import { Platform, Pressable, StyleSheet, View } from "react-native";
import * as DocumentPicker from "expo-document-picker";

import { useDispatch, useSelector } from "react-redux";
import { useNavigation, useRoute } from "@react-navigation/native";
// import * as Sentry from "sentry-expo"; // Constants
import { colors } from "../helpers/colors";
import { RootState } from "../../types";

// Components
import MayaText from "../components/typography/MayaText";
import ButtonWide from "../components/general/ButtonWide";

// Icons
import PickFromIcon from "../icons/pickFrom";
import { trainingMultiPdfAsyncThunk } from "../api/training/training";
import { AppDispatch } from "../store/store";
import FilesSelected from "../components/training/ChatFooter";
import { clearState, setLoading } from "../reducers/trainingSlice";
import MobileViewHOC from "./MobileViewHOC";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import { RouteNames } from "../navigation/routeNames";
import { sentryErrorService } from "../services/sentry-error.service";

type RouteParams = {
  type: string;
};

const DocumentTrainingHOC: React.FC = () => {
  const navigation = useNavigation<NativeStackNavigationProp<any>>();
  const { userId } = useSelector(
    (state: RootState) => state.persist.authReducer,
  );
  const trainingReducer = useSelector(
    (state: RootState) => state.root.trainingReducer,
  );
  const { baseUrl } = useSelector(
    (state: RootState) => state.root.generalReducer,
  );
  const route = useRoute();
  const type = route.params ? (route.params as RouteParams).type : null;
  const [files, setFiles] = useState<DocumentPicker.DocumentPickerAsset[]>([]);
  const [responseMessage, setResponseMessage] = useState<string>("");
  const dispatch = useDispatch<AppDispatch>();

  const pickDocument = async () => {
    try {
      dispatch(setLoading(true));
      let result = await DocumentPicker.getDocumentAsync({
        type:
          type === "DOCUMENT"
            ? ["application/docx",
              "text/plain",
              "application/vnd.ms-excel",
              'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
              'application/pdf'
            ]
            : [
              "text/csv",
              "text/x-csv",
            ],
        multiple: true,
      });
      dispatch(setLoading(false));
      if (!result) {
        return;
      }
      if (result && result.assets && result.assets.length > 0) {
        uploadMultipleDocument(result.assets);
      }
    } catch (error) {
      dispatch(setLoading(false));
      sentryErrorService(error);
      console.error("pickDocument", error);
    }
  };
  const uploadMultipleDocument = (
    files: DocumentPicker.DocumentPickerAsset[],
  ) => {
    try {
      if (files.length === 0) return;
      setFiles(files);
    } catch (error) {
      sentryErrorService(error);
      console.log("Error uploading file: ", error);
    }
  };
  const uploadDocument = async () => {
    try {
      const formData = new FormData();
      files.forEach((file: any) => {
        if (Platform.OS === "web") {
          formData.append("file", file!.file!);
        } else {
          formData.append("file", file!);
        }
      });
      formData.append("userid", userId);
      dispatch(trainingMultiPdfAsyncThunk({ formData: formData, baseUrl }));
      setFiles([]);
    } catch (error) {
      console.error("Error uploading file: ", error);
      sentryErrorService(error);
      setResponseMessage("Error uploading file");
    }
  };
  const handlerCLearFilterFromChat = (fileName: string) => {
    setFiles((prev) => {
      return prev.filter((item, i) => item.name !== fileName);
    });
  };

  useEffect(() => {
    dispatch(clearState());
    setResponseMessage("");
  }, []);

  useEffect(() => {
    if (trainingReducer.result.message) {
      setResponseMessage(trainingReducer.result.message);
      navigation.navigate(RouteNames.Chat);
      return;
    }

    return () => {
      setResponseMessage("");
    };
  }, [trainingReducer, trainingReducer.result]);

  return (
    <View style={styles.container}>
      <MobileViewHOC>
        <View>
          <Pressable style={styles.cameraContainer} onPress={pickDocument}>
            {files.length > 0 ? (
              <FilesSelected
                files={files}
                onClearFile={handlerCLearFilterFromChat}
              />
            ) : (
              <>
                <PickFromIcon />
                <MayaText
                  style={{ fontSize: 12, color: "#FFFFFFBF", marginTop: 10 }}
                >
                  Choose a {type === "CSV" ? "CSV " : " "}file
                </MayaText>
                <MayaText
                  style={{ fontSize: 12, color: "#FFFFFFBF", marginTop: 5 }}
                >
                  {type === "DOCUMENT" ? "DOCX, TXT, PDF up to 10MB" : " "}
                </MayaText>
              </>
            )}
          </Pressable>
        </View>

        <View>
          <MayaText
            style={{ fontSize: 16, fontWeight: "500", textAlign: "center" }}
          >
            Team Maya, only give me really, really good stuff... Seriously.
          </MayaText>

          <MayaText
            style={{
              fontSize: 18,
              fontWeight: "700",
              textAlign: "center",
              marginTop: 30,
            }}
          >
            {/* {responseMessage} */}
          </MayaText>
        </View>

        <View style={styles.buttonWrapper}>
          <ButtonWide
            text={"Start Training"}
            onPress={files && uploadDocument}
            disabled={files.length === 0 || trainingReducer?.loading}
            loading={trainingReducer?.loading}
            style={{ padding: 10 }}
          />
        </View>
      </MobileViewHOC>
    </View>
  );
};

export default DocumentTrainingHOC;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.background,
    padding: 20,
    height: "100%",
  },
  filesSelected: {
    padding: 5,
    textAlign: "left",
  },
  cameraContainer: {
    height: "auto",
    minHeight: 300,
    backgroundColor: colors.background2,
    marginBottom: 20,
    borderRadius: 30,
    justifyContent: "center",
    alignItems: "center",
  },
  buttonWrapper: {
    padding: 10,
    borderTopColor: colors.borderColor,
    borderTopWidth: 1,
    marginTop: "auto",
  },
});
