import { createSlice } from "@reduxjs/toolkit";
import { writeSomethingByThemeAsyncThunk } from "../api/insight/insight";

export type InsightState = {
  loading: boolean;
};

const initialState: InsightState = {
  loading: false,
};

const insightSlice = createSlice({
  name: "insight",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      writeSomethingByThemeAsyncThunk.pending,
      (state, action) => {
        state.loading = true;
      }
    );
    builder.addCase(
      writeSomethingByThemeAsyncThunk.fulfilled,
      (state, action) => {
        state.loading = false;
      }
    );
    builder.addCase(
      writeSomethingByThemeAsyncThunk.rejected,
      (state, action) => {
        state.loading = false;
      }
    );
  },
});

export default insightSlice.reducer;
