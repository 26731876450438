import React, { useCallback } from "react";
import {
  Alert,
  Button,
  Linking,
  Platform,
  StyleSheet,
  Text,
  View,
} from "react-native";
import { colors } from "../../helpers/colors";
import { InAppBrowser } from "react-native-inappbrowser-reborn";
import * as url from "url";
import ButtonWide from "../general/ButtonWide";
import { URLS } from "../../helpers/constants";

const supportedURL = "https://google.com";

const unsupportedURL = "slack://open?team=123456";

type OpenURLButtonProps = {
  url: string;
  children: string;
};
export const getDeepLink = (path = "") => {
  const scheme = "maya_mobile";
  const prefix =
    Platform.OS == "android" ? `${scheme}://my-host/` : `${scheme}://`;
  return prefix + path;
};
const openInAppBrowser = async () => {
  const deepLink = getDeepLink("callback");
  const url = `${URLS.TEST}?redirect_uri=${deepLink}`;
  if (await InAppBrowser.isAvailable()) {
    InAppBrowser.openAuth(url, deepLink, {
      // iOS Properties
      dismissButtonStyle: "cancel",
      preferredBarTintColor: colors.springGreen,
      preferredControlTintColor: "white",
      readerMode: false,
      animated: true,
      modalPresentationStyle: "fullScreen",
      modalTransitionStyle: "coverVertical",
      modalEnabled: true,
      enableBarCollapsing: false,
      // Android Properties
      showTitle: true,
      toolbarColor: colors.springGreen,
      secondaryToolbarColor: "black",
      navigationBarColor: "black",
      navigationBarDividerColor: "white",
      enableUrlBarHiding: true,
      enableDefaultShare: true,
      forceCloseOnRedirection: false,
      // Specify full animation resource identifier(package:anim/name)
      // or only resource name(in case of animation bundled with app).
      animations: {
        startEnter: "slide_in_right",
        startExit: "slide_out_left",
        endEnter: "slide_in_left",
        endExit: "slide_out_right",
      },
      headers: {
        "my-custom-header": "my custom header value",
      },
    }).then((res) => {
      console.log("in app browser", res);
      console.log(JSON.stringify(res));
      Alert.alert(JSON.stringify(res));
    });
  } else {
    await Linking.openURL(url);
  }
};

const OpenURLButton = ({ url, children }: OpenURLButtonProps) => {
  const handlePress = useCallback(async () => {
    // Checking if the link is supported for links with custom URL scheme.
    const supported = await Linking.canOpenURL(url);

    if (supported) {
      // Opening the link with some app, if the URL scheme is "http" the web link should be opened
      // by some browser in the mobile

      await Linking.openURL(url).then((res) => console.log("open url", res));
      Linking.addEventListener("url", (event) => {
        console.log("linking url", { event });
      });
    } else {
      Alert.alert(`Don't know how to open this URL: ${url}`);
    }
  }, [url]);

  return <Button title={children} onPress={handlePress} />;
};

const LinkingUrl = () => {
  return (
    <View style={styles.container}>
      <View style={styles.buttonContainer}>
        <OpenURLButton url={URLS.TEST}>Open Maya Browser</OpenURLButton>
        <ButtonWide text="Register" onPress={openInAppBrowser} />
      </View>
    </View>
  );
};
export default LinkingUrl;
const styles = StyleSheet.create({
  container: {
    flexDirection: "column",
    justifyContent: "center",
    alignItems: 'flex-start',
    alignContent: "center",
    backgroundColor: colors.background,
  },
  buttonContainer: {
    width: "100%",
  },
});
