// api/api.js
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";

const useApi = () => {
  const { baseUrl } = useSelector((state) => state.root.generalReducer);
  // const dispatch = useDispatch();
  // console.log(token);

  const body = {
    thinklikeachore: "",
    manset: "",
    user_id: 1949,
    firstname: "Hector",
    lastname: "Gatsos",
    step: "updatedailys",
    lastlogin: "2023-06-01 23:27:45",
    type: "updatedailys",
    uploadstuff: "updatedailys",
    corefocus: "Getting More Sales",
    humanmotivation: "Growing my company",
    companyweakness: "Brand Awareness",
    smp: "Facebook",
    bestskillhuman: "Leading a Team",
    maintrend: "tech",
    maincommoditiy: "wheat",
    mainbrainchip: "GoogleNews",
    memid: "1TPI5n2P7RjgAq_66IQqr_Q_0qd9cB9-Sq64xhYCR8gM",
    dailyID: null,
  };

  const postWriteSomething = async (type, text, theme) => {
    await axios
      .post(
        `${baseUrl}/doing_work/${theme}`,
        {
          ...body,
          thinklikeachore: type,
          manset: text,
        },
        {
          headers: {
            Authorization: "No Auth",
          },
        }
      )
      .catch(function (error) {
        console.log("error", error);
        // if (error.response && error.response.status === 401) {
        // }
      });
  };

  const postUrl = async (url) => {
    return await axios
      .post(
        `${baseUrl}/training/url_raw_upload`,
        {
          url,
          userid: 1949,
        },
        {
          headers: {
            Authorization: "No Auth",
          },
        }
      )
      .catch(function (error) {
        console.log("error", error);
        // if (error.response && error.response.status === 401) {
        // }
      });
  };

  return { postWriteSomething, postUrl };
};

export default useApi;
