import * as React from "react";
import Svg, { Path } from "react-native-svg";

const SvgComponent = (props) => (
  <Svg
    width="10"
    height="14"
    viewBox="0 0 10 14"
    {...props}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <Path
      d="M1 13L8 7L1 1"
      stroke="white"
      stroke-opacity="0.75"
      stroke-width="2"
      strokeLinecap="round"
    />
  </Svg>
);

export default SvgComponent;
