export interface TrainingEvent {
  filename: string;
  message: string;
  status: ETrainingStatus;
  timestamp: number;
  type: string;
  user_id: string;
  ws_category: "TRAINING_STATUS";
  read?: boolean;
  request_id: string;
}
export interface HistoryModel {
  history: TrainingEvent[];
}
export enum ETrainingStatus {
  PROCESSING = "processing",
  FAILED = "failed",
  SUCCESS = "success",
}

export type HistoryNotification = {
  filenames: string[];
  read: boolean;
  request_id: string;
  status: string;
  user_id: string;
};
