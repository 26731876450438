import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import { STORAGE_KEYS } from "../helpers/constants";
import { getStorageData } from "../helpers/utils";

axios.defaults.baseURL = "https://platform.mayadashboard.com";

axios.interceptors.response.use(
  async (response) => {
    return response;
  },
  (err: AxiosError) => {
    const { data, status, config, headers } = err.response as AxiosResponse;
    switch (status) {
      case 400:
        // TODO: add store error display
        // TODO: add navigation
        break;
      case 401:
        // TODO: add store error display
        // TODO: add navigation
        break;
      case 403:
        // TODO: add store error display
        // TODO: add navigation
        break;
      case 404:
        // TODO: add store error display
        // TODO: add navigation
        break;
      case 500:
        // TODO: add store error display
        // TODO: add navigation
        break;
    }
    return Promise.reject(err);
  },
);

const responseBody = <T>(response: AxiosResponse<T>) => response.data;

axios.interceptors.request.use(async (config) => {
  const token = await getStorageData(STORAGE_KEYS.TOKEN);
  if (token && config && config.headers) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

export const requests = {
  get: <T>(url: string) => axios.get<T>(url).then(responseBody),
  post: <T>(url: string, body: {}, config?: AxiosRequestConfig) =>
    axios.post<T>(url, body, config).then(responseBody),
  put: <T>(url: string, body: {}) => axios.put<T>(url, body).then(responseBody),
  delete: <T>(url: string) => axios.delete<T>(url).then(responseBody),
  patch: <T>(url: string, body: {}) =>
    axios.patch<T>(url, body).then(responseBody),
};

export const axiosBase = axios;
