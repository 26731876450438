// import { IExecutable } from "./execution-factory.service";
import { IExecutable } from "./executable.interface";

export class HiddenNode extends IExecutable {
  response_message: string;

  constructor(response_message: string) {
    super();
    this.response_message = response_message;
  }

  execute() {
    return true;
  }
}
