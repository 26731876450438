import React, { useState } from "react";
import { Image, StyleSheet, View } from "react-native";
import * as Progress from "react-native-progress";

// Components
import LoginLayout from "../components/login/LoginLayout";
import MayaTitle from "../components/typography/MayaTitle";
import MayaText from "../components/typography/MayaText";

// Constants
import { colors } from "../helpers/colors";
import Layout from "../components/Layout/Layout";

type Props = {};

const LoadingHOC = ({}: Props) => {
  return (
    <View style={styles.container}>
      <Layout>
        <Image
          source={require("../assets/maya-min.png")}
          style={styles.image}
          resizeMode="center"
        />

        <MayaTitle style={{ marginBottom: 10 }}>Maya is working...</MayaTitle>
        <MayaText style={{ textAlign: "center" }}>Be Patient</MayaText>

        <View
          style={{
            alignSelf: "center",
            paddingVertical: 10,
            flex: 1,
            justifyContent: "space-between",
            marginTop: 30,
          }}
        >
          <Progress.Circle
            progress={0.4}
            showsText={true}
            textStyle={{ fontSize: 16, fontWeight: "600" }}
            size={70}
            borderWidth={12}
            color={"#fff"}
            unfilledColor={"red"}
            animated={true}
            // indeterminate={true}
          />
        </View>
      </Layout>
    </View>
  );
};

export default LoadingHOC;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.background,
    paddingTop: 20,
  },
  image: {
    alignSelf: "center",
    width: 405,
    height: 405,
  },
});
