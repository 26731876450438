import { requests } from "../agent";
import { IDoingWork } from "../../models/doingWotrk.model";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { sentryErrorService } from "../../services/sentry-error.service";

export const doingWorkAgent = {
  get: (id: string) => requests.get<IDoingWork>(`/doing_work/${id}`),
  post: (data: IDoingWork) => requests.post(`/doing_work/${data.id}`, data),

  // requests.post(`/doing_work/opinion_submit_answers`, data),
};

export const workFlowSendAsyncThunk = createAsyncThunk(
  "chat_slice/send",
  async (data: { dagFile: IDoingWork }, { rejectWithValue }) => {
    try {
      // const userAnswers = getState();

      return await doingWorkAgent.post(data.dagFile);
    } catch (e) {
      console.error("workFlowSendAsyncThunk", e);
      sentryErrorService(e);
      return rejectWithValue(e);
    }
  },
);
