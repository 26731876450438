import React, { useEffect, useState } from "react";
import { StyleSheet, View } from "react-native";
import { colors } from "../helpers/colors";
import ButtonWide from "../components/general/ButtonWide";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../types";
import { AppDispatch } from "../store/store";
import { useNavigation, useRoute } from "@react-navigation/native";
import MayaText from "../components/typography/MayaText";
import MobileViewHOC from "./MobileViewHOC";
import IpPortInput from "../components/ip-port-input/ip-port-input";
import {
  resetDefaultValues,
  setChatUpload,
  setDocumentUpload,
} from "../reducers/generalSlice";

const SettingsHOC = () => {
  const [clearValues, setClearValues] = useState(false);
  const [documentUrl, setDocumentUrl] = useState("");
  const [chatUrl, setChatUrl] = useState("");
  const { responseMessage, loadingUrl } = useSelector(
    (state: RootState) => state.root.urlInputReducer,
  );
  const dispatch = useDispatch<AppDispatch>();
  const route = useRoute();
  const navigation = useNavigation();
  const params: any = route?.params;
  const key = params?.key || "";
  const name = params?.name || "URL";
  const value = params?.value || "";
  const subtitle = params?.subtitle || "";
  const buttonText = "Update Urls";

  const handleUploadDocumentUrl = (url: string) => {
    if (!url) {
      return;
    }
    setDocumentUrl(`${url}`);
    setClearValues(false);
  };
  const handlerChatBaseUrl = (url: string) => {
    if (!url) {
      return;
    }
    setChatUrl(`${url}/chat`);
    setClearValues(false);
  };

  const handleSaveUrl = () => {
    if (chatUrl) {
      dispatch(setChatUpload(chatUrl));
    }
    if (documentUrl) {
      dispatch(setDocumentUpload(documentUrl));
    }
  };

  const restoreDefaultValues = () => {
    setDocumentUrl("");
    setChatUrl("");
    setClearValues(true);
    dispatch(resetDefaultValues());
  };

  useEffect(() => {
    navigation.setOptions({
      title: name,
    });
    return () => {
      setClearValues(false);
    };
  }, [route, navigation]);
  return (
    <View style={styles.container}>
      <MobileViewHOC>
        <View style={styles.inputButtonContainer}>
          <View>
            <View style={styles.containerInputButton}>
              <IpPortInput
                ipPlaceholder="Chat with Maya"
                portPlaceHolder="Port"
                handleText={handlerChatBaseUrl}
                clearValues={clearValues}
              />
            </View>
            <View style={styles.containerInputButton}>
              <IpPortInput
                ipPlaceholder="Document Upload"
                portPlaceHolder="Port"
                handleText={handleUploadDocumentUrl}
                clearValues={clearValues}
              />
            </View>

            {subtitle && (
              <MayaText style={{ paddingHorizontal: 30, fontSize: 16 }}>
                {subtitle}
              </MayaText>
            )}
          </View>
          <View style={styles.buttonWrapper}>
            <ButtonWide
              text={buttonText || "Save"}
              onPress={() => handleSaveUrl()}
              loading={loadingUrl}
              disabled={loadingUrl || (!chatUrl && !documentUrl)}
              style={{ padding: 10 }}
            />
            <ButtonWide
              text={"Restore default"}
              onPress={() => restoreDefaultValues()}
              loading={loadingUrl}
              style={{ padding: 10 }}
            />
          </View>
        </View>
      </MobileViewHOC>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.background,
    justifyContent: "space-between",
    flexDirection: "column",
    padding: 10,
  },
  input: {
    backgroundColor: colors.background2,
    borderRadius: 30,
    padding: 10,
    paddingHorizontal: 20,
    margin: 20,
    borderColor: colors.borderColor,
    borderWidth: 1,
    color: "#fff",
    fontSize: 18,
  },
  buttonWrapper: {
    padding: 10,
    borderTopColor: colors.borderColor,
    borderTopWidth: 1,
    width: "100%",
  },
  containerInputButton: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  inputButtonContainer: {
    flexDirection: "column",
    justifyContent: "space-between",
    alignContent: "center",
    height: "100%",
  },
});
export default SettingsHOC;
