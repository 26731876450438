const apiKey = "a43e24712079e238728ad267b0ca6aa3";

export interface DnsGoogleResponse {
  AD: boolean;
  Answer: Answer[];
  CD: boolean;
  Comment: string;
  Question: Question[];
  RA: boolean;
  RD: boolean;
  Status: number;
  TC: boolean;
}

export interface Answer {
  TTL: number;
  data: string;
  name: string;
  type: number;
}

export interface Question {
  name: string;
  type: number;
}

export const getIpFromDomain = async (domain: string): Promise<string> => {
  try {
    const response = await fetch(`https://dns.google/resolve?name=${domain}`);
    const json = await response.json();
    console.log("answer", json);
    const answer =
      json && json?.Answer ? json.Answer[0] : (null as Answer | null);
    console.log("answer", answer);
    return answer?.data || "";
  } catch (error) {
    console.error(error);
    return null;
  }
};
