import React, { useEffect, useState } from "react";
import { Image, KeyboardAvoidingView, StyleSheet, View } from "react-native";
import { useNavigation } from "@react-navigation/native";

// Constants
import { colors } from "../helpers/colors";
import { RouteNames } from "../navigation/routeNames";

// Components
import MayaText from "../components/typography/MayaText";
import LoginLayout from "../components/login/LoginLayout";
import MayaTitle from "../components/typography/MayaTitle";
import CustomTextInput from "../components/general/CustomTextInput";
import ButtonWide from "../components/general/ButtonWide";
import FooterText from "../components/typography/FooterText";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";

type Props = {};

const ResetPasswordHOC: React.FC = ({}: Props) => {
  const navigation = useNavigation<NativeStackNavigationProp<any>>();

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const [isStrongPassword, setIsStrongPassword] = useState(false);

  const strongPasswordRegex =
    /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*()\-_=+{}[\]\\|:;<>?/~])[A-Za-z\d!@#$%^&*()\-_=+{}[\]\\|:;<>?/~]{8,}$/;

  useEffect(() => {
    if (password !== confirmPassword) {
      setPasswordsMatch(false);
    } else {
      setPasswordsMatch(true);
    }

    setIsStrongPassword(strongPasswordRegex.test(password));
  }, [password, confirmPassword]);

  const navigateToLogin = () => {
    navigation.navigate(RouteNames.Login);
  };

  const handleRegister = () => {
    if (!passwordsMatch) {
      return;
    }

    // Password strength check

    const strongPasswordRegex =
      /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*()\-_=+{}[\]\\|:;<>?/~])[A-Za-z\d!@#$%^&*()\-_=+{}[\]\\|:;<>?/~]{8,}$/;
    if (!strongPasswordRegex.test(password)) {
      console.log(
        "Password should contain at least 8 characters including uppercase, lowercase, numeric, and special characters"
      );
      return;
    }

    // Perform registration logic here using the form data
    console.log("Registering:", {
      password,
      confirmPassword,
    });
  };

  return (
    <View style={styles.container}>
      <LoginLayout paddingHorizontal={20}>
        <KeyboardAvoidingView behavior="position">
          <Image
            source={require("../assets/maya.png")}
            style={styles.image}
            resizeMode="center"
          />

          <MayaTitle style={styles.welcome}>Reset Password</MayaTitle>

          <MayaText style={styles.subtitle}>
            This is a small explanatory text that contains some information
            about the app.
          </MayaText>

          <CustomTextInput
            value={password}
            setValue={setPassword}
            placeholder="Password"
            isPassword={true}
            showError={!passwordsMatch || !isStrongPassword}
            errorMessage={
              !passwordsMatch
                ? "Passwords do not match"
                : !isStrongPassword
                ? "Password should contain at least 8 characters including uppercase, lowercase, numeric, and special characters"
                : ""
            }
          />

          <CustomTextInput
            value={confirmPassword}
            setValue={setConfirmPassword}
            placeholder="Confirm Password"
            isPassword={true}
            showError={!passwordsMatch}
            errorMessage="Passwords do not match"
          />

          {password.length > 0 && !isStrongPassword && (
            <MayaText style={styles.errorMessage}>
              Password should contain at least 8 characters including uppercase,
              lowercase, numeric, and special characters
            </MayaText>
          )}
        </KeyboardAvoidingView>

        <View style={{ marginTop: 40 }}>
          <ButtonWide text="Send" onPress={navigateToLogin} />
        </View>
      </LoginLayout>
    </View>
  );
};

export default ResetPasswordHOC;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.background,
  },
  image: {
    alignSelf: "center",
    width: 124,
    height: 137,
  },
  welcome: {
    marginBottom: 40,
    marginTop: 10,
  },
  subtitle: {
    textAlign: "center",
    marginBottom: 40,
  },
  errorMessage: {
    color: "red",
    fontSize: 12,
    marginBottom: 20,
  },
});
