export const jsonData = {
  nodeKeyProperty: "id",
  metaData: {
    intentID: "WRITE_AN_OPINION",
    entry_step: "N1",
  },
  nodeDataArray: [
    {
      id: "N1",
      response_message:
        "Ok {username}, what specific topic would you like my opinion on?",
      showType: "BUBBLE_INPUT",
      header: "",
    },

    {
      id: "N2",
      response_message: "Whose mindset should I adopt?",
      showType: "BUBBLE_INPUT",
      header: "",
    },

    {
      id: "N3",
      response_message:
        "Hang tight, it might take a few moments, but I'm on it : )",
      showType: "BUBBLE_INFORMATIONAL",
      header: "",
    },
  ],
  linkDataArray: [
    { from: "N1", to: "N2", return_value: "any", return_type: "TEXT" },

    {
      from: "N2",
      to: "N3",
      return_value: "test",
      return_type: "OPTION",
      text: "edge",
    },
  ],
};

export const optionData = {
  DAG: {
    id: "write_an_opinion",
    nodes_consumable: [
      {
        id: "start",
        show_type: "MESSAGE",
        response_message: null,
        answers: null,
      },
      {
        id: "ask_topic",
        show_type: "GETINPUT",
        response_message:
          "Ok {username}, what specific topic would you like my opinion on?",
        answers: [{ answer: { show: "any", type: "text" } }],
      },
      {
        id: "ask_mindset",
        show_type: "GETINPUT",
        response_message: "Whose mindset should I adopt?",
        answers: [{ answer: { show: "any", type: "text" } }],
      },
      {
        id: "end",
        show_type: "MESSAGE",
        response_message: null,
        answers: null,
      },
    ],
    edges_consumable: [
      {
        from_node: "start",
        to_node: "ask_topic",
        user_message: null,
        return_type: "default",
        return_value: null,
      },
      {
        from_node: "ask_topic",
        to_node: "ask_mindset",
        user_message: null,
        return_type: "default",
        return_value: null,
      },
      {
        from_node: "ask_mindset",
        to_node: "end",
        user_message: null,
        return_type: "default",
        return_value: null,
      },
    ],
  },
};
