import { optionData } from "./json-data";
import { createNode } from "./node.services";
// import { PubSubEvents, pubSubChat } from "./pub-sub.service";
import { Node } from "./node.services";
import { ExeFactory } from "./execution-factory.service";

let service: ExeFactory | null = null;
export function startDAGExecService() {

  service = new ExeFactory();
  service.start_service();

}

export function getDAGExecService() {
  if (service === null) {
    throw new Error("Service is not started");
  }
  return service.get_service();
}

class AIPlatformDAG2 {
  TAG: string;
  _head: Node | null;
  _intentID: string | null;
  _nodeObjMap: { [key: string]: Node };
  _nodeExtObjMapper: { [key: string]: any };

  constructor() {
    this.TAG = "DAG";
    this._head = null;
    this._intentID = null;
    this._nodeObjMap = {};
    this._nodeExtObjMapper = {};
  }

  get nodeObjMap() {
    return this._nodeObjMap;
  }

  set nodeObjMap(nodeObjMap: { [key: string]: Node }) {
    this._nodeObjMap = nodeObjMap;
  }

  get nodeExtObjMapper() {
    return this._nodeExtObjMapper;
  }

  set nodeExtObjMapper(nodeExtObjMapper: { [key: string]: any }) {
    this._nodeExtObjMapper = nodeExtObjMapper;
  }

  get head() {
    return this._head;
  }

  set head(_head: Node | null) {
    this._head = _head;
  }


  build(graphName: string, dagJsonObj: any) {
    try {
      this._intentID = graphName;

      const nodesList = dagJsonObj["DAG"]["nodes_consumable"];
      const edgesList = dagJsonObj["DAG"]["edges_consumable"];

      this.nodeObjMap = {};
      this.nodeExtObjMapper = {};


      for (const nodeData of nodesList) {

        const nodeId = nodeData["id"];
        const showType = nodeData["show_type"];
        const responseMessage = nodeData["response_message"];
        const answers = nodeData["answers"];
        const answersList: { [key: string]: string } = {};

        if (answers) {
          for (const answer of answers) {
            if (answer["answer"]["type"] === "text") {

              answersList[answer["answer"]["show"]] = answer["answer"]["type"];

            } else if (answer["answer"]["type"] === "int") {
              // validate and add?
            }
          }
        }

        const node = createNode(
          showType,
          responseMessage,
          answers,
          answersList,
          nodeId
        );

        if (nodeId === "start") {
          this.head = node;

        }

        this.nodeObjMap[nodeId] = node;

      }

      for (const edgeData of edgesList) {

        try {

          const fromNode = edgeData["from_node"];
          const toNode = edgeData["to_node"];
          const returnType = edgeData["return_type"];
          const returnValue = edgeData["return_value"];
          const userMessage = edgeData["user_message"];

          const fromNodeObj = this.nodeObjMap[fromNode];
          const toNodeObj = this.nodeObjMap[toNode];

          if (returnType === "string") {
            fromNodeObj.addEdge(toNodeObj, returnValue);
          } else if (returnType === "boolean") {
            fromNodeObj.addEdge(toNodeObj, Boolean(returnValue));
          } else if (returnType === "integer") {
            fromNodeObj.addEdge(toNodeObj, Number(returnValue));
          } else if (returnType === "default" || returnType === "any") {
            fromNodeObj.addEdge(toNodeObj, "any");
          }


        } catch (e) {

          // handle exception
          console.error(e);

        }
      }

      if (false) {
        // replace with your debug condition
        for (const [nodeId, nodeObj] of Object.entries(this.nodeObjMap)) {

          console.log(
            "Build nodeId and NodeObj",
            JSON.stringify(nodeId),
            JSON.stringify(nodeObj)

          );
        }
      }
    } catch (e) {
      console.error(e);
    }
  }

  execute() {
    if (this._head !== null) {

      return this._head.execute();
    }
    return null;
  }
}

export async function startService() {
  // # Example usage:
  startDAGExecService();
  const json_data = optionData;
  const dag = new AIPlatformDAG2();
  const name = json_data["DAG"]["id"];
  dag.build(name, json_data);
  const executable = dag.execute();
}
