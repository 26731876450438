import { doingWorkAgent } from "../api/doing-work/doing-work";
import { IDoingWork } from "../models/doingWotrk.model";
import { startService } from "./dag.services";
import { jsonData } from "./json-data";
import { PubSubEvents, pubSubChat } from "./pub-sub.service";

export const getTheDoingWorkWithOption = async (
  optionFlow: string
): Promise<IDoingWork | null> => {
  try {
    pubSubChat.on(PubSubEvents.USER_SELECT_WORKFLOW, () => jsonData);
    await startService();
    const workFlow = await doingWorkAgent.get(optionFlow);
    return workFlow;
  } catch (e) {
    return null;
  }
};
