// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import React, { useEffect, useState } from "react";
import { FlatList, Image, Pressable, StyleSheet, View } from "react-native";

// Helpers & Constants
import { themeType } from "../../helpers/constants";
import { colors } from "../../helpers/colors";
import { WorkFolder, Works } from "../../reducers/worksSlice";

// Components
import WorkListItem from "./workListItem";
import MayaText from "../typography/MayaText";
import {
  useIsFocused,
  useNavigation,
  useRoute,
} from "@react-navigation/native";
import { RouteNames } from "../../navigation/routeNames";

type Props = {
  data: Works[] | WorkFolder[];
  onAddPress?: () => void;
  onItemPress?: (id: string) => void;
};

const WorksList = ({ data, onAddPress, onItemPress }: Props) => {
  return (
    <>
      <FlatList
        data={data}
        keyExtractor={(item) => item.id}
        ListFooterComponent={() => <View style={{ height: 100 }} />}
        renderItem={({ item }) => {
          return (
            <WorkListItem
              image={themeType[item?.type]?.icon || ""}
              type={themeType[item?.type]?.title || ""}
              title={item?.title || ""}
              onPress={() => onItemPress && onItemPress(item?.id)}
            />
          );
        }}
        ListEmptyComponent={() => (
          <View style={{ flex: 1, alignItems: "center", paddingVertical: 60 }}>
            <MayaText style={{ fontWeight: "500", fontSize: 16 }}>
              No Items found
            </MayaText>
          </View>
        )}
      />

      {onAddPress && (
        <Pressable style={styles.plusButton} onPress={onAddPress}>
          <Image
            source={require("../../assets/plusCircle.png")}
            style={{ width: 60, height: 60, alignSelf: "flex-end" }}
            resizeMode="contain"
          />
        </Pressable>
      )}
    </>
  );
};

export default WorksList;

const styles = StyleSheet.create({
  plusButton: {
    marginBottom: 20,
    width: 60,
    height: 60,
    alignSelf: "flex-end",
  },
});
