import { requests } from "../agent";
import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  AuthJwtModel,
  AuthModel,
  RegisterInputModel,
} from "../../models/auth.model";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { STORAGE_KEYS } from "../../helpers/constants";
import { jwtDecode } from "jwt-decode";
import { decode } from "base-64";

import { userAgent } from "../user/user";
import { sentryErrorService } from "../../services/sentry-error.service";

global.atob = decode;

const authAgent = {
  login: (email: string, password: string) =>
    requests.post<AuthModel>("/auth/login", { email, password }),
  register: (registerInput: RegisterInputModel) =>
    requests.post<AuthModel>("/auth/register", registerInput),
  submitToken: (registrationToken: string) =>
    requests.post<AuthModel>("/auth/submit_token", {
      registration_token: registrationToken,
    }),
};

// INFO here we add the  createAsyncThunk for Redux to use the API

export const loginAsyncThunk = createAsyncThunk(
  "auth/login",
  async (data: { email: string; password: string }, { rejectWithValue }) => {
    try {
      const response = await authAgent.login(data.email, data.password);
      if (!response || response?.error) {
        return rejectWithValue(response);
      }
      await storeData(response?.access_token);
      await userAgent.getUser();
      return response;
    } catch (error) {
      sentryErrorService(error);
      return rejectWithValue(error);
    }
  },
);

export const loginAsyncThunkLogout = createAsyncThunk(
  "auth/logout",
  async () => {
    await AsyncStorage.removeItem(STORAGE_KEYS.TOKEN);
    return null;
  },
);

export const registerAsyncThunk = createAsyncThunk(
  "auth/register",
  async (data: { registerInput: RegisterInputModel }) => {
    const response = await authAgent.register(data.registerInput);
    return response;
  },
);

// INFO Submit Token from register api to login in project
export const submitTokenAsyncThunk = createAsyncThunk(
  "auth/submitToken",
  async (data: { registrationToken: string }, { rejectWithValue }) => {
    try {
      const response = await authAgent.submitToken(data.registrationToken);
      if (!response || response?.error) {
        return rejectWithValue(response);
      }
      await storeData(response?.access_token);
      await userAgent.getUser();

      return response;
    } catch (error) {
      sentryErrorService(error);
      return rejectWithValue(error);
    }
  },
);

const storeData = async (value: string) => {
  try {
    await AsyncStorage.setItem(STORAGE_KEYS.TOKEN, value);
  } catch (e) {
    sentryErrorService(e);
    console.error("Token is not stored");
  }
};

// export const getStorageData = async (key: string) => {
//   try {
//     const value = await AsyncStorage.getItem(key);
//     if (value !== null) {
//       return value;
//     }
//     return "";
//   } catch (e) {
//     // error reading value
//     return "";
//   }
// };

export const decoderJWT = (token: string): AuthJwtModel | null => {
  try {
    console.log("decoderJWT token", token);
    const decoded = jwtDecode(token) as AuthJwtModel;
    console.log("decoderJWT", decoded);
    return decoded;
  } catch (error) {
    sentryErrorService(error);
    console.error("decoderJWT", error);
    return null;
  }
};
