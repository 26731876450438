import React, { useEffect, useRef, useState } from "react";
import { Alert, Platform, StyleSheet, Text, TextInput } from "react-native";
import { colors } from "../../helpers/colors";
import { formatWithMask } from "react-native-mask-input";
import { checkIsTextHasRegex } from "../../helpers/utils";

export type InputIpFormatterProps = {
  placeholder: string;
  handleSaveUrl: (text: string) => void;
  clearValues?: boolean;
};
const MASK_IP_DEFAULT = [
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  ".",
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  ".",
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  ".",
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
];

export const InputIpFormatter = ({
  placeholder,
  handleSaveUrl,
  clearValues,
}: InputIpFormatterProps) => {
  const [ip, setIp] = useState(null);
  const ref = useRef(null);
  const [userEnterDot, setUserEnterDot] = useState(false);
  const isValidIpAddress = (ip: string): boolean => {
    const ipAddressRegex = /^(?:[0-9]{1,3}\.){3}[0-9]{1,3}$/;
    return ipAddressRegex.test(ip);
  };
  const onChangeText = (ev: string) => {
    if (isValidIpAddress(ev)) {
      ref.current = ev;
      handleSaveUrl(ev);
      setIp(ev);
      return;
    }
    if (checkIsTextHasRegex(ev)) {
      setIp(ev);
      ref.current = ev;
      setUserEnterDot(false);
      handleSaveUrl(ev);
      return;
    }
    const textSliptByDot = ev.split(".");
    if (textSliptByDot.length > 4) {
      return;
    }
    setUserEnterDot(false);
    const lastChar = ev.charAt(ev.length - 1);
    if (lastChar === "." || userEnterDot) {
      setIp(ev);
      ref.current = ev;
      setUserEnterDot(true);
      handleSaveUrl(ev);
      return;
    }
    const { masked, unmasked, obfuscated } = formatWithMask({
      text: ev,
      mask: MASK_IP_DEFAULT,
    });
    setIp(masked);
    ref.current = masked;
    handleSaveUrl(masked);
  };

  useEffect(() => {
    if (clearValues) {
      setIp("");
      ref.current = "";
    }
  }, [clearValues]);

  return (
    <TextInput
      onChangeText={onChangeText}
      placeholder={placeholder}
      style={styles.input}
      value={ip}
      placeholderTextColor={"white"}
    />
  );
};

const styles = StyleSheet.create({
  input: {
    backgroundColor: colors.background2,
    borderRadius: 30,
    padding: Platform.OS === "web" ? 10 : 5,
    borderColor: colors.borderColor,
    borderWidth: 1,
    color: "#fff",
    fontSize: 18,
    maxWidth: 200,
    width: "100%",
  },
});

export default InputIpFormatter;
