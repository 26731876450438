import React, { useContext, useEffect, useState } from "react";
import { createMaterialTopTabNavigator } from "@react-navigation/material-top-tabs";
import { useNavigation } from "@react-navigation/native";
import { Image, Platform, Pressable, StyleSheet, View ,Text} from "react-native";

import { RouteNames } from "../../navigation/routeNames";
import { Registration } from "./Register";
import { Payment } from "./Payment";
import { Plans } from "./Plans";
import { Activate } from "./Activate";
import { colors } from "../../helpers/colors";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import Layout from "../Layout/Layout";
import { useIsFocused } from "@react-navigation/native";
import { UserContext, UserProvider } from "./RegistrationContext";
import HomeHOC from "../../containers/HomeHOC";
import PromptHOC from "../../containers/PromptHOC";
import BackIcon from "../../icons/arrowLeft";



const Tab = createMaterialTopTabNavigator();

const TopTabBar = ({
  state,
  descriptors,
  navigation,
  changedIcon,
  changedIconPay,
  changedIconRegister,
  changedIconActivate,
}) => {
  const [completedTabs, setCompletedTabs] = useState([]);
  const [activeTab, setActiveTab] = useState(RouteNames.Register);

  // const onPressTab = (route) => {
  //   setActiveTab(route.name);
  //   navigation.navigate(route.name);
  // };


  const onTabCompletion = (tabName) => {
    setCompletedTabs((prevTabs) => [...prevTabs, tabName]);
  };

  const icons = {
    [RouteNames.Register]: {
      active: require("../../assets/focusedUser.png"),
      inactive: require("../../assets/inactiveUser.png"),
      focused: require("../../assets/focusedUser.png"),
    },
    [RouteNames.Plan]: {
      active: require("../../assets/plansIconActive.png"),
      inactive: require("../../assets/registration-plans.png"),
      focused: require("../../assets/plansIconActive.png"),
    },
    [RouteNames.Pay]: {
      active: require("../../assets/payIconActive.png"),
      inactive: require("../../assets/registration-payment.png"),
      focused: require("../../assets/payIconActive.png"),
    },
    [RouteNames.Activation]: {
      active: require("../../assets/activateIconActive.png"),
      inactive: require("../../assets/registration-activate.png"),
      focused: require("../../assets/activateIconActive.png"),
    },
  };

  useEffect(() => {
    const unsubscribeFocus = navigation.addListener("focus", () => {
      const currentRoute = descriptors[state.routes[state.index].key];
      setActiveTab(currentRoute.route.name);
    });

    const initialRoute = descriptors[state.routes[state.index].key];
    setActiveTab(initialRoute.route.name);

    return unsubscribeFocus;
  }, [navigation, state, descriptors]);

  return (
    <View
      style={{
        flexDirection: "row",
        justifyContent: "space-around",
        alignItems: "center",
        backgroundColor: colors.background,
        height: Platform.OS === 'web' ? 154 : 94,
        paddingBottom: 6,
      }}
    >
<View style={{top:10}}>

</View>
<Pressable
        onPress={() => navigation?.goBack()}
        style={{ paddingLeft: Platform.OS!='web'?8:20,top:Platform.OS!='web'?4:20,flex:1,alignContent:'flex-start',alignItems:'flex-start',alignSelf:'flex-start'}}
      >
        {/* Back button icon  here */}
        <BackIcon />
              </Pressable>
      {state.routes.map((route, index) => {
        const isCompleted = completedTabs.includes(route.name);
        const isFocused = route.name === activeTab;

        // Modify the condition to check if the tab should be hidden
        if (
          (route.name === RouteNames.Plan && !changedIcon) ||
          (route.name === RouteNames.Pay && !changedIconPay)||
          (route.name === RouteNames.Register && !changedIconRegister)||
          (route.name === RouteNames.Activation && !changedIconActivate)
        ) {
          return null; // Do not render the tab
        }

        return (
          <Pressable
            key={index}
            // onPress={() => onPressTab(route)}

            style={{
              flex: route.name === RouteNames.Register || route.name === RouteNames.Activation ? 2 : 1, // Adjust the flex based on the tab name
              alignItems: "center",
            }}
          >
            {changedIconRegister && route.name === RouteNames.Register && (
              <Image
                source={require("../../assets/checkedRegistrationIcon.png")}
                style={styles.checkedImage}
              />
            )}
            {changedIcon && route.name === RouteNames.Plan && (
              <Image
                source={require("../../assets/checkedRegistrationIcon.png")}
                style={styles.checkedImage}
              />
            )}
            {changedIconPay && route.name === RouteNames.Pay && (
              <Image
                source={require("../../assets/checkedRegistrationIcon.png")}
                style={styles.checkedImage}
              />
            )}
            {changedIconActivate && route.name === RouteNames.Activation && (
              <Image
                source={require("../../assets/checkedRegistrationIcon.png")}
                style={styles.checkedImage}
              />
            )}

            <View style={styles.imageContainer}>
              <Image
                source={
                  isFocused || changedIconPay
                    ? icons[route.name].focused
                    : isCompleted
                    ? icons[route.name].active
                    : icons[route.name].inactive
                }
                style={styles.image}
              />
            </View>
          </Pressable>
        );
      })}
    </View>
  );
};

const TopTabNavigator = () => {
  const navigation = useNavigation<NativeStackNavigationProp<any>>();

  const [changedIconRegister, setChangedIconRegister] = useState(false);
  const [changedIcon, setChangedIcon] = useState(false);
  const [changedIconPay, setChangedIconPay] = useState(false);
  const [changedIconActivate, setChangedIconActivate] = useState(false);
  const { userData } = useContext(UserContext);

  const receiveDataFromChild = (data) => {
    setChangedIcon(data);
    console.log("Received data in TopTabNavigator:", data);
  };

  const receiveDataFromChildPay = (data) => {
    setChangedIconPay(data);
    //console.log("Received data in TopTabNavigator:", data);
  };
  const receiveDataFromChildRegistration = (data) => {
    setChangedIconRegister(data);
    //console.log("Received data in TopTabNavigator:", data);
  };
  const receiveDataFromChildActivation = (data) => {
    setChangedIconActivate(data);
    //console.log("Received data in TopTabNavigator:", data);
  };
  const [activatedScreen, setActivatedScreen] = useState(false);
  const handelActiveTab = () => {
    setActivatedScreen(true);
  };
  return (
    <UserProvider>
      <Tab.Navigator
        initialRouteName={RouteNames.Register}
        tabBar={(props) => (
          <TopTabBar
            {...props}
            navigation={navigation}
            changedIcon={changedIcon}
            changedIconPay={changedIconPay}
            changedIconRegister={changedIconRegister}
            changedIconActivate={changedIconActivate}
          />
        )}
        screenOptions={{
          tabBarStyle: { backgroundColor: colors.background },
          tabBarActiveTintColor: colors.background,animationEnabled: true

        }}
        style={{ backgroundColor: colors.background }}
      >
        <Tab.Screen
          name={RouteNames.Register}
          options={{
            tabBarLabel: "Register",animationEnabled: true,swipeEnabled:false

          }}
        >
          {() => (
            <Registration
              setActive={handelActiveTab}
              sendDataRegistration={receiveDataFromChildRegistration}
            />
          )}
        </Tab.Screen>

        <Tab.Screen
          name={RouteNames.Plan}
          options={{
            tabBarLabel: "Plans",
          }}
          children={() => <Plans sendDataplans={receiveDataFromChild} />}
        />
        <Tab.Screen
          name={RouteNames.Pay}
          options={{
            tabBarLabel: "Pay",
          }}
        >
          {() => <Payment sendDataPayments={receiveDataFromChildPay} />}
        </Tab.Screen>
        <Tab.Screen
          name={RouteNames.Activation}
          options={{
            tabBarLabel: "Activation",animationEnabled: false,swipeEnabled:false


          }}
          children={() => (
            <Activate sendDataActivation={receiveDataFromChildActivation} />
          )}
        />  

      </Tab.Navigator>
    </UserProvider>
  );
};

export default TopTabNavigator;

const styles = StyleSheet.create({
  imageContainer: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
  },
  image: {
    width: Platform.OS !== "web" ? 62 : 102,
    height: Platform.OS !== "web" ? 72 : 122,
    top: 14,
    marginHorizontal: Platform.OS !== "web" ? 14 : 20,
    position: "relative",
  },
  checkedImage: {
    width: Platform.OS !== "web" ? 22 : 32,
    height: Platform.OS !== "web" ? 22 : 32,
    marginHorizontal: Platform.OS !== "web" ? 14 : 40,
    position: "absolute",
    zIndex: 1,
  },

});