import { combineReducers } from "redux";

//reducers
import authReducer from "../reducers/authSlice";
import worksReducer from "../reducers/worksSlice";
import generalReducer from "../reducers/generalSlice";
import insightsReducer from "../reducers/insightSlice";
import urlInputReducer from "../reducers/urlInputSlice";
import trainingReducer from "../reducers/trainingSlice";
import dimentionReducer from "../reducers/dimentionSlice";
import recognitionReducer from "../reducers/recognitionSlice";
import chatReducer from "../reducers/chatSlice";
import notificationReducer from "../reducers/notificationSlice";
import userReducer from "../reducers/userSlice";
const root = combineReducers({
  generalReducer,
  notificationReducer,
  worksReducer,
  insightsReducer,
  urlInputReducer,
  trainingReducer,
  dimentionReducer,
  recognitionReducer,
  userReducer,
});

const persist = combineReducers({
  authReducer,
  chatReducer,
});

const reducers = combineReducers<any>({
  root,
  persist,
});

export default reducers;
