import React, { useState } from "react";
import { Image, Pressable, StyleSheet, View } from "react-native";

// Types
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import { ParamListBase } from "@react-navigation/routers";

// Constants
import { colors } from "../helpers/colors";

// Components
import MayaText from "../components/typography/MayaText";
import MayaTitle from "../components/typography/MayaTitle";
import MenuModal from "../components/general/menuModal";
import MenuContent from "../components/general/MenuContent";

type Props = {
  title?: string;
  back?: boolean;
  navigation?: NativeStackNavigationProp<ParamListBase>;
  showMenu?: boolean;
};

const NavigationHeader: React.FC = ({
  title,
  back,
  navigation,
  showMenu,
}: Props) => {
  const [isVisible, setIsVisible] = useState(false);

  const closeModal = () => {
    setIsVisible(false);
  };

  return (
    <View style={styles.container}>
      <Pressable onPress={() => navigation?.goBack()}>
        {back && <MayaTitle>X</MayaTitle>}
      </Pressable>

      {title && (
        <MayaText style={{ fontSize: 20, fontWeight: "700", color: "white" }}>
          {title}
        </MayaText>
      )}

      <Pressable onPress={() => showMenu && setIsVisible(true)}>
        {showMenu && (
          <Image
            source={require("../assets/menuBurger.png")}
            style={{ width: 45, height: 45 }}
          />
        )}
      </Pressable>

      <MenuModal isVisible={isVisible} closeModal={closeModal}>
        <MenuContent closeModal={closeModal} />
      </MenuModal>
    </View>
  );
};

export default NavigationHeader;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.background,
    paddingVertical: 20,
    paddingHorizontal: 20,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    borderBottomColor: "#FFFFFF33",
    borderBottomWidth: 1,
  },
  modalContainer: {
    backgroundColor: "#000000",
    flex: 0.7,
    paddingHorizontal: 40,
    paddingVertical: 10,
  },
  menuWrapper: {
    width: 70,
    height: 3,
    backgroundColor: "#fff",
    alignSelf: "center",
    borderRadius: 50,
    marginBottom: 20,
  },
  menuItemWrapper: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 20,
  },
});
