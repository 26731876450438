export enum EChatHeightOnOpenActionBanner {
  IOS_OPEN_SPEECH = 350,
  ANDROID_OPEN_SPEECH = 350,
  WEB_OPEN_SPEECH = 450,
  IOS_CLOSE_SPEECH = 200,
  ANDROID_CLOSE_SPEECH = 280,
  WEB_CLOSE_SPEECH = 350,
  OPEN_WORK_FLOW = 280,
  CLOSE_WORK_FLOW = 350,
}
