import React from "react";
import {
  Image,
  ImageProps,
  ImageSourcePropType,
  Pressable,
  StyleSheet,
  View,
} from "react-native";

// Constants
import { colors } from "../../helpers/colors";

// Components
import MayaText from "../typography/MayaText";

// Icons
import ArrowRightIcon from "../../icons/arrowRight";

type Props = {
  image?: ImageSourcePropType;
  type?: string;
  title: string;
  onPress?: () => void;
};

const WorkListItem = ({ image, type, title, onPress }: Props) => {
  return (
    <Pressable style={styles.container} onPress={onPress}>
      <View style={styles.wrapper}>
        {image && (
          <Image source={image} style={styles.image} resizeMode="contain" />
        )}

        <View style={{ paddingVertical: 5 }}>
          {type && (
            <MayaText style={styles.typeText}>{type.toUpperCase()}</MayaText>
          )}

          <MayaText style={styles.titleText}>{title}</MayaText>
        </View>
      </View>

      <ArrowRightIcon />
    </Pressable>
  );
};

export default WorkListItem;

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.background2,
    marginBottom: 10,
    paddingVertical: 15,
    paddingHorizontal: 20,
    borderRadius: 50,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  image: {
    width: 38,
    height: 38,
    marginRight: 5,
  },
  typeText: {
    fontSize: 10,
    fontWeight: "700",
  },
  titleText: {
    fontWeight: "700",
    fontSize: 16,
  },
  wrapper: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
});
