import { APICallExe } from "./api-exec.service";
import { AskClientExec } from "./ask-client-exec.service";
import { HiddenNode } from "./hidden-node-exec.service";
import { ShowResultsExec } from "./show-result-exec.service";
import { ValidateLocationExec } from "./validation-exec.service";
import { IExecutable } from "./executable.interface";

// export abstract class IExecutable {
//   abstract execute(): any;
// }

export enum EXECUTABLE_TYPE {
  MESSAGE = "MESSAGE",
  GET_INPUT = "GETINPUT",
  DYNAMIC_MESSAGE = "DYNAMICMESSAGE",
  DYNAMIC_ASK = "DYNAMICASK",
  LISTENING_NODE = "LISTENINGNODE",
  CALL_API = "CALL_API",
  VALIDATE = "VALIDATE",
  HIDDEN = "HIDDEN",
}

export class ExeFactory {
  create_executable(type: string, response_message: string, answers: any) {
    let exec_instance: IExecutable | null = null;

    if (type !== null) {
      if (type === EXECUTABLE_TYPE.VALIDATE) {
        exec_instance = new ValidateLocationExec();
      } else if (type === EXECUTABLE_TYPE.GET_INPUT) {
        exec_instance = new AskClientExec(response_message, answers);
      } else if (type === EXECUTABLE_TYPE.MESSAGE) {
        exec_instance = new ShowResultsExec(response_message, answers);
      } else if (type === EXECUTABLE_TYPE.CALL_API) {
        exec_instance = new APICallExe(response_message);
      } else if (type === EXECUTABLE_TYPE.HIDDEN) {
        exec_instance = new HiddenNode(response_message);
      }
    }

    return exec_instance;
  }

  start_service() {
    // BaseMayaService.logger.info(`Starting ${this.service_name}....`);
    // console.log("Starting service");
  }

  kill_service() { }

  get_service() {
    // console.log('get_service', this);

    return this;
  }
}
