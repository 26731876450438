// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import React, { useEffect } from "react";
import { StyleSheet, View } from "react-native";
import { useNavigation, useRoute } from "@react-navigation/native";

// Constants
import { colors } from "../../../helpers/colors";

// Components
import WorksList from "../WorksList";
import { useSelector } from "react-redux";
import { RootState } from "../../../../types";
import MayaText from "../../typography/MayaText";

type Nav = {
  id: string;
};

const FolderScreen: React.FC = () => {
  const route = useRoute();
  const navigation = useNavigation();
  const { works, workFolders } = useSelector(
    (state: RootState) => state.root.worksReducer
  );
  const { id } = route.params as Nav;

  useEffect(() => {
    navigation.setOptions({
      title: workFolders[id].title,
    });
  }, [route, navigation]);

  const denormalizedWorks = works.allIds.map((id) => works[id]);

  const handleOnItemPress = (id: string) => {};

  return (
    <View style={styles.container}>
      <WorksList
        data={denormalizedWorks}
        // onAddPress={handleNewFolder}
        onItemPress={handleOnItemPress}
      />
    </View>
  );
};

export default FolderScreen;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.background,
    justifyContent: "space-between",
    padding: 10,
  },
  input: {
    backgroundColor: colors.background2,
    borderRadius: 30,
    padding: 10,
    paddingHorizontal: 20,
    margin: 20,
    borderColor: colors.borderColor,
    borderWidth: 1,
    color: "#fff",
    fontSize: 18,
  },
  buttonWrapper: {
    padding: 10,
    borderTopColor: colors.borderColor,
    borderTopWidth: 1,
  },
});
