import { createSlice } from '@reduxjs/toolkit';

export type Works = {
  id: number;
  title: string;
  type: string;
}

export type NormalizedWorks = {
  [id: string]: Works | string[];
}

export type WorkFolder = {
  id: number;
  title: string;
}

export type NormalizedWorkFolders = {
  [id: string]: WorkFolder | string[];
}

export type WorksState = {
  works: NormalizedWorks
  workFolders: NormalizedWorkFolders
}

const initialState: WorksState = {
  works: { allIds: [] },
  workFolders: { allIds: [] }
};

const worksSlice = createSlice({
  name: 'general',
  initialState,
  reducers: {
    setWorks: (state, { payload }) => {
      payload.map((item: any) => {
        state.works = {
          ...state.works,
          [item.id]: {
            id: item.id,
            title: item.title,
            type: item.type
          },
          //@ts-ignore
          allIds: state.works[item.id] ? [...state.works.allIds] : [...state.works.allIds, item.id]
        }
      })
    },
    setNewWorkFolders: (state, { payload }) => {
      state.workFolders = {
        ...state.workFolders,
        [payload.id]: {
          id: payload.id,
          title: payload.title
        },
        //@ts-ignore
        allIds: [...state.workFolders.allIds, payload.id]
      }

      // state.workFolders = { allIds: [] }
    },
  },
});

export const { setWorks, setNewWorkFolders } = worksSlice.actions;

export default worksSlice.reducer;
