import {
  EXECUTABLE_TYPE,
  // ExeFactory,
} from "./execution-factory.service";
import { IExecutable } from "./executable.interface";

export class AskClientExec extends IExecutable {
  response_message: string;
  answers: any;
  node_id: string;
  userInputCallback: ((input: any) => void) | null = null;


  constructor(response_message: string, answers: any) {
    super();

    this.response_message = response_message;
    this.answers = answers;
    this.node_id = EXECUTABLE_TYPE.GET_INPUT;

    // console.log('ask client', response_message, answers);
    // this.execute()
  }

  setUserInputCallback(callback: (input: any) => void) {
    this.userInputCallback = callback;
  }

  /* TODO: i need to stop and response this to the user chat
   * 1. i need to stop and response this to the user chat
   * 2. in the return what the user is saying
   */
  execute() {

    // console.log('HExecute Ask client', this.response_message);


    if (this.response_message) {


      if (this.answers === null) {
        return this.response_message;
      } else {
        return this.response_message;
      }
    }
  }
}
