import React from "react";
import { StyleSheet, TouchableOpacity, View } from "react-native";

// Components
import MayaText from "../typography/MayaText";

// Icons
import ArrowIcon from "../../icons/arrowRight";

type Props = {
  label: string;
  value: string | undefined;
  onPress?: () => void;
  enableArrow?: boolean;
};

const ProfileField = ({ label, value, onPress, enableArrow = true }: Props) => {
  return (
    <TouchableOpacity style={styles.container} onPress={onPress}>
      <MayaText style={styles.title}>{label}</MayaText>

      <View style={styles.wrapper}>
        <MayaText style={styles.value}>{value}</MayaText>

        {enableArrow && <ArrowIcon />}
      </View>
    </TouchableOpacity>
  );
};

export default ProfileField;

const styles = StyleSheet.create({
  container: {
    borderBottomColor: "#9F9F9F",
    borderBottomWidth: 1,
    paddingBottom: 5,
    marginBottom: 15,
  },
  title: {
    fontWeight: "500",
    fontSize: 12,
    marginBottom: 6,
  },
  wrapper: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  value: {
    fontWeight: "500",
    fontSize: 16,
    marginBottom: 6,
    color: "#9F9F9F",
  },
});
