import React, { useContext, useEffect, useState } from "react";
import {
  Keyboard,
  KeyboardAvoidingView,
  Platform,
  ScrollView,
  StyleSheet,
  Text,
  TextInput,
  TouchableOpacity,
  TouchableWithoutFeedback,
} from "react-native";
import MayaTitle from "../typography/MayaTitle";
import { RouteNames } from "../../navigation/routeNames";
import { useNavigation } from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import { View } from "react-native";
import { colors } from "../../helpers/colors";
import { UserContext } from "./RegistrationContext";
import ButtonWide from "../general/ButtonWide";

export const Registration = ({ sendDataRegistration, setActive }) => {
  const [changedIcon, setChangedIcon] = useState(true);
  const [isActive, setIsActive] = useState(false);

  const { userData } = useContext(UserContext);

  const sendData = (bool) => {
    sendDataRegistration(bool);
  };
  const [fullName, setFullName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [fullNameErrors, setFullNameErrors] = useState("");
  const [fullNameError, setFullNameError] = useState(false);

  const [company, setCompany] = useState("");
  const [companyError, setCompanyError] = useState(null);

  const [phone, setPhone] = useState("");
  const [phoneError, setPhoneError] = useState(null);

  const [email, setEmail] = useState("");
  const [emailErrorType, setEmailErrorType] = useState("");
  const [emailError, setEmailError] = useState(null);
  //const [existingEmail, setExistingEmail] = useState(false);

  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState(null);
  const [passwordErrorMessage, setPasswordErrorMessage] = useState("");
  const [attemptedToContinue, setAttemptedToContinue] = useState(false);

  const navigation = useNavigation<NativeStackNavigationProp<any>>();
  const { saveUserData } = useContext(UserContext);

  const handleSaveUserData = () => {
    if (isActive) {
      saveUserData({
        fullName: fullName,
        firstName: firstName,
        lastName: lastName,
        company: company,
        phone: phone,
        email: email,
        password: password,
      });
    }
  };
  useEffect(() => {
    setActive(true);
    if (userData.email == "409") {
      setChangedIcon(false);
      sendData(false);
      setIsActive(true);
      setEmailError(true);
      setEmailErrorType("Email already exists!");
    }
  }, [userData]);

const handleValidations = () => {
if(!fullNameError&&companyError){

  setIsActive(  false);
}else{
  setIsActive(true)
}
};

  
  
  
const validateFullName = () => {
  const trimmedFullName = fullName.trim();
  const splitName = trimmedFullName.split(" ");
  if (trimmedFullName === "") {
    setFullNameError(true);
    setFullNameErrors("Full name field cannot be empty");
  } else if (splitName.length >= 2) {
    setFirstName(splitName[0]);
    setLastName(splitName.slice(1).join(" "));
    setFullNameError(false);
  } else {
    setFullNameErrors("Please enter both first and last name");
    setFullNameError(true);
  }
  
  // Explicitly set the state to true if there is no error
  if (trimmedFullName !== "" && splitName.length >= 2) {
    setFullNameError(false);
  }

  console.log("validateFullName - fullName:", fullName);
  console.log("validateFullName - fullNameError:", fullNameError);
  handleValidations(); // Trigger validations after each field validation
};

  const checkCompanyError = () => {
    if (company == "") {
      setCompanyError(true);

    } else if (company.length < 2) {
      setCompanyError(true);
      console.log("error");
    }
    else{
      setCompanyError(false)
    }
  };
  //Phone validation
  const formatPhoneNumber = (phoneNumber) => {
    const cleaned = phoneNumber.replace(/\D/g, "");
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{0,4})$/);

    if (match) {
      return `(${match[1]})-${match[2]}${match[3] ? `-${match[3]}` : ""}`;
    }

    return phoneNumber;
  };
  const checkPhoneError = () => {
    let formatted = phone.replace(/\D/g, "");
    if (phone == "") {
      setPhoneError(true);
      //final check for empty fields
    } else if (formatted.length > 14) {
      setPhoneError(true);
      console.log("error");
    } else if (formatted.length < 10) {
      setPhoneError(true);
      console.log("error");
    } else if (formatted.length === 10) {
      setPhoneError(false)
      formatted = formatPhoneNumber(formatted);
      setPhone(formatted);
    }
  };
  //Email validation
  const validateEmail = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (email.length == 0) {
      setEmailError(true);
      //final check for empty fields
      setEmailErrorType("Email most be valid!");
    } else if (!emailRegex.test(email)) {
      setEmailError(true);
      setEmailErrorType("Email most be valid!");
    } else {
      setEmailError(false);
    }
  };

  // Validate password
  const validatePassword = () => {
    const special = /[!@#$%^&*(),.?":{}|<>]/;
    const upper = /[A-Z]/;
    const lower = /[a-z]/;
    const numb = /\d/;

    let errorMessage = "";

    if (password.length < 10) {
      errorMessage = "Password must be at least 10 characters long.";
    } else if (!special.test(password)) {
      errorMessage = "Password must have at least 1 special character.";
    } else if (!numb.test(password)) {
      errorMessage = "Password must have at least 1 number.";
    } else if (!upper.test(password)) {
      errorMessage = "Password must contain at least one upper case.";
    } else if (!lower.test(password)) {
      errorMessage = "Password must contain at least one lower case.";
    } else {
      setPasswordError(true);
    }
    setPasswordError(!!errorMessage);

    setPasswordErrorMessage(errorMessage);
  };


const handleContinue =  () => {
  if (!fullNameError&& !companyError&&company
    !=''&&!phoneError&&phone&&!emailError&&email&&!passwordError&&password) {
    handleSaveUserData();
    sendData(true);
    navigation.navigate(RouteNames.Activation);};

  }
  
  

  return (
Platform.OS!='web'?(<>
 <KeyboardAvoidingView
      style={styles.container}
      behavior={Platform.OS === "ios" ? "padding" : null}
      keyboardVerticalOffset={Platform.OS === "ios" ? 20 : 25}
    >
      <TouchableWithoutFeedback onPress={Keyboard.dismiss}>
        <ScrollView
          contentContainerStyle={{
            flexGrow: 1,
            justifyContent: "center",
            alignItems: "center",
            bottom: 50,
          }}
          keyboardShouldPersistTaps="handled"
        >
          <MayaTitle style={{  }}>
            Tell Me About You
          </MayaTitle>
          <View style={{ alignSelf: "center", top: 0 }}>
            <View style={styles.flexRowCenter}>
              <TextInput
                placeholder="Full Name"
                placeholderTextColor={colors.placeholder}
                value={fullName}
                onChangeText={(text) => setFullName(text)}
                onBlur={validateFullName}
          
                style={
                  !fullNameError
                    ? styles.textinputWrapper
                    : styles.textInputError
                }
              />

              {fullNameError && (
                <View style={{ marginTop: -20, paddingVertical: 5 }}>
                  <Text style={{ color: "#f43636", fontSize: 14 }}>
                    {fullNameErrors}
                  </Text>
                </View>
              )}
              <TextInput
                placeholder="Company"
                value={company}
                onChangeText={setCompany}
                onBlur={checkCompanyError}
           
                placeholderTextColor={colors.placeholder}
                style={
                  !companyError
                    ? styles.textinputWrapper
                    : styles.textInputError
                }
              />
              {companyError && (
                <View style={{ marginTop: -20, paddingVertical: 5 }}>
                  <Text style={{ color: "#f43636", fontSize: 14 }}>
                    Company requires at least two characters
                  </Text>
                </View>
              )}
              <TextInput
                placeholder="Phone"
                onBlur={checkPhoneError}
                maxLength={14}
                placeholderTextColor={colors.placeholder}
                value={phone}
                onChangeText={setPhone}
                keyboardType="numeric"
                style={
                  !phoneError ? styles.textinputWrapper : styles.textInputError
                }
              />
              {phoneError && (
                <View style={{ marginTop: -20, paddingVertical: 5 }}>
                  <Text style={{ color: "#f43636", fontSize: 14 }}>
                    Phone most be valid
                  </Text>
                </View>
              )}
              <TextInput
                placeholder="Email"
                placeholderTextColor={colors.placeholder}
                value={email}
                onChangeText={setEmail}
                onBlur={validateEmail}
                onFocus={() => {
                  setEmailError(false);
                  //setExistingEmail(false);
                }}
                style={[
                  styles.textinputWrapper,

                  emailError && styles.textInputError,
                ]}
              />
              {emailError && (
                <View style={{ marginTop: -20, paddingVertical: 5 }}>
                  <Text style={{ color: "#f43636", fontSize: 14 }}>
                    {emailErrorType}
                  </Text>
                </View>
              )}

              <TextInput
                placeholder="Password"
                placeholderTextColor={colors.placeholder}
                value={password}
                onChangeText={setPassword}
                onBlur={validatePassword}
                onFocus={() => {
                  setPasswordError(false);
                }}
                style={[
                  styles.textinputWrapper,
                  passwordError && styles.textInputError,
                ]}
              />
              {passwordError && (
                <View style={{ marginTop: -20, paddingVertical: 5 }}>
                  <Text style={{ color: "#f43636", fontSize: 14 }}>
                    {passwordErrorMessage}
                  </Text>
                </View>
              )}
                {/* opacity: !isActive ? 0.5 : 1, */}
                <ButtonWide
            onPress={handleContinue}
    
            text="Continue"
            style={{
              height: 46,
              width: 350,
              padding: 10,
              alignSelf: "center",
              opacity: !isActive ? 0.5 : 1,

            }}
       />
            </View>
          </View>
        </ScrollView>
      </TouchableWithoutFeedback>
    </KeyboardAvoidingView>


</>):(<>

  <ScrollView
      contentContainerStyle={{
        flexGrow: 1,
        justifyContent: "center",
        alignItems: "center",
             backgroundColor:colors.background,
      }}
       keyboardShouldPersistTaps="handled"
  showsVerticalScrollIndicator={false}
    >

      <View style={{ alignSelf: "center", bottom:70 }}>
      <MayaTitle style={{ fontSize:24}}>
        Tell Me About You
      </MayaTitle>
        <View style={styles.flexRowCenter}>
          <TextInput
            placeholder="Full Name"
            placeholderTextColor={colors.placeholder}
            value={fullName}
            onChangeText={(text) => setFullName(text)}
            onBlur={validateFullName}
            onFocus={() => {
              setFullNameError(false);
              sendDataRegistration(false);
            }}
            style={
              !fullNameError ? [styles.textinputWrapper] : [styles.textInputError]
            }
          />

          {fullNameError && (
            <View style={{ marginTop: -20, paddingVertical: 15 }}>
              <Text style={{ color: "#f43636" }}>
                {fullNameErrors}
              </Text>
            </View>
          )}
          <TextInput
            placeholder="Company"
            value={company}
            onChangeText={setCompany}
            onBlur={checkCompanyError}
  
            placeholderTextColor={colors.placeholder}
            style={
              !companyError ? [styles.textinputWrapper] : [styles.textInputError]
            }
          />
          {companyError && (
            <View style={{ marginTop: -20, paddingVertical: 15 }}>
              <Text style={{ color: "#f43636" }}>
                Type  at least two characters
              </Text>
            </View>
          )}
          <TextInput
            placeholder="Phone"
            onFocus={() => {
              setPhoneError(false);
            }}
            onBlur={checkPhoneError}
            maxLength={14}
            placeholderTextColor={colors.placeholder}
            value={phone}
            onChangeText={setPhone}
            textContentType='telephoneNumber'
            style={
              !phoneError ? [styles.textinputWrapper] : [styles.textInputError]
            }
          />
          {phoneError && (
            <View style={{ marginTop: -20, paddingVertical: 15 }}>
              <Text style={{ color: "#f43636" }}>
                Phone most be valid
              </Text>
            </View>
          )}
          <TextInput
            placeholder="Email"
            placeholderTextColor={colors.placeholder}
            value={email}
            onChangeText={setEmail}
            onBlur={validateEmail}
            onFocus={() => {
              setEmailError(false);
              //setExistingEmail(false);
            }}
            style={
              !emailError ? [styles.textinputWrapper] : [styles.textInputError]
            }
          />
          {emailError && (
            <View style={{ marginTop: -20, paddingVertical: 15 }}>
              <Text style={{ color: "#f43636" }}>
                {emailErrorType}
              </Text>
            </View>
          )}

          <TextInput
            placeholder="Password"
            placeholderTextColor={colors.placeholder}
            value={password}
            onChangeText={setPassword}
            onBlur={validatePassword}
            onFocus={() => {
              setPasswordError(false);
            }}
            style={
              !passwordError ? [styles.textinputWrapper,{}] : [styles.textInputError]
            }
          />
          {passwordError && (
            <View style={{ marginTop: -20, paddingVertical: 15,width:500}}>
              <Text style={{ color: "#f43636",textAlign:'center' }}>
                {passwordErrorMessage}
              </Text>
            </View>
          )}
  
        </View>
        
      </View>
      <ButtonWide
      onPress={handleContinue}
      
      text="Continue"
      style={{
        height: 46,
        width: 350,
        padding: 10,
        alignSelf: "center",
        opacity: !isActive ? 0.5 : 1,
       bottom:20

                  }}
                />
    </ScrollView>



</>)
  
  );
};
const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.background,
    height: "100%",
    width: "100%",
  },

  mainContainer: {
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
  },



  textinputWrapper: {
    backgroundColor: colors.borderColor,
    paddingHorizontal: 15,
    paddingVertical: Platform.OS === "ios" ? 5 : 0,
    borderRadius: 50,
    borderColor: colors.borderColor,
    borderWidth: 1,
    marginBottom: 20,
    height: 59,
                    padding: 10,
                    width: 350,
    color: colors.placeholder,

  },
  textInputError: {
    backgroundColor: colors.borderColor,
    paddingHorizontal: 15,
    paddingVertical: Platform.OS === "ios" ? 5 : 0,
    borderRadius: 50,
    borderColor: "#f43636",
    width: Platform.OS == "web" ? 400 : 350,
    height: Platform.OS == "web" ? 60 : 59,
    padding: 10,
    borderWidth: Platform.OS == "web" ? 2 : 1,
    marginBottom: 16,

    color: "#f43636",
  },
  flexRowCenter: {
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    top: Platform.OS !== "web" ? 20 : 50,
    width: Platform.OS !== "web" ? 320 : 350,
  },

});