import React, { useState } from "react";
import {
  Image,
  Platform,
  Pressable,
  StyleProp,
  StyleSheet,
  TextInput,
  View,
  ViewStyle,
} from "react-native";

// Components
import MayaText from "../typography/MayaText";

// Icons
import EyeIcon from "../../icons/eye";

// Constants
import { colors } from "../../helpers/colors";

type Props = {
  value: string;
  setValue: (email: string) => void;
  placeholder: string;
  isPassword?: boolean;
  style?: StyleProp<ViewStyle>;
  showError?: boolean;
  errorMessage?: string;
  secureTextEntry?: boolean;
};

const CustomTextInput = ({
  value,
  setValue,
  placeholder,
  isPassword,
  style,
  showError,
  errorMessage,
  secureTextEntry,
}: Props) => {
  const borderStyle = showError ? { borderColor: "red" } : {};
  const [focused, setFocused] = useState(false);
  const [showPassword, setShowPassword] = useState(secureTextEntry);

  return (
    <View style={[styles.textinputWrapper, style, focused && borderStyle]}>
      <View style={styles.flexRowCenter}>
        <TextInput
          secureTextEntry={showPassword}
          placeholder={placeholder}
          placeholderTextColor={"#DBDBDB"}
          value={value}
          onChangeText={setValue}
          style={styles.textInput}
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
        />

        {isPassword && (
          <Pressable onPress={() => setShowPassword(!showPassword)}>
            {showPassword ? (
              <Image
                style={{ width: 34, height: 34 }}
                source={require("../../assets/showPass.png")}
              ></Image>
            ) : (
              <Image
                style={{ width: 34, height: 34 }}
                source={require("../../assets/hidePass.png")}
              ></Image>
            )}
          </Pressable>
        )}
      </View>

      {showError && focused && (
        <MayaText style={styles.errorMessage}>{errorMessage}</MayaText>
      )}
    </View>
  );
};

export default CustomTextInput;

const styles = StyleSheet.create({
  textinputWrapper: {
    backgroundColor: colors.borderColor,
    paddingHorizontal: 15,
    paddingVertical: Platform.OS === "ios" ? 5 : 0,
    borderRadius: 50,
    borderColor: colors.borderColor,
    borderWidth: 1,
    marginBottom: 20,
  },
  errorMessage: {
    color: "red",
    fontSize: 12,
    marginTop: 5,
  },
  textInput: {
    color: "#fff",
    flex: 1,
    padding: 10,
  },
  flexRowCenter: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
});
